import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { View, SafeAreaView, ScrollView, Linking, Switch, Platform, ActivityIndicator } from 'react-native'
import { Text, Dimensions, Image, TouchableOpacity, TextInput, Modal, Alert } from 'react-native';
import { store } from "../sharewebapp/store"
import { fontStyles, themeColor, server_var } from '../config/servervar';
import { CustomIcon } from '../component/custom';
import { getNextFlow, getFlowInfo } from '../component/custom';
import { getJwtToken } from '../component/custom';
import { uploadPhoto } from '../component/custom';

import moment from 'moment';
import { appLogout } from '../sharewebapp/common1';
import { useCustomFocusEffect } from '../component/custom';

export function SettingPart({ navigation }) {
  const { globalState, dispatch } = useContext(store);
  const [adminFlag, setAdminFlag] = useState(false);
  const [dialog, setDialog] = useState({ visible: false });
  const [blockPromoNoti, setBlockPromoNoti] = useState(false);
  const [fingerFlag, setFingerFlag] = useState(false);
  const [enterFlowFlag, setEnterFlowFlag] = useState(false);
  const [allmemFlag, setAllmemFlag] = useState(false);
  const [eTaxFlag, setEtaxFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileRef1 = useRef();
  const [eTaxMode, setEtaxMode] = useState(false);
  const [urlEslip, setUrlEslip] = useState(null);
  const [versionEslip, setVersionEslip] = useState(null);

  useCustomFocusEffect(useCallback(() => {
    if (globalState?.user?.allmemId) {
      validateEslip();
    } else {
      setEtaxFlag(false);
    }
  }, []))

  const loadUser = async () => {
    const res = await globalState.client.service('users').get(globalState?.user?.id, { query: { $select: ['roles', 'blockPromoNoti', 'fingerFlag', 'allmemId'] } });
    if (['admin'].includes(res.roles)) {
      setAdminFlag(true)
    }
    setBlockPromoNoti(res.blockPromoNoti);
    setFingerFlag(res.fingerFlag);
    if (res?.allmemId) {
      setAllmemFlag(true);
    }
  }

  useEffect(() => {
    if (globalState.user) {
      loadUser();
      if (server_var?.eTaxMode) {
        if (globalState?.user?.staffFlag == 1) {
          setEtaxMode(false);
        } else {
          if (globalState?.user?.allmemId) {
            setEtaxMode(true);
            validateEslip();
          } else {
            if (globalState?.user?.allcusId) {
              setEtaxMode(false);
            } else {
              setEtaxMode(true);
            }
            setEtaxFlag(false);
          }
        }
      } else {
        setEtaxMode(false);
      }
    }
  }, [])

  const validateEslip = async () => {
    let res;
    let jwtToken;
    try {
      jwtToken = await getJwtToken();
    } catch (e) { }
    try {
      res = await fetch(`${server_var.server_api}/validateEslip`, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
        body: JSON.stringify({ userId: globalState?.user?.id })
      });
    } catch (e) {
      console.log('validateEslip post error : ', e);
    }
    if (!res) return;
    const resJson = await res.json();
    console.log(themeColor.FgYellow, 'validateEslip resJson : ' + JSON.stringify(resJson), themeColor.FgReset);

    if (resJson?.result) {
      if (resJson?.statusId == 1) {
        setEtaxFlag(true);
      } else {
        setUrlEslip(resJson?.urlEslip);
        setVersionEslip(resJson?.versionEslip);
        setEtaxFlag(false);
      }
    } else {
      //Alert.alert('มีข้อผิดพลาด', (resJson?.resultData?.errorMsgTh) || 'มีข้อผิดพลาดในการสมัครรับใบเสร็จอิเล็กทรอนิกส์');
      setEtaxFlag(false);
    }
  }

  const updateEslip = async (statusId) => {
    setLoading(true);
    let res;
    let jwtToken;
    try {
      jwtToken = await getJwtToken();
    } catch (e) { }
    try {
      res = await fetch(`${server_var.server_api}/updateEslip`, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
        body: JSON.stringify({ userId: globalState?.user?.id, statusId })
      });
    } catch (e) {
      console.log('updateEslip post error : ', e);
    }
    if (!res) return;
    const resJson = await res.json();
    console.log(themeColor.FgYellow, 'updateEslip resJson : ' + JSON.stringify(resJson), themeColor.FgReset);

    if (resJson?.result) {
      if (statusId == 1) {
        setEtaxFlag(true);
      } else {
        try {
          res = await fetch(`${server_var.server_api}/acceptEslip`, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
            body: JSON.stringify({ userId: globalState?.user?.id, statusId: 0 })
          });
        } catch (e) {
          console.log('acceptEslip post cancel error : ', e);
        }
        setEtaxFlag(false);
      }
    } else {
      Alert.alert('มีข้อผิดพลาด', 'มีข้อผิดพลาดในการสมัครรับใบเสร็จอิเล็กทรอนิกส์');
    }
    setLoading(false);
  }

  useEffect(() => {
    if (globalState?.flowType == 'change_pin' && enterFlowFlag) {
      setEnterFlowFlag(false)
      changePin();
    }
  }, [globalState?.flowType, enterFlowFlag])

  const changePin = async () => {
    const flowName = await getNextFlow({ globalState, dispatch });
    const flowInfo = await getFlowInfo({ globalState, dispatch, flowName, params: null, navigation });
    if (!(flowInfo?.navAction)) {
      navigation.push(flowInfo.navName, { ...flowInfo.navParams, otpMode: 'old_user', mobileNumber: globalState?.user?.mobileNumber, userId: globalState?.user?.id });
    }
  }

  const MENU_LIST = [
    {
      id: 1, section: 1, title: 'ข้อมูลทั่วไป', option: { borderTopWidth: 0 }, visible: true,
      onPress: () => globalState?.user?.staffFlag == 1 ? navigation.navigate('ViewPharmaProfile', { userId: globalState?.user?.id }) : navigation.navigate('ViewUser', { userId: globalState?.user?.id })
    },
    {
      id: 2, section: 1, title: 'ที่อยู่ที่บันทึกไว้', option: { borderTopWidth: 1 },
      visible: !globalState?.user?.staffFlag,
      onPress: async () => {
        navigation.navigate('Location');
      }
    },
    {
      id: 3, section: 1, title: 'เปลี่ยนรหัสผ่าน', option: { borderTopWidth: 1 }, visible: true, onPress: async () => {
        if (globalState?.user?.staffFlag == 1) {
          navigation.navigate('PasswordEnter', {
            memberStatus: 'new',
            mobileNumber: globalState?.user?.mobileNumber,
            userId: globalState?.user?.id,
          });
        } else {
          setEnterFlowFlag(true);
          dispatch({ type: 'setFlowType', payload: 'change_pin' });
        }
      }
    },
    {
      id: 4, section: 1, title: 'รับข่าวสารและโปรโมชั่น', type: 'switch', visible: true, option: { borderTopWidth: 1 },
      value: !blockPromoNoti,
      onValueChange: async (value) => {
        await globalState.client.service('users').patch(globalState.user.id, { blockPromoNoti: !value });
        setBlockPromoNoti(!value);
      }
    },
    {
      id: 5, section: 1, title: 'เปิดใช้ Face/Touch ID', type: 'switch', visible: Platform.OS != 'web', option: { borderTopWidth: 1 },
      value: fingerFlag,
      onValueChange: async (value) => {
        console.log('before', fingerFlag);
        const res = await globalState.client.service('users').patch(globalState.user.id, { fingerFlag: value });
        setFingerFlag(value);
        dispatch({ type: 'setUserFingerFlag', payload: value })
        console.log('after', res.fingerFlag);
      }
    },
    {
      id: 6, section: 1, title: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอิเล็กทรอนิกส์', type: 'switch', visible: (eTaxMode && Platform.OS != 'web'), option: { borderTopWidth: 1 },
      value: eTaxFlag,
      onValueChange: async (value) => {
        if (allmemFlag) {
          if (eTaxFlag) {
            setDialog({ visible: true, type: 'updateEslip' });
            //updateEslip(2);
          } else {
            console.log('urlEslip PrivacyNotice : ', urlEslip, versionEslip);
            navigation.navigate('AllMemberApplyNow', { userId: globalState.user.id, mobileNumber: globalState.user.mobileNumber, updateEslip: true, urlEslip, versionEslip });
            //updateEslip(1);
          }
        } else {
          //Linking.openURL(`allpharmasee://AllMemberApply1`);
          setEtaxFlag(false);
          navigation.navigate('AllMemberApplyNow', { userId: globalState.user.id, mobileNumber: globalState.user.mobileNumber, applyEslip: true });
        }
      }
    },
    {
      id: 7, section: 1, title: 'ลบบัญชี', visible: !globalState?.user?.staffFlag, option: { borderTopWidth: 1, paddingBottom: 10 }, onPress: async () => {
        console.log('menu');
        setDialog({ visible: true, type: 'deleteAccount' });
      }
    },
    {
      id: 8, section: 2, title: 'คำแนะนำการใช้บริการ ALL PharmaSee', visible: Platform.OS != 'web', option: { borderTopWidth: 0 }, onPress: async () => {
        navigation.navigate('ViewWebSimple', {
          title: 'คำแนะนำการใช้บริการ',
          uri: `${server_var.server_api}/helppractice`,
        })
      }
    },
    {
      id: 9, section: 2, title: 'ติดต่อฝ่ายบริการลูกค้า', visible: true, option: { borderTopWidth: Platform.OS == 'web' ? 0 : 1, paddingBottom: 10 }, onPress: async () => {
        navigation.navigate('CustomerService');
      }
    },
    {
      id: 10, section: 3, title: 'ข้อกำหนดและเงื่อนไขการให้บริการ',
      optionText: (globalState?.user?.acceptTerm < globalState?.initInfo?.termVersion) ? 'ไม่ยอมรับเงื่อนไข' : 'ยอมรับเงื่อนไข',
      visible: Platform.OS != 'web', option: { borderTopWidth: 0 }, onPress: async () => {
        navigation.navigate('ViewWebSimple', {
          title: 'ข้อกำหนดและเงื่อนไขในการใช้บริการแอปพลิเคชัน ALL Pharma See',
          uri: globalState?.initInfo?.allpharmasee?.term1?.link ?? `${server_var.server_api}/termofuse`
        })
      }
    },
    {
      id: 11, section: 3, title: 'การให้ความยินยอม',
      optionText: (globalState?.user?.acceptConsent < globalState?.initInfo?.consentVersion) ? 'ไม่ยอมรับเงื่อนไข' : 'ยอมรับเงื่อนไข',
      visible: Platform.OS != 'web', option: { borderTopWidth: 1 }, onPress: async () => {
        if (globalState?.user?.acceptConsent >= globalState?.initInfo?.consentVersion) {
          navigation.navigate('ViewWeb',
            {
              title: 'การให้ความยินยอม',
              uri: globalState?.initInfo?.allpharmasee?.consent1?.link ?? `${server_var.server_api}/consent`,
              actionList: [
                {
                  id: 1, title: 'ถอนความยินยอม',
                  textStyle: { color: themeColor.color1 },
                  buttonStyle: { backgroundColor: 'white', borderWidth: 1, borderColor: themeColor.color1 },
                  onPressValue: { value: 'withdraw_consent_and_back' }
                },
              ],
              userId: globalState?.user?.id, mobileNumber: globalState?.user?.mobileNumber
            }
          )
        } else {
          navigation.navigate('ViewWeb',
            {
              title: 'การให้ความยินยอม',
              uri: globalState?.initInfo?.allpharmasee?.consent1?.link ?? `${server_var.server_api}/consent`,
              actionList: [
                {
                  id: 2, title: 'ยินยอม', onPressValue: {
                    value: 'accept_consent_and_back'
                  }
                }
              ],
              userId: globalState?.user?.id, mobileNumber: globalState?.user?.mobileNumber
            }
          )
        }
        // navigation.navigate('ViewWeb', {
        //   title: 'การให้ความยินยอม',
        //   uri: `${server_var.server_api}/consent`,
        // })
      }
    },
    {
      id: 12, section: 3, title: 'การแจ้งการประมวลผลข้อมูลส่วนบุคคล \n(Privacy Notice)',
      visible: Platform.OS != 'web', option: { borderTopWidth: 1, paddingBottom: 10 }, onPress: async () => {
        navigation.navigate('ViewWebSimple', {
          title: 'เอกสารแจ้งการประมวลผลข้อมูลส่วนบุคคล',
          uri: `${server_var.server_api}/privacy`,
        })
      }
    },
  ];

  const DEV_LIST = [
    {
      id: 1, title: 'Test2', onPress: async () => {
        const jwtToken = await getJwtToken();
        const tmpUrl = `${server_var.server_api}/ordermains`;
        const tmpResOrder = await fetch(tmpUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`
          },
          body: JSON.stringify({
            "orderStatus": "in_basket",
            "userId": 565,
            "shopId": 48,
            "queId": 524,
            "pharmaId": 1,
            "deliverType": null,
            "deliverJsonAddr": {
              "id": 0,
              "lat": 13.725345,
              "long": 100.544338,
              "userId": 565,
              "address": "เขตสาทร กรุงเทพมหานคร 10120 ประเทศไทย",
              "locType": "current",
              "fullAddress": "175 ถ. สาทรใต้ แขวงทุ่งมหาเมฆ เขตสาทร กรุงเทพมหานคร 10120 ประเทศไทย",
              "detailAddress": "",
              "mainAddressFull": "เขตสาทร กรุงเทพมหานคร 10120 ประเทศไทย",
              "contactDisplayName": "สมชาย สมหวัง",
              "contactMobileNumber": "0834389598",
              "otherAddress": ""
            },
            "$calcFromServer": true
          }),
        });
        const resOrder = await tmpResOrder.json();
        console.log('resOrder', resOrder);
      }
    },
    // { id: 2, title: 'Toast', onPress: () => dispatch({ type: 'showToast', payload: { text: 'ยกเลิกห้องสนทนากับเภสัชกรท่านนี้แล้ว' }, }) },
    // { id: 3, title: 'Shape', onPress: () => navigation.navigate('Blank') },
    // {
    //   id: 407, title: 'Payment', onPress: async () => {
    //     navigation.navigate('PaymentOption', {
    //       totalOrderPrice: 20, ordermainId: 11, deliverJsonAddr:
    //         { "id": 6, "lat": "13.7276592", "long": "100.524106", "userId": 637, "address": "สาทร กรุงเทพมหานคร 10120 ประเทศไทย", "locType": "home", "otherAddress": null, "detailAddress": "149/23 ซอย สุรวงศ์ แขวงสุริยวงศ์", "mainAddressDid": 1028, "mainAddressPid": 10, "mainAddressFull": "สาทร กรุงเทพมหานคร 10120 ประเทศไทย", "contactDisplayName": "Somchai Somwang", "mainAddressCountry": null, "contactMobileNumber": "0818680151", "mainAddressDistrict": "สาทร", "mainAddressPostCode": "10120", "mainAddressProvince": "กรุงเทพมหานคร" }
    //     });
    //   }
    // },
    // {
    //   id: 406, title: 'view web', onPress: async () => {
    //     navigation.navigate('ViewWebDeli', { uri: 'https://www.google.com' });
    //   }
    // },
    // {
    //   id: 405, title: 'check image', onPress: async () => {
    //     navigation.navigate('TmwPayment');
    //   }
    // },
    // {
    //   id: 403, title: 'get sec level', onPress: async () => {
    //     const res = await LocalAuthentication.getEnrolledLevelAsync();
    //     alert("Hello" + JSON.stringify(res));
    //   }
    // },
    // {
    //   id: 402, title: 'finger', onPress: async () => {
    //     const res = await LocalAuthentication.authenticateAsync();
    //     alert("res" + JSON.stringify(res));
    //   }
    // },
    // {
    //   id: 401, title: 'auth login', onPress: async () => {
    //     const res = await auth().signInWithPhoneNumber('+66834389598');
    //     console.log('res' + JSON.stringify(res));
    //   }
    // },
    // {
    //   id: 400,
    //   title: 'test analytics', onPress: async () => {
    //     await analytics().logEvent('basket', {
    //       id: 3745092,
    //       item: 'mens grey t-shirt',
    //       description: ['round neck', 'long sleeved'],
    //       size: 'L',
    //     })
    //   }
    // },
    // {
    //   id: 104, title: 'Test noti', option: { borderTopWidth: 1 }, onPress: async () => {
    //     Notifications.scheduleNotificationAsync({
    //       content: {
    //         title: 'Look at that notification',
    //         body: "I'm so proud of myself!",
    //         data: { messageFrom: 'force', link: `https://www.google.com` },
    //         sound: 'default',
    //       },
    //       trigger: null,
    //     });
    //   }
    // },

    // {
    //   id: 281, title: 'long2', onPress: () => {
    //     const key = CryptoJS.enc.Hex.parse('a4e1112f45e84f785358bb86ba750f48');
    //     const iv = CryptoJS.enc.Hex.parse('');
    //     const a1 = '{"result":"old_user_new_device","userId":3}';
    //     console.log('a1', a1);
    //     const rst = CryptoJS.AES.encrypt(a1, key, { iv: iv });
    //     console.log('\n\nciphertext', CryptoJS.enc.Hex.stringify(rst.ciphertext));
    //     var hex = '5ef068caf13fe79efc2b5461a99e8176789031a62c95abc0ddf0762211119d53de790074f87c317ada06d4461167c3d9';
    //     const tmp1 = CryptoJS.enc.Hex.parse(hex);
    //     console.log('\n\ntmp1', tmp1);
    //     const decrypted = CryptoJS.AES.decrypt({ ciphertext: tmp1 }, key, { iv: iv });
    //     console.log('\n\ndecrypted', CryptoJS.enc.Utf8.stringify(decrypted));
    //   }
    // },
    // {
    //   id: 282, title: 'long', onPress: () => {
    //     const key = CryptoJS.enc.Hex.parse('a4e1112f45e84f785358bb86ba750f48');
    //     const iv = CryptoJS.enc.Hex.parse('');
    //     const a1 = '{"result":"old_user_new_device","userId":3}';
    //     console.log('a1', a1);
    //     const rst = CryptoJS.AES.encrypt(a1, key, { iv: iv });
    //     console.log('\n\nciphertext', CryptoJS.enc.Hex.stringify(rst.ciphertext));
    //     var hex = '5ef068caf13fe79efc2b5461a99e8176789031a62c95abc0ddf0762211119d53de790074f87c317ada06d4461167c3d9';
    //     const tmp1 = CryptoJS.enc.Hex.parse(hex);
    //     console.log('\n\ntmp1', tmp1);
    //     const decrypted = CryptoJS.AES.decrypt({ ciphertext: tmp1 }, key, { iv: iv });
    //     console.log('\n\ndecrypted', CryptoJS.enc.Utf8.stringify(decrypted));
    //   }
    // },
    // {
    //   id: 283, title: 'long', onPress: () => {
    //     const a1 = `
    //     The disadvantage of this method is a lack of diffusion. Because ECB encrypts identical plaintext blocks into identical ciphertext blocks, it does not hide data patterns well. ECB is not recommended for use in cryptographic protocols.[20][21][22]
    //     A striking example of the degree to which ECB can leave plaintext data patterns in the ciphertext can be seen when ECB mode is used to encrypt a bitmap image which uses large areas of uniform color. While the color of each individual pixel is encrypted, the overall image may still be discerned, as the pattern of identically colored pixels in the original remains in the encrypted version`;
    //     console.log('a1', a1);
    //     const rst = CryptoJS.AES.encrypt(a1, 'Secret Passphrase')

    //     console.log('rst', rst);
    //     const decrypted = CryptoJS.AES.decrypt(rst, 'Secret Passphrase');
    //     console.log('decrypted', CryptoJS.enc.Utf8.stringify(decrypted));

    //   }
    // },

    // {
    //   id: 284, title: 'long', onPress: () => {
    //     const a1 = new Uint8Array(`
    //     The disadvantage of this method is a lack of diffusion. Because ECB encrypts identical plaintext blocks into identical ciphertext blocks, it does not hide data patterns well. ECB is not recommended for use in cryptographic protocols.[20][21][22]
    //     A striking example of the degree to which ECB can leave plaintext data patterns in the ciphertext can be seen when ECB mode is used to encrypt a bitmap image which uses large areas of uniform color. While the color of each individual pixel is encrypted, the overall image may still be discerned, as the pattern of identically colored pixels in the original remains in the encrypted version`
    //       .match(/../g).map(h => parseInt(h, 16))).buffer
    //     console.log('a1', a1);
    //     const words = CryptoJS.lib.WordArray.create(a1);
    //     console.log('words', words);
    //     const rst = CryptoJS.AES.encrypt(words, 'Secret Passphrase')
    //     console.log('rst', rst);
    //     const decrypted = CryptoJS.AES.decrypt(rst, 'Secret Passphrase');
    //     console.log('decrypted', decrypted);
    //   }
    // },
    // {
    //   id: 285, title: 'decrypt', onPress: () => {
    //     const a1 = new Uint8Array('5ef068caf13fe79efc2b5461a99e8176250f14e1c103e06404e2039f73bea585f66dd21de567cc1a4ee5952981bd9ce4'.match(/../g).map(h => parseInt(h, 16))).buffer
    //     const words = CryptoJS.lib.WordArray.create(a1);

    //     console.log('words', words);
    //     const key = CryptoJS.enc.Hex.parse('a4e1112f45e84f785358bb86ba750f48');
    //     const iv = CryptoJS.enc.Hex.parse('');
    //     const decrypted = CryptoJS.AES.decrypt({
    //       ciphertext: words
    //     }, key, { iv: iv });
    //     console.log('decrypted', CryptoJS.enc.Utf8.stringify(decrypted));
    //   }
    // },
    // {
    //   id: 286, title: 'long decrypt', onPress: () => {
    //     const key = CryptoJS.enc.Hex.parse('a4e1112f45e84f785358bb86ba750f48');
    //     const iv = CryptoJS.enc.Hex.parse('');
    //     const aesDecryptor = CryptoJS.algo.AES.createDecryptor(key, { iv: iv });
    //     const a2 = aesDecryptor.finalize(CryptoJS.enc.Hex.parse('5ef068caf13fe79efc2b5461a99e8176250f14e1c103e06404e2039f73bea585f66dd21de567cc1a4ee5952981bd9ce4'));
    //     console.log('total', CryptoJS.enc.Utf8.stringify(a2));
    //   }
    // },
    // {
    //   id: 287, title: 'decrypt2', onPress: () => {
    //     const key = CryptoJS.enc.Hex.parse('a4e1112f45e84f785358bb86ba750f48');
    //     const iv = CryptoJS.enc.Hex.parse('');
    //     const decrypted = CryptoJS.AES.decrypt(
    //       { ciphertext: CryptoJS.enc.Hex.parse('9b2e4547e383872e1aa2e4d67334d6d8') }, key, { iv: iv }
    //     );
    //     console.log('decrypted', CryptoJS.enc.Utf8.stringify(decrypted));
    //   }
    // },
    // {
    //   id: 288, title: 'encrypt', onPress: () => {
    //     const key = CryptoJS.enc.Hex.parse('a4e1112f45e84f785358bb86ba750f48');
    //     const iv = CryptoJS.enc.Hex.parse('');
    //     const encrypted = CryptoJS.AES.encrypt("GeeksforGeeks", key, { iv: iv });
    //     console.log('encrypted', CryptoJS.enc.Hex.stringify(encrypted.ciphertext));
    //   }
    // },
    // {
    //   id: 289, title: 'test1', option: { borderTopWidth: 1 }, onPress: () => {
    //     const encrypted = CryptoJS.AES.encrypt("Message", "");
    //     const decrypted = CryptoJS.AES.decrypt(encrypted, "Secret Passphrase");
    //     const dec2 = CryptoJS.AES.decrypt(CryptoJS.enc.Hex.parse('190b4312ec415434729e4bdbddd7c5cb'), "Secret Passphrase")
    //     const dec3 = CryptoJS.AES.decrypt('U2FsdGVkX1+kxHEPtbNID3v8+gATC2mcXOgzpAAGlAw=', "Secret Passphrase");
    //     const dec4 = CryptoJS.AES.decrypt(CryptoJS.enc.Base64.parse('Tp8tiuZ6+ii/0lRyDGKi/A=='), "Secret Passphrase");
    //     const base64CText = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    //     const a1 = CryptoJS.enc.Base64.parse(base64CText);
    //     const a2 = CryptoJS.enc.Hex.stringify(encrypted.ciphertext);
    //     console.log('encrypted', encrypted);
    //     console.log('base64CText', base64CText);
    //     console.log('a1', a1);
    //     console.log('a2', a2);
    //     const aesDecryptor = CryptoJS.algo.AES.createDecryptor(encrypted);
    //     const plaintextPart1 = aesDecryptor.process(encrypted.ciphertext);
    //     console.log('plain:', plaintextPart1);
    //   }
    // },
    // {
    //   id: 290, title: 'test decrypt', option: { borderTopWidth: 1 }, onPress: () => {
    //     const key = 'a4e1112f45e84f785358bb86ba750f48';
    //     const iv = CryptoJS.enc.Hex.parse('aaww');
    //     const aesDecryptor = CryptoJS.algo.AES.createDecryptor(key, { mode: CryptoJS.mode.ECB });

    //     let plaintextPart1 = aesDecryptor.process('9b2e4547e383872e1aa2e4d67334d6d8', "utf8"); //WORK
    //     console.log('key', key);
    //     console.log('paintext', plaintextPart1.toString());
    //   }
    // },
    // {
    //   id: 301, title: 'Video1', option: { borderTopWidth: 1 }, onPress: async () => {
    //     navigation.navigate('Test1', { feedId1: 1, feedId2: 2 })
    //   }
    // },
    // {
    //   id: 302, title: 'Video2', option: { borderTopWidth: 1 }, onPress: async () => {
    //     navigation.navigate('Test1', { feedId1: 2, feedId2: 1 })
    //   }
    // },
    // {
    //   id: 300, title: 'reAuthen', option: { borderTopWidth: 1 }, onPress: async () => {
    //     const res = await globalState.client.reAuthenticate();
    //     console.log('res', res);
    //   }
    // },
    // {
    //   id: 115, title: 'Read jwt', option: { borderTopWidth: 1 }, onPress: async () => {
    //     const tmp1 = await AsyncStorage.getItem('feathers-jwt')
    //     alert("jwt" + JSON.stringify(tmp1));
    //     console.log('jwt', tmp1);
    //   }
    // },

    // {
    //   id: 111, title: 'Authen by store jwt', option: { borderTopWidth: 1 }, onPress: async () => {
    //     const tmp1 = await AsyncStorage.getItem('feathers-jwt')
    //     const res = await globalState.client.authenticate({
    //       strategy: "jwt",
    //       accessToken: tmp1,
    //     });
    //   }
    // },
    // {
    //   id: 101, title: 'Lalamove', option: { borderTopWidth: 1 }, onPress: async () => {
    //     navigation.navigate('LalamoveStatus', { lalaId: 186750409428 })
    //   }
    // },
    // {
    //   id: 103, title: 'Read init info', option: { borderTopWidth: 1 }, onPress: async () => {
    //     alert('a' + JSON.stringify(globalState.initInfo));
    //   }
    // },
    // {
    //   id: 105, title: 'VideoCall1', option: { borderTopWidth: 1 }, onPress: async () => {
    //     navigation.navigate('VideoCall1', { roomId: 1234 })
    //   }
    // },
    // {
    //   id: 106, title: 'Nav1', option: { borderTopWidth: 1 }, onPress: async () => {
    //     Linking.openURL('allpharmasee://Main?setTab=consult')
    //   }
    // },
    // {
    //   id: 108, title: 'Upload Doc', option: { borderTopWidth: 1 }, onPress: async () => {
    //     const res = await DocumentPicker.getDocumentAsync();
    //     console.log('upload press:', res);
    //     if (res && res.type == 'success') {
    //       const uploadResult = await FileSystem.uploadAsync(`${server_var.server_api}/uploadFile`, res.uri, {
    //         httpMethod: 'POST',
    //         uploadType: FileSystem.FileSystemUploadType.MULTIPART,
    //         fieldName: 'tmpFile',
    //         parameters: { uploadTo: 'chat' }
    //       });
    //       console.log('uploadResult', uploadResult);
    //       const filename = JSON.parse(uploadResult?.body)?.filename;
    //       console.log('filename', filename);
    //     }
    //   }
    // },
    // {
    //   id: 110, title: 'PinEnter', option: { borderTopWidth: 1 }, onPress: async () => {
    //     navigation.navigate('PinEnter', { mobileNumber: '01', mode: 'enter' })

    //   }
    // },
    // {
    //   id: 112, title: 'Consent', option: { borderTopWidth: 1 }, onPress: async () => {
    //     navigation.navigate('ViewWeb', {
    //       title: 'การให้ความยินยอม',
    //       uri: `${server_var.server_api}/consent`,
    //       actionList: [
    //         {
    //           id: 1, title: 'ไม่ยินยอม', textStyle: { color: themeColor.color1 },
    //           buttonStyle: { backgroundColor: 'white', borderWidth: 1, borderColor: themeColor.color1 },
    //           onPress: () => console.log('hello')
    //         },
    //         { id: 2, title: 'ยินยอม', onPress: () => console.log('hello') }
    //       ]
    //     })
    //   }
    // },
    // {
    //   id: 113, title: 'Privacy', option: { borderTopWidth: 1 }, onPress: async () => {
    //     navigation.navigate('ViewWeb', {
    //       title: 'เอกสารแจ้งการประมวลผลข้อมูลส่วนบุคคล',
    //       uri: `${server_var.server_api}/privacy`,
    //       actionList: [
    //         { id: 2, title: 'ปิดหน้าต่าง', onPress: () => console.log('hello') }
    //       ]
    //     })
    //   }
    // },
    // {
    //   id: 114, title: 'Register', option: { borderTopWidth: 1 }, onPress: async () => {
    //     navigation.navigate('Register', { mobileNumber: '01' })
    //   }
    // },
    // {
    //   id: 116, title: 'BirthDateConfirm', option: { borderTopWidth: 1 }, onPress: async () => {
    //     navigation.navigate('LoginBirthDateConfirm', { mobileNumber: '0899255875' })
    //   }
    // },
    // {
    //   id: 117, title: 'OtherNote', option: { borderTopWidth: 1 }, onPress: async () => {
    //     navigation.navigate('OtherNote',)
    //   }
    // },
    // {
    //   id: 118, title: 'VatRequest', option: { borderTopWidth: 1 }, onPress: async () => {
    //     navigation.navigate('VatRequest',)
    //   }
    // },
  ]

  const uploadPhoto1 = async (e) => {
    uploadPhoto2(e.target.files)
  }

  const uploadPhoto2 = async (targetFiles) => {
    let images;
    try {
      images = await uploadPhoto('avatar', false, 'photolib', targetFiles);
    } catch { }
    console.log('images!!', images);
    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }
    await globalState.client.service('users').patch(globalState.user.id,
      { avatar: `${server_var.server_api}/images/profile/resized/${images[0].filename}` }
    );
    dispatch({ type: 'changeUserPhoto', payload: `${server_var.server_api}/images/profile/resized/${images[0].filename}` });
  }


  // const pickImage = async () => {
  //   const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
  //   console.log('hello permission', permissionResult);
  //   if (permissionResult.granted === false) {
  //     alert('คุณไม่ได้อนุญาตให้แอปนี้เข้าถึงรูปภาพ!');
  //     return;
  //   }
  //   let result = await ImagePicker.launchImageLibraryAsync({
  //     mediaTypes: ImagePicker.MediaTypeOptions.All,
  //     aspect: [1, 1],
  //     quality: 1,
  //     allowsEditing: true,
  //   });
  //   console.log(result);
  //   if (!result.canceled) {
  //     const originalFileName = result.uri.substring(result.uri.lastIndexOf("/") + 1);
  //     console.log('originalFileName', originalFileName);
  //     const jwtToken = await getJwtToken();
  //     const uploadResult = await FileSystem.uploadAsync(`${server_var.server_api}/uploadFile`, result.uri, {
  //       httpMethod: 'POST',
  //       uploadType: FileSystem.FileSystemUploadType.MULTIPART,
  //       fieldName: 'tmpFile',
  //       parameters: { uploadTo: 'profile', originalFileName: originalFileName, jwtToken }
  //     });
  //     console.log('uploadResult', uploadResult);
  //     const fileName = JSON.parse(uploadResult?.body)?.filename;

  //     await globalState.client.service('users').patch(globalState.user.id,
  //       { avatar: `${server_var.server_api}/images/profile/resized/${fileName}` }
  //     );
  //     dispatch({ type: 'changeUserPhoto', payload: `${server_var.server_api}/images/profile/resized/${fileName}` });
  //   }
  // };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        {globalState.user ? (
          <View style={{ alignItems: 'center', paddingHorizontal: 10 }}>
            <TouchableOpacity onPress={() => {
              if (Platform.OS == 'web') {
                fileRef1.current.click();
              } else {
                uploadPhoto2('')
              }
            }}>
              <View>
                <Image
                  source={globalState?.user?.avatar ?
                    { uri: globalState?.user?.avatar } :
                    { uri: `${server_var.server_api}/images/unknown-user-pic.jpeg` }
                  }
                  style={{ width: 100, height: 100, borderRadius: 50, marginTop: 40 }}
                />
                <View style={{ position: 'absolute', right: 0, bottom: -5 }}>
                  <CustomIcon name="plus-circle" size={25} color={themeColor.color1} />
                </View>
              </View>
            </TouchableOpacity>

            <Text style={[fontStyles.body1, { fontSize: 20, marginTop: 10 }]}>
              {globalState?.user?.firstName + ' ' + globalState?.user?.lastName}
            </Text>
            <Text style={[fontStyles.body1, { marginTop: 5, }]}>
              {globalState?.user?.mobileNumber}
            </Text>
            <View style={{ marginBottom: 0, marginTop: 10, paddingHorizontal: 15, paddingVertical: 2, borderRadius: 10, backgroundColor: themeColor.color1 }}>
              <Text style={{ color: 'white', fontSize: 12 }}>
                {(globalState?.user?.staffFlag ? (globalState?.user?.roles ?? '') : (globalState?.user?.allmemId) ? 'ALL member' : 'ผู้ใช้งานทั่วไป')}
              </Text>
            </View>
          </View>
        ) : (
          <View style={{ alignItems: 'center', paddingHorizontal: 10 }}>
            <View>
              <Image
                source={globalState?.user?.avatar ?
                  { uri: globalState?.user?.avatar } :
                  { uri: `${server_var.server_api}/images/unknown-user-pic.jpeg` }
                }
                style={{ width: 100, height: 100, borderRadius: 50, marginTop: 40 }}
              />
            </View>
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center' }} onPress={() => navigation.navigate('Login')}>
              <View style={{
                height: 40, backgroundColor: themeColor.color1,
                borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginTop: 10
              }}>
                <Text style={{ color: 'white', paddingHorizontal: 20 }}>ลงทะเบียนหรือเข้าสู่ระบบ</Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
        <View style={{ paddingHorizontal: 20 }}>
          {[
            globalState.user ? { id: 1, section: 'บัญชีของฉัน', visible: true } : { id: 5 },
            { id: 2, section: 'ศูนย์ช่วยเหลือ', visible: true },
            { id: 3, section: 'ข้อมูลทางกฎหมาย', visible: Platform.OS != 'web' },
          ].filter(item5 => item5.visible).map(item => (
            <View key={item.id?.toString()} style={{ marginTop: 15 }}>
              <Text style={{ color: themeColor.color1, fontWeight: 'bold' }}>{item.section}</Text>
              <View style={{ backgroundColor: 'white', marginTop: 10, borderRadius: 5 }}>
                {MENU_LIST.filter(item2 => item2.visible).filter(item2 => item2.section == item.id).map(item2 => {
                  if (item2?.type == 'switch') {
                    return (
                      <View key={item2.id.toString()} style={[
                        { marginVertical: 5, marginHorizontal: 10, borderColor: themeColor.color8, paddingTop: 5 },
                        { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }, item2.option]}>
                        <Text style={{ fontSize: 14, color: '#303030' }}>{item2.title}</Text>
                        <Switch
                          value={item2.value}
                          onValueChange={(value) => {
                            item2.onValueChange(value)
                          }}
                          style={{ transform: [{ scale: 0.7 }] }}
                        />
                      </View>
                    )
                  } else if (item2.visible) {
                    return (
                      <TouchableOpacity
                        key={item2.id.toString()}
                        onPress={() => item2.onPress()}
                        style={[
                          { marginVertical: 5, marginHorizontal: 10, justifyContent: 'center', borderColor: themeColor.color8, paddingTop: 5 },
                          item2.option
                        ]}
                      >
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                          <View>
                            <Text style={{ fontSize: 14, color: '#303030' }}>{item2.title}</Text>
                            {item2?.optionText ? <Text style={{ fontSize: 12, color: themeColor.color1 }}>{item2.optionText}</Text> : <View />}
                          </View>
                          <CustomIcon name="chevron-right" size={18} color={'gray'} />
                        </View>
                      </TouchableOpacity>
                    )
                  } else {
                    return <View key={item2.id.toString()} />
                  }
                }
                )}
              </View>
            </View>
          ))}
          {globalState.user &&
            <TouchableOpacity
              style={{ marginTop: 30 }}
              onPress={async () => {
                await appLogout(globalState, dispatch);
                if (Platform.OS == 'web') {
                  navigation.goHome();
                } else {
                  navigation.navigate('home', { screen: 'Main' });
                }

              }}
            >
              <View style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', paddingVertical: 7, borderRadius: 10 }}>
                <Text style={{ color: themeColor.color1 }}>ออกจากระบบ</Text>
              </View>
            </TouchableOpacity>
          }
          <View style={{ alignItems: 'center', marginTop: 20 }}>
            <Text style={{ color: 'gray', fontSize: 12 }}>Version {server_var.version}</Text>
            <TouchableOpacity onPress={() => Linking.openURL('https://www.orbitxstudio.com')}>
              <Text style={{ color: 'gray', fontSize: 12, marginTop: 5 }}>Power by
                <Text style={{ color: themeColor.color1, marginLeft: 5 }}> orbitxstudio.com</Text>
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{ marginTop: 50 }}>
            {
              DEV_LIST
                .filter(item => 0)
                .map(item => (
                  <TouchableOpacity key={item.id.toString()} onPress={() => item.onPress()}>
                    <View style={[
                      { justifyContent: 'center', borderColor: 'lightgray', height: 40 },
                      item.option
                    ]}>
                      <Text style={{ color: 'gray', fontSize: 12 }}>{item.title}</Text>
                    </View>
                  </TouchableOpacity>
                ))}
          </View>
        </View>
        {loading &&
          <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size='large' />
          </View>
        }
      </ScrollView>
      {
        Platform.OS == 'web' && (
          <div style={{ display: 'flex' }}>
            <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadPhoto1} multiple accept=".mp4, .jpg, .png" />
          </div>
        )
      }
      <Dialog dialog={dialog} setDialog={setDialog} updateEslip={updateEslip} />
    </SafeAreaView >
  )
}

function Dialog({ dialog, setDialog, updateEslip }) {
  const { globalState, dispatch } = useContext(store);
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <View style={{
          width: dialogWidth, height: dialogWidth * 1.5, paddingTop: 10, paddingBottom: 30, justifyContent: "flex-start",
          alignItems: 'center', borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
        }}>
          <ScrollView>
            {(dialog?.type == 'deleteAccount') &&
              <View style={{ marginVertical: 20, alignItems: 'center', paddingHorizontal: 20 }}>
                <Image
                  // source={require('../assets/images/exclamation1.png')}
                  source={{ uri: `${server_var.server_api}/images/exclamation1.png` }}
                  style={{ width: 160, height: 160, alignSelf: 'center' }}
                  resizeMode="contain"
                />
                <Text style={{ color: themeColor.color1, marginVertical: 10, fontWeight: 'bold' }}>คุณแน่ใจหรือไม่ว่าต้องการลบบัญชีของคุณ</Text>
                <Text style={{ textAlign: 'center', color: 'gray' }}>
                  หากคุณลบบัญชีผู้ใช้แล้วคุณจะออกจากระบบทันที​ โดยการยกเลิกบัญชีผู้ใช้งานจะใช้เวลาประมาณ 30 วัน นับแต่วันที่แจ้งยกเลิกการใช้งาน
                </Text>
                <Text style={{ textAlign: 'center', color: 'gray', marginTop: 20 }}>
                  หมายเหตุ: หากท่านต้องการกู้คืนบัญชีในภายหลัง กรุณาติดต่อไลน์ @allpharmasee
                </Text>
              </View>
            }
            {(dialog?.type == 'updateEslip') &&
              <View style={{ marginVertical: 20, alignItems: 'center', paddingHorizontal: 20 }}>
                <Text style={{ color: themeColor.color1, marginVertical: 10, fontWeight: 'bold' }}>ปิดการรับใบเสร็จอิเล็กทรอนิกส์</Text>
                <Text style={{ textAlign: 'center' }}>
                  คุณต้องการปิดรับใบเสร็จรับเงิน/ใบกำกับภาษีอิเล็กทรอนิกส์ในการซื้อครั้งต่อไป และสามารถเปิดรับในภายหลังได้
                </Text>
                <Text style={{ textAlign: 'center', color: themeColor.color6, marginTop: 20 }}>
                  มีผลกับคำสั่งซื้อบน ALL PharmaSee และ 7-ELEVEN
                </Text>
              </View>
            }
          </ScrollView>
          <View style={{ flexDirection: 'row' }}>
            {
              [
                {
                  id: 1, title: 'ยกเลิก', onPress: () => setDialog(prev => ({ ...prev, visible: false })),
                  viewStyle: { backgroundColor: 'white' }, textStyle: { color: themeColor.color1 }
                },
                {
                  id: 2, title: 'ยืนยัน', onPress: async () => {
                    setDialog(prev => ({ ...prev, visible: false }));
                    if (dialog?.type == 'deleteAccount') {
                      const jwtToken = await getJwtToken();
                      const res = await fetch(`${server_var.server_api}/reqDelAccount`, {
                        method: 'POST',
                        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
                        body: JSON.stringify({ userId: globalState?.user?.id })
                      });
                      const resJson = await res.json();
                      await appLogout(globalState, dispatch);
                    } else if (dialog?.type == 'updateEslip') {
                      updateEslip(2);
                    }
                  },
                  viewStyle: { backgroundColor: themeColor.color1 }, textStyle: { color: 'white' }
                }
              ].map(item => (
                <TouchableOpacity key={item.id.toString()} onPress={() => item.onPress()} style={{ marginHorizontal: 10 }}>
                  <View style={[{
                    width: 120, height: 35, borderRadius: 10, borderWidth: 1, borderColor: themeColor.color1,
                    justifyContent: 'center', alignItems: 'center'
                  }, item.viewStyle]}>
                    <Text style={[item.textStyle]}>{item.title}</Text>
                  </View>
                </TouchableOpacity>
              ))
            }
          </View>
          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: false }))}>
              <CustomIcon name="close-circle-outline" size={25} color="gray" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}
