import React from 'react';
import { Text, View, TouchableOpacity, Image, Platform, Modal, Dimensions, ScrollView, SafeAreaView, Switch, Alert } from 'react-native';
import { AppState, Linking } from 'react-native';

import { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';
import moment from 'moment';
import { getVersionNumber, getJwtToken, useCustomFocusEffect } from '../component/custom';

export function HomePharmacistPart({ navigation }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState([]);
  const appState = useRef(AppState.currentState);
  const [adminFlag, setAdminFlag] = useState(false);
  const [adminType, setAdminType] = useState();
  const ADMIN_TYPE = ['admin', 'cs', 'marketing', 'rapd', 'pharmacist'];

  useEffect(() => {
    const loadUserRole = async () => {
      const res = await globalState.client.service('users').get(globalState?.user?.id, { query: { $select: ['roles'] } });
      if (ADMIN_TYPE.includes(res?.roles)) {
        setAdminFlag(true);
        setAdminType(res?.roles);
      }
    }
    if (globalState.user) {
      loadUserRole();
    }
  }, [globalState?.user?.id])

  const loadData = async () => {
    const jwtToken = await getJwtToken();
    const res = await fetch(`${server_var.server_api}/shopListByPharmaId?pharmaId=${globalState?.user?.id}`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
    });
    const resJson = await res.json();
    setData(resJson);
  }

  useEffect(() => {
    loadData();
  }, [globalState?.user?.id])

  useCustomFocusEffect(
    useCallback(() => {
      loadData();
    }, [])
  );

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (
        appState.current.match(/inactive|background/) && nextAppState === "active"
      ) {
        loadData();
      }
      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, [globalState?.user?.id])

  useEffect(() => {
    globalState.client.service('notitemplogs').on('created', onNotiListener);
    return () => {
      globalState.client.service('notitemplogs').removeListener('created', onNotiListener);
    };
  }, [globalState?.user?.id]);

  const onNotiListener = (payload) => {
    if (payload.toUserId == globalState.user.id && payload.type == 'RefreshQueline') {
      loadData();
    }
  };

  useEffect(() => {
    const changePassword = (dayLeftToChangePassword) => {
      navigation.navigate('PasswordEnter', {
        memberStatus: 'new',
        mobileNumber: globalState?.user?.mobileNumber,
        userId: globalState?.user?.id,
        ...dayLeftToChangePassword <= 0 ? { noBackButton: true } : null
      });
    }
    const notChangePassword = () => {
      globalState.client.service('users').patch(globalState.user.id,
        { passwordRemindedDate: moment().add(24, 'hours').utc() }
      );
    }
    if (globalState?.user?.id) {
      if (moment().diff(globalState?.user?.passwordRemindedDate, 'hours') > 0) {
        const dayLeftToChangePassword = -1 * moment().diff(globalState?.user?.passwordExpiredDate, 'days');
        const promptList = [
          { text: 'เปลี่ยนรหัสผ่าน', onPress: async () => changePassword() },
          ...dayLeftToChangePassword > 0 ? [{ text: 'ไว้ภายหลัง', onPress: async () => notChangePassword() }] : []
        ]
        if (Platform.OS == 'web') {
          if (window.confirm(`รหัสผ่านของคุณจะหมดอายุใน ${dayLeftToChangePassword} วัน คุณต้องการเปลี่ยนรหัสผ่าน`)) {
            changePassword(dayLeftToChangePassword)
          } else {
            notChangePassword()
          }
        } else {
          Alert.alert('ต้องการเปลี่ยนรหัส', `รหัสผ่านของคุณจะหมดอายุใน ${dayLeftToChangePassword} วัน`, promptList)
        }
      }
    }
  }, [globalState?.user])


  const toggleOnOff = async (id, value) => {
    // alert("Hello");
    if (Platform.OS != 'web' && getVersionNumber(server_var?.version) < getVersionNumber(globalState?.initInfo?.allpharmasee?.appVersion)) {
      // if (true) {
      const resConfirm = await confirmToUpdate();
      if (resConfirm) {
        return;
      }
    }

    await globalState.client.service('shopusers').patch(null,
      { statusOnline: value ? 'online' : 'offline' },
      { query: { shopId: id, userId: globalState?.user?.id } }
    );
    await loadData();
  }

  const SCREEN_LIST = [
    {
      id: 1, title: 'จัดการคำสั่งซื้อ',
      iconName: require('../assets/images/PHX_Order.png'),
      onPress: () => navigation.navigate('ManageOrder'),
      visible: ['admin', 'cs', 'marketing', 'rapd', 'pharmacist'].includes(adminType)
    },
    {
      id: 2, title: 'จัดการ\nใบสั่งจองสินค้า',
      iconName: require('../assets/images/PHX_PreOrder.png'),
      onPress: () => navigation.navigate('ManagePreOrder'),
      visible: ['admin', 'cs', 'marketing', 'rapd', 'pharmacist'].includes(adminType)
    },
    {
      id: 3, title: 'ข้อมูล\nลูกค้า',
      iconName: require('../assets/images/PHX_Customer.png'),
      onPress: () => navigation.navigate('ManageCustomer'),
      visible: false,
      // visible: ['admin', 'cs', 'marketing', 'rapd', 'pharmacist'].includes(adminType)
    },
    {
      id: 4, title: 'ข้อมูลยา',
      iconName: require('../assets/images/PHX_Product.png'),
      onPress: () => navigation.navigate('ManageProduct'),
      visible: ['admin', 'cs', 'rapd', 'pharmacist'].includes(adminType)
    },
    {
      id: 5, title: 'ฉลากยา',
      iconName: require('../assets/images/PHX_Label.png'),
      onPress: () => navigation.navigate('ManageLabel'),
      visible: ['admin', 'cs', 'rapd', 'pharmacist'].includes(adminType)
    },
    {
      id: 6, title: 'นัดหมาย\nติดตามอาการ',
      iconName: require('../assets/images/PHX_Appointment.png'),
      onPress: () => navigation.navigate('ManageAppointment'),
      visible: ['admin', 'cs', 'marketing', 'rapd', 'pharmacist'].includes(adminType)
    },
    {
      id: 7, title: 'ค้นหาร้านยา\neXta Plus',
      iconName: require('../assets/images/PHX_Map.png'),
      onPress: () => navigation.navigate('Map'),
      visible: ['admin', 'cs', 'marketing', 'rapd', 'pharmacist'].includes(adminType) && Platform.OS != 'web'
    },
    {
      id: 8, title: 'ประวัติ\nการให้คำปรึกษา',
      iconName: require('../assets/images/PHX_PharmaNote.png'),
      onPress: () => navigation.navigate('ManagePharmaNote'),
      visible: ['admin', 'cs', 'marketing', 'rapd', 'pharmacist'].includes(adminType)
    },
    {
      id: 9, title: 'รายงาน',
      iconName: require('../assets/images/PHX_Reports.png'),
      onPress: () => navigation.navigate('Reports'),
      visible: ['admin', 'cs', 'rapd', 'pharmacist'].includes(adminType)
    },
    {
      id: 10, title: 'คำถามที่พบบ่อย\nFAQ',
      iconName: require('../assets/images/PHX_Faq.png'),
      onPress: () => navigation.navigate('Faq'),
      visible: ['admin', 'cs', 'marketing', 'rapd', 'pharmacist'].includes(adminType)
    },
    {
      id: 11, title: 'คู่มือ\nการใช้งาน',
      iconName: require('../assets/images/PHX_UserManual.png'),
      onPress: () => navigation.navigate('UserManual'),
      visible: ['admin', 'cs', 'marketing', 'rapd', 'pharmacist'].includes(adminType)
    },
    {
      id: 12, title: 'Service &\nSupport',
      iconName: require('../assets/images/PHX_CustomerService.png'),
      onPress: () => navigation.navigate('CustomerService'),
      visible: ['admin', 'cs', 'marketing', 'rapd', 'pharmacist'].includes(adminType)
    },
    {
      id: 13, title: 'แชท/วิดีโอการปรึกษา',
      iconName: require('../assets/images/PHX_Video.png'),
      onPress: () => navigation.navigate('ConsultVideoList'),
      visible: ['admin', 'cs', 'marketing', 'rapd', 'pharmacist'].includes(adminType)
    },
  ]

  const ADMIN_LIST = [
    {
      id: 1, title: 'จัดการคำสั่งซื้อ',
      iconName: require('../assets/images/Admin_Order.png'),
      onPress: () => navigation.navigate(Platform.OS == 'web' ? 'ManageOrderAdmin' : 'ManageOrder', { forAdmin: true }),
      visible: ['admin', 'cs'].includes(adminType)
    },
    {
      id: 2, title: 'จัดการ\nใบสั่งจองสินค้า',
      iconName: require('../assets/images/Admin_PreOrder.png'),
      onPress: () => navigation.navigate('ManagePreOrder', { forAdmin: true }),
      visible: ['admin', 'cs'].includes(adminType)
    },
    {
      id: 3, title: 'จัดการบัญชีผู้ใช้',
      iconName: require('../assets/images/Admin_User.png'),
      onPress: () => navigation.navigate('ManageUser'),
      visible: ['admin', 'cs', 'rapd'].includes(adminType)
    },
    {
      id: 4, title: 'จัดการร้านค้า',
      iconName: require('../assets/images/Admin_Shop.png'),
      onPress: () => navigation.navigate('ManageShop'),
      visible: ['admin', 'cs', 'rapd'].includes(adminType)
    },
    {
      id: 5, title: 'จัดการแบนเนอร์',
      iconName: require('../assets/images/Admin_Banner.png'),
      onPress: () => navigation.navigate('ManageBanner'),
      visible: ['admin', 'marketing'].includes(adminType)
    },
    {
      id: 6, title: 'บรอดแคสต์',
      iconName: require('../assets/images/Admin_Broadcast.png'),
      onPress: () => navigation.navigate('FeedNoti'),
      visible: ['admin', 'cs', 'marketing'].includes(adminType)
    },
    {
      id: 7, title: 'จัดการ FAQ',
      iconName: require('../assets/images/Admin_Faq.png'),
      onPress: () => navigation.navigate('Faq', { forAdmin: true }),
      visible: ['admin', 'cs', 'rapd'].includes(adminType)
    },
    {
      id: 8, title: 'สถานะออนไลน์\nของร้านยา',
      iconName: require('../assets/images/Admin_Online.png'),
      onPress: () => navigation.navigate('ShopOnline'),
      visible: ['admin', 'cs', 'rapd'].includes(adminType)
    },
    {
      id: 9, title: 'จัดการ\nข้อความต้อนรับ',
      iconName: require('../assets/images/Admin_Greeting.png'),
      onPress: () => navigation.navigate('ManageGreeting'),
      visible: ['admin', 'cs', 'marketing'].includes(adminType)
    },
    {
      id: 10, title: 'จัดการโปรโมชั่น\nและสิทธิพิเศษ',
      iconName: require('../assets/images/Admin_Promotion.png'),
      onPress: () => navigation.navigate('ManagePromotion'),
      visible: ['admin', 'cs', 'marketing'].includes(adminType)
    },
    {
      id: 11, title: 'จัดการ\nแชท/วิดีโอการปรึกษา',
      iconName: require('../assets/images/Admin_Video.png'),
      onPress: () => navigation.navigate('ManageConsultVideo'),
      visible: ['admin'].includes(adminType)
      //visible: Platform.OS != 'web' && ['admin'].includes(adminType)
    },
    {
      id: 12, title: 'การจัดการสิทธิ์',
      iconName: require('../assets/images/Admin_Roles.png'),
      onPress: () => navigation.navigate('ManageRole'),
      visible: ['admin'].includes(adminType)
    },
    {
      id: 13, title: 'จัดการบทความ',
      iconName: require('../assets/images/Admin_Content.png'),
      onPress: () => {
        navigation.navigate('ManageContent');
      },
      visible: Platform.OS == 'web' && ['admin', 'marketing'].includes(adminType)
    },
    {
      id: 14, title: 'จัดการคูปอง',
      iconName: require('../assets/images/Admin_Coupon.png'),
      onPress: () => navigation.navigate('ManageCoupon'),
      visible: Platform.OS == 'web' && ['admin', 'cs', 'marketing'].includes(adminType)
    },
  ]

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ paddingHorizontal: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 5 }}>
        <View style={{ flex: 1 }}>
          <Text style={{ color: themeColor.color1, fontSize: 16 }}>เภสัชกร {globalState?.user?.displayName}</Text>
          <Text>รหัสพนักงาน {globalState?.user?.mobileNumber} เลขที่ใบอนุญาต {globalState?.user?.staffCerNo}</Text>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate('Noti')}>
          <CustomIcon name="bell-outline" size={26} color="gray" />
          {globalState?.totalUnread?.noti > 0 && (
            <View style={{
              position: 'absolute', top: -10, right: -8, width: 20, height: 20, borderRadius: 10,
              backgroundColor: themeColor.color6, justifyContent: 'center', alignItems: 'center'
            }}>
              <Text style={{ color: 'white', fontSize: 10, fontWeight: 'bold' }}>
                {globalState?.totalUnread?.noti}
              </Text>
            </View>
          )}
        </TouchableOpacity>
      </View>
      <ScrollView style={[{ paddingHorizontal: 10 }, Platform.OS == 'web' ? { height: '80vh' } : null]}>
        {data?.map(item => <ShopItem key={item.id.toString()} item={item} toggleOnOff={toggleOnOff} />)}
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', marginVertical: 5, paddingLeft: 15 }}>
          {SCREEN_LIST.filter(item => item?.visible).map(item => (
            <View key={item.id.toString()} style={{ width: '45%', marginHorizontal: 5, marginVertical: 5 }}>
              <TouchableOpacity onPress={() => item.onPress()}>
                <View style={{
                  flexDirection: 'row', alignItems: 'center', height: 50, width: '100%',
                  borderRadius: 10, backgroundColor: 'white', paddingHorizontal: 5, paddingVertical: 5
                }}>
                  <Image source={item.iconName} style={{ width: 30, height: 30, marginLeft: 10 }} />
                  <Text style={{ fontSize: 12, marginLeft: 10, }}>{item.title}</Text>
                </View>
              </TouchableOpacity>
            </View>
          ))}
        </View>

        {adminFlag &&
          <View>
            {adminType != 'pharmacist' &&
              <View style={{ marginTop: 20, height: 40, width: '100%', backgroundColor: themeColor.color1, borderRadius: 5, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ color: 'white' }}>แอดมิน</Text>
              </View>
            }
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', marginVertical: 5, paddingLeft: 15 }}>
              {ADMIN_LIST.filter(item => item?.visible).map(item => (
                <View key={item.id.toString()} style={{ width: '45%', marginHorizontal: 5, marginVertical: 5 }}>
                  <TouchableOpacity onPress={() => item.onPress()}>
                    <View style={{
                      flexDirection: 'row', alignItems: 'center', height: 50, width: '100%',
                      borderRadius: 10, backgroundColor: 'white', paddingHorizontal: 5, paddingVertical: 5
                    }}>
                      <Image source={item.iconName} style={{ width: 30, height: 30, marginLeft: 10 }} />
                      <Text style={{ fontSize: 12, marginLeft: 10, }}>{item.title}</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              ))}
            </View>
          </View>}
      </ScrollView>
    </SafeAreaView >
  )
}

function ShopItem({ item, toggleOnOff }) {
  const { globalState } = useContext(store);

  return (
    <View style={{ backgroundColor: 'white', borderRadius: 5, marginVertical: 5 }}>
      <View style={{ flexDirection: 'row', marginVertical: 15, marginHorizontal: 20 }}>
        <Image source={{ uri: item.shopPhoto1 }} style={{ width: 50, height: 50, borderRadius: 25 }} />
        <View style={{ marginLeft: 20, flex: 1 }}>
          <Text style={{ color: themeColor.color1, }}>{item.shopThName}</Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Switch
              value={item.statusOnline == 'online'}
              onValueChange={(value) => {
                toggleOnOff(item.id, value);
              }}
              style={{ transform: [{ scale: 0.7 }] }}
            />
            <Text style={{ fontSize: 12, color: 'gray' }}>{item.statusOnline == 'online' ? 'ออนไลน์' : 'ออฟไลน์'}</Text>
          </View>
        </View>
      </View>
      <View style={{
        flexDirection: 'row', backgroundColor: themeColor.color1, alignItems: 'center',
        borderBottomLeftRadius: 5, borderBottomRightRadius: 5, paddingVertical: 5
      }}>
        {[
          { id: 1, title: 'รอการปรึกษา', iconName: 'clock', value: item?.numInQue, style: {} },
          { id: 2, title: 'ระหว่างปรึกษา', iconName: 'chat', value: item?.numInConsult, style: { borderLeftWidth: 1, borderColor: 'lightgray' } },
        ].map(item2 => (
          <View key={item2.id.toString()} style={[
            { flex: 0.5, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
            item2.style
          ]}>
            <CustomIcon name={item2.iconName} size={20} color="lightgray" />
            <Text style={{ color: 'white', fontSize: 12, marginLeft: 5 }}>{item2.title} {item2?.value ?? '0'} คน</Text>
          </View>
        ))}
      </View>
    </View>
  )
}

function confirmToUpdate() {
  return new Promise((resolve, reject) => {
    Alert.alert(
      'ALL PharmaSee',
      '\nกรุณาอัพเดทแอปพลิเคชัน ALL PharmaSee เป็นเวอร์ชันล่าสุดเพื่อความปลอดภัยและประสบการณ์ใช้งานที่ดียิ่งขึ้น\n',
      [
        {
          text: 'อัพเดต', onPress: async () => {
            let storeUrl;
            if (Platform.OS == 'ios') {
              storeUrl = 'https://apps.apple.com/th/app/all-pharmasee/id1468859352';
            } else {
              storeUrl = 'https://play.google.com/store/apps/details?id=com.appexta1'
            }
            Linking.openURL(storeUrl);
          }
        },
        { text: 'ยกเลิก', onPress: () => { resolve(false) } },
      ]
    )
  })
}

