import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator, Alert, TouchableWithoutFeedback, Keyboard } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';
import { uploadPhoto } from '../component/custom';
import moment from 'moment';
import EventEmitter from '../sharewebapp/EventEmitter';

export function EditUserPart({ route, navigation }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ at: '', message: '' });
  const [chgPwdMode, setChgPwdMode] = useState(false)
  const { userId, staffFlag } = route.params;
  const [adminType, setAdminType] = useState();
  const [viewMode, setViewMode] = useState(false)

  const FIELD_LIST = [
    {
      id: 1, type: 'avatar', title: 'รูปโปรไฟล์', fieldName: 'avatar', value: data?.avatar, visible: true
    },
    {
      id: 2, type: 'text', title: staffFlag ? 'User ID (ขึ้นต้นด้วย E)' : 'เบอร์โทรศัพท์', fieldName: 'mobileNumber', value: data?.mobileNumber, textInputProp: { editable: (userId == 0) && staffFlag ? true : false }, visible: true
    },
    {
      id: 3, type: 'text', title: 'รหัสผ่าน', fieldName: 'password', value: data?.password, visible: chgPwdMode, textInputProp: { autoCapitalize: 'none' }
    },
    {
      id: 4, type: 'text', title: 'ยืนยันรหัสผ่าน', fieldName: 'repassword', value: data?.repassword, visible: chgPwdMode, textInputProp: { autoCapitalize: 'none' }
    },
    {
      id: 5, type: 'text', title: 'ชื่อ', fieldName: 'firstName', value: data?.firstName, visible: true, textInputProp: { editable: !viewMode }
    },
    {
      id: 6, type: 'text', title: 'นามสกุล', fieldName: 'lastName', value: data?.lastName, visible: true, textInputProp: { editable: !viewMode }
    },
    {
      id: 7, type: 'text', title: 'ชื่อเล่น', fieldName: 'staffNickName', value: data?.staffNickName, visible: staffFlag, textInputProp: { editable: !viewMode }
    },
    {
      id: 8, type: 'gender', title: 'เพศ', fieldName: 'gender', value: data?.gender, visible: staffFlag
    },
    {
      id: 9, type: 'date', title: 'วันเดือนปีเกิด เป็น คศ. (25/06/1990)', fieldName: 'birthDate', value: data?.birthDate, textInputProp: { keyboardType: 'number-pad', editable: !viewMode }, visible: true
    },
    {
      id: 10, type: 'text', title: 'รหัสพนักงาน', fieldName: 'staffId', value: data?.staffId, visible: staffFlag, textInputProp: { editable: !viewMode }
    },
    {
      id: 11, type: 'text', title: 'เลขที่ใบอนุญาตประกอบวิชาชีพ', fieldName: 'staffCerNo', value: data?.staffCerNo, visible: staffFlag, textInputProp: { editable: !viewMode }
    },
    {
      id: 12, type: 'text', title: 'เบอร์โทรศัพท์', fieldName: 'staffPhoneNumber', value: data?.staffPhoneNumber, textInputProp: { keyboardType: 'number-pad' }, visible: staffFlag, textInputProp: { editable: !viewMode }
    },
    {
      id: 13, type: 'text', title: 'รหัสอ้างอิง', fieldName: 'referalCode', value: data?.referalCode, visible: !staffFlag, textInputProp: { editable: !viewMode }
    },
    {
      id: 14, type: 'datetime', title: 'วันที่สมัครใช้งาน', fieldName: 'createdAt', value: moment(data?.createdAt).format('DD/MM/YYYY HH:mm:ss'), textInputProp: { editable: false }, visible: !staffFlag
    },
    {
      id: 15, type: 'status', title: 'สถานะสมาชิก', fieldName: 'memberStatus', value: data?.memberStatus, visible: !staffFlag
    },
  ]

  useEffect(() => {
    const loadUserRole = async () => {
      const res = await globalState.client.service('users').get(globalState?.user?.id, { query: { $select: ['roles'] } });
      setAdminType(res?.roles);
      setViewMode(['cs', 'rapd'].includes(res?.roles));
    }
    if (globalState.user) {
      loadUserRole();
    }
  }, [])

  useEffect(() => {
    if (userId > 0) {
      loadData();
    } else {
      setChgPwdMode(true);
    }
  }, [userId])

  const loadData = async () => {
    const res = await globalState.client.service('users').get(userId);
    if (!res) {
      dispatch({ type: 'showToast', payload: { text: 'ไม่มีข้อมูลผู้ใช้คนนี้' }, });
      navigation.goBack();
    }
    setData(res);
  }

  const onDelete = (id) => {
    const delAction = async () => {
      await globalState.client.service('users').remove(id);
      navigation.goBack();
    }
    if (Platform.OS == 'web' && window.confirm('คุณต้องการลบผู้ใช้คนนี้อย่างถาวร ?')) {
      delAction();
    } else {
      Alert.alert('ลบบัญชีผู้ใช้', 'คุณต้องการลบผู้ใช้คนนี้อย่างถาวร ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => delAction() },
        ]
      );
    }

  }

  const validatePassword = (password) => {
    // const mediumPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    const mediumPassword = new RegExp('(?=.*[a-zA-Z])(?=.*[0-9])(?=.{12,})');
    // https://www.section.io/engineering-education/password-strength-checker-javascript/
    // min length 6, at least 1 number and 1 english or thai character 
    console.log('test result:' + mediumPassword.test(password));
    return mediumPassword.test(password)
  }

  const validatePin = (pin) => /^\d{6}$/gm.test(pin);


  const validateForm = () => {
    const blankField = ['mobileNumber', 'firstName', 'lastName', 'gender'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    // if (!moment(data.birthDate, 'DD/MM/YYYY').isValid()) {
    //   setError({ at: 'birthDate', message: 'วันที่ไม่ถูกต้อง' });
    //   return false
    // }
    if (chgPwdMode) {
      const blankField2 = ['password', 'repassword'].find(item => !data?.[item]);
      if (blankField2) {
        setError({ at: blankField2, message: 'ไม่สามารถเว้นว่างได้' })
        return false;
      }

      if (data.password != data.repassword) {
        setError({ at: 'password', message: 'รหัสผ่านไม่ตรงกัน' })
        return false;
      }

      if (staffFlag) {
        if (!validatePassword(data.password)) {
          //setError({ at: 'password', message: 'รหัสผ่านง่ายเกินไป(ต้องยาวกว่า 8 ตัว และมีทั้งตัวเลข ตัวอักษรตัวเล็ก ตัวใหญ่ และสัญลักษณ์)' })
          setError({ at: 'password', message: 'รหัสผ่านง่ายเกินไป(ต้องยาวกว่า 12 ตัว มีทั้งตัวเลขและตัวอักษร)' })
          return false
        }
      } else {
        if (!validatePin(data.password)) {
          setError({ at: 'password', message: 'รหัสต้องเป็นตัวเลขทั้งหมดและมี  6 ตัว' })
          return false
        }
      }
    }
    return true;
  }

  const onSave = async (id) => {
    setError({ at: '', message: '' });
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    let res;
    let tmpDate;
    if (data?.birthDate) {
      if (data?.birthDate.indexOf("/") == -1) {
        tmpDate = moment(data?.birthDate).format('YYYY/MM/DD');
      } else {
        tmpDate = moment(data?.birthDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
      }
    }

    if (id == 0) {
      const tmpCreate = {
        mobileNumber: data?.mobileNumber,
        avatar: data?.avatar,
        firstName: data?.firstName,
        lastName: data?.lastName,
        displayName: `${data?.firstName} ${data?.lastName}`,
        staffNickName: data?.staffNickName,
        gender: data?.gender,
        birthDate: tmpDate,
        staffId: data?.staffId,
        staffCerNo: data?.staffCerNo,
        staffPhoneNumber: data?.staffPhoneNumber,
        staffFlag: 1,
        roles: 'pharmacist',
        memberStatus: 'new',
        password: data?.password,
      }
      res = await globalState.client.service('users').create(tmpCreate);
    } else {
      let tmpEdit = {
        avatar: data?.avatar,
        firstName: data?.firstName,
        lastName: data?.lastName,
        displayName: `${data?.firstName} ${data?.lastName}`,
        staffNickName: data?.staffNickName,
        gender: data?.gender,
        birthDate: tmpDate,
        staffId: data?.staffId,
        staffCerNo: data?.staffCerNo,
        staffPhoneNumber: data?.staffPhoneNumber,
        referalCode: data?.referalCode,
        memberStatus: data?.memberStatus,
      }
      if (chgPwdMode) {
        tmpEdit = { ...tmpEdit, password: data?.password, memberStatus: staffFlag ? 'new' : 'verified', }
      }
      res = await globalState.client.service('users').patch(id, tmpEdit);
    }
    setLoading(false);
    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลเรียบร้อย' }, });
    EventEmitter.notify('ManageUser:refresh');
    navigation.goBack(2);
  }

  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5, }}>
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <ScrollView contentContainerStyle={[{ paddingBottom: 260, paddingHorizontal: 20 }, Platform.OS == 'web' ? { height: '80vh' } : null]}>
        {
          FIELD_LIST.map(item =>
            <CustomItem
              key={item.id.toString()} item={item} setData={setData} error={error} viewMode={viewMode} data={data}
            />
          )}
        <View style={{ marginTop: 20 }} />
        {/* {[
            { id: 1, title: 'บันทึก', visible: true, onPress: () => onSave(userId), style: { backgroundColor: themeColor.color1 } },
            {
              id: 3, title: chgPwdMode ? 'ไม่เปลี่ยนรหัสผ่าน' : 'เปลี่ยนรหัสผ่าน', visible: userId != 0, onPress: () => {
                setChgPwdMode(prev => !prev);
                setData(prev => ({ ...prev, password: '', repassword: '' }))
              }, style: { backgroundColor: themeColor.color6 }
            },
            // { id: 2, title: 'ลบ', onPress: () => (userId == 0) ? null : onDelete(userId), style: { backgroundColor: themeColor.color6 } }
          ].map(item => {
            if (item.visible) {
              return (
                <TouchableOpacity key={item.id.toString()} style={{ marginTop: 10, alignSelf: 'center' }} onPress={() => item.onPress()}>
                  <View style={[{
                    height: 40, width: Dimensions.get('window').width - 40,
                    borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginTop: 10
                  }, item.style]}>
                    <Text style={{ color: 'white' }}>{item.title}</Text>
                  </View>
                </TouchableOpacity>
              )
            } else {
              return <View key={item.id.toString()} />
            }

          })} */}

        <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 10 }}>
          {(['admin'].includes(adminType) || (staffFlag && ['cs'].includes(adminType))) &&
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '40%' }} onPress={() => {
              setChgPwdMode(prev => !prev);
              setData(prev => ({ ...prev, password: '', repassword: '' }));
            }}>
              <View style={{
                height: 40, borderRadius: 10,
                justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color6
              }}>
                <Text style={{ color: 'white' }}>{chgPwdMode ? 'ไม่เปลี่ยนรหัสผ่าน' : 'เปลี่ยนรหัสผ่าน'}</Text>
              </View>
            </TouchableOpacity>
          }
          {(['admin'].includes(adminType) || (chgPwdMode && ['cs'].includes(adminType))) &&
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '40%' }} onPress={() => onSave(userId)} >
              <View style={{
                height: 40, borderRadius: 10,
                justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color1
              }}>
                <Text style={{ color: 'white' }}>บันทึก</Text>
              </View>
            </TouchableOpacity>
          }
        </View>

        {loading &&
          <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size='large' />
          </View>
        }
      </ScrollView>
      {/* </TouchableWithoutFeedback> */}
    </SafeAreaView>
  )
}

function CustomItem({ item, setData, error, viewMode, data }) {
  if (item.type == 'avatar') {
    return <UserAvatar item={item} viewMode={viewMode} setData={setData} data={data} />
  } else if (item.type == 'text') {
    return <CustomText item={item} setData={setData} error={error} />
  } else if (item.type == 'gender') {
    return <UserGender item={item} setData={setData} error={error} viewMode={viewMode} />
  } else if (item.type == 'date') {
    return <UserDate item={item} setData={setData} error={error} />
  } else if (item.type == 'datetime') {
    return <UserDateTime item={item} error={error} />
  } else if (item.type == 'status') {
    return <UserStatus item={item} setData={setData} error={error} />
  }

}

function UserAvatar({ item, viewMode, setData, data }) {
  const fileRef1 = useRef();
  const uploadPhoto1 = async (e) => {
    uploadPhoto2(e.target.files)
  }

  const uploadPhoto2 = async (targetFiles) => {
    let images;
    try {
      images = await uploadPhoto('profile', false, 'photolib', targetFiles);
    } catch { }
    console.log('images!!', images);
    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }
    for (const item of images) {
      setData(prev => ({ ...prev, avatar: `${server_var.server_api}/images/profile/${item.filename}` }))
    }
  }

  return (
    <View style={{ alignItems: 'center', paddingHorizontal: 10 }}>
      <TouchableOpacity onPress={() => {
        if (!viewMode) {
          if (Platform.OS == 'web') {
            fileRef1.current.click();
          } else {
            uploadPhoto2('')
          }
        }
      }}>
        <View>
          <Image
            source={{ uri: item?.value }}
            style={{ width: 100, height: 100, borderRadius: 50, marginVertical: 15 }}
          />
          {!viewMode &&
            <View style={{ position: 'absolute', right: 0, bottom: 10 }}>
              <CustomIcon name="plus-circle" size={25} color={themeColor.color1} />
            </View>
          }
        </View>
      </TouchableOpacity>
      <View style={{ marginBottom: 0, marginTop: 10, paddingHorizontal: 15, paddingVertical: 2, borderRadius: 10, backgroundColor: themeColor.color1 }}>
        <Text style={{ color: 'white', fontSize: 12 }}>
          {(data?.staffFlag ? (data?.roles ?? '') : (data?.allmemId) ? 'ALL member' : 'ผู้ใช้งานทั่วไป')}
        </Text>
      </View>
      {
        Platform.OS == 'web' && (
          <div style={{ display: 'flex' }}>
            <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadPhoto1} multiple accept=".mp4, .jpg, .png" />
          </div>
        )
      }
    </View>
  )
}

function CustomText({ item, setData, error }) {
  if (item.visible) {
    return (
      <View>
        <Text style={[{ fontSize: 14, marginTop: 10 }]}>{item.title}</Text>
        <TextInput
          {...item.textInputProp}
          value={item.value}
          onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
          style={[
            { height: 40, marginTop: 5 },
            { borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white', borderWidth: 1, },
            (error?.at == item.fieldName) ? { borderColor: 'red' } : { borderColor: 'lightgray' },
          ]}
        />
        {error?.at == item.fieldName && <Text style={{ color: 'red', textAlign: 'center' }}>{error.message}</Text>}
      </View>
    )
  } else {
    return (
      <View />
    )
  }
}

function UserGender({ item, setData, error, viewMode }) {
  return (
    <View>
      <Text style={[{ fontSize: 14, marginTop: 10 }]}>{item.title}</Text>
      <View style={[
        { paddingRight: 20, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 10 },
        error?.at == item.fieldName && { borderColor: 'red', borderWidth: 1 }
      ]}>
        {[
          { id: 1, title: 'ชาย', value: 'M' },
          { id: 2, title: 'หญิง', value: 'F' },
        ].map(item2 => (
          <TouchableOpacity key={item2.id.toString()} style={{ marginHorizontal: 10 }} onPress={() => !viewMode ? setData(prev => ({ ...prev, [item.fieldName]: item2.value })) : null}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <CustomIcon name={item2.value == item?.value ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
              <Text style={{ marginLeft: 5 }}>{item2.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
      {error?.at == item.fieldName && <Text style={{ color: 'red', textAlign: 'center' }}>{error.message}</Text>}
    </View>
  )
}

function UserDate({ item, setData, error }) {

  const [tmpBirthDate1, setTmpBirthDate1] = useState();

  useEffect(() => {
    const a = moment(tmpBirthDate1, 'DD/MM/YYYY');
    if (a.isValid() && tmpBirthDate1.length == 10) {
      setData(prev => ({ ...prev, [item.fieldName]: a.format('YYYY-MM-DD') }))
    }
  }, [tmpBirthDate1])

  useEffect(() => {
    const tmpDate = item?.value ? moment(item?.value).format('DD/MM/YYYY') : '';
    setTmpBirthDate1(tmpDate);
  }, [item?.value])

  return (
    <View>
      <Text style={[{ fontSize: 14, marginTop: 10 }]}>{item.title}</Text>
      <TextInput
        {...item.textInputProp}
        // defaultValue={tmpDate}
        value={tmpBirthDate1}
        onChangeText={text => setTmpBirthDate1(prev => getBirthDateOutput(prev, text))}
        style={[
          { height: 40, marginTop: 5 },
          { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' },
          error?.at == item.fieldName && { borderColor: 'red' }
        ]}
      />
      {error?.at == item.fieldName && <Text style={{ color: 'red', textAlign: 'center' }}>{error.message}</Text>}
    </View>
  )
}

function getBirthDateOutput(prev, text) {
  let tmpString = text.split('');
  if (tmpString.length > 10) return prev;

  if (prev?.length < text?.length) {
    if (text.length == 2) {
      tmpString.splice(2, 0, '/')
    } else if (text.length >= 2 && tmpString.length >= 3 && tmpString[2] != '/' && tmpString[1] != '/') {
      tmpString.splice(2, 0, '/')
    } else if (text.length == 5) {
      tmpString.splice(5, 0, '/')
    } else if (text.length >= 4 && tmpString.length >= 6 && tmpString[5] != '/' && tmpString[4] != '/') {
      tmpString.splice(5, 0, '/')
    }
  } else if (prev?.length > text?.length) {
    if (text.length == 3) {
      tmpString.splice(2, 1);
    }
    if (text.length == 6) {
      tmpString.splice(5, 1);
    }
  }
  return tmpString.join('');
}

function UserDateTime({ item, error }) {
  if (item.visible) {
    return (
      <View>
        <Text style={[{ fontSize: 14, marginTop: 10 }]}>{item.title}</Text>
        <TextInput
          {...item.textInputProp}
          value={item?.value}
          style={[
            { height: 40, marginTop: 5 },
            { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' },
            error?.at == item.fieldName && { borderColor: 'red' }
          ]}
        />
        {error?.at == item.fieldName && <Text style={{ color: 'red', textAlign: 'center' }}>{error.message}</Text>}
      </View>
    )
  } else {
    return (
      <View />
    )
  }
}

function UserStatus({ item, setData, error, viewMode }) {
  if (item.visible) {
    return (
      <View>
        <Text style={[{ fontSize: 14, marginTop: 10 }]}>{item.title}</Text>
        <View style={[
          { paddingRight: 20, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 10 },
          error?.at == item.fieldName && { borderColor: 'red', borderWidth: 1 }
        ]}>
          {[
            { id: 1, title: 'ปกติ', value: 'verified' },
            { id: 2, title: 'อยู่ในระหว่างลบบัญชี', value: 'todelete' },
          ].map(item2 => (
            <TouchableOpacity key={item2.id.toString()} style={{ marginHorizontal: 10 }} onPress={() => !viewMode ? setData(prev => ({ ...prev, [item.fieldName]: item2.value })) : null}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CustomIcon name={item2.value == item?.value ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
                <Text style={{ marginLeft: 5 }}>{item2.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
        {error?.at == item.fieldName && <Text style={{ color: 'red', textAlign: 'center' }}>{error.message}</Text>}
      </View>
    )
  } else {
    return (
      <View />
    )
  }
}