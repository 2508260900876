import React, { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator, Alert, TouchableWithoutFeedback, Keyboard, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { uploadPhoto, uploadFile, CustomIcon } from '../component/custom';

export function EditGreetingPart({ navigation, route }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ at: '', message: '' });

  const FIELD_LIST = [
    {
      id: 1, type: 'label', title: 'ประเภทร้าน', titleStyle: { marginTop: 10 }, fieldName: 'type', value: data?.type
    },
    {
      id: 2, type: 'label', title: 'บริการจัดส่ง', titleStyle: { marginTop: 10 }, fieldName: 'delivery', value: data?.delivery
    },
    {
      id: 3, type: 'quickReply', title: 'อาการเบื้องต้น', titleStyle: { marginTop: 10 }, fieldName: 'quickReply', value: data?.quickReply
    },    
    {
      id: 4, type: 'text', title: 'ข้อความต้อนรับ', titleStyle: { marginTop: 10 }, fieldName: 'greetingText', value: data?.greetingText, textInputProp: { multiline: true }, textInputStyle: { height: 150, marginTop: 5, padding: 5, textAlignVertical: "top" }
    },
    {
      id: 5, type: 'image', title: 'ไฟล์แนบ (รูปภาพ)', titleStyle: { marginTop: 10 }, fieldName: 'greetingPhoto', value: data?.greetingPhoto
    },
  ]

  useEffect(() => {
    if (route?.params?.id > 0) {
      loadData();
    }
  }, [route?.params?.id])

  const loadData = async () => {
    let query = {
      id: route?.params?.id,
      $select: ['id', 'settingName', 'settingValue']
    };
    const res = await globalState.client.service('settings').find({ query });
    console.log('\nsettings : ', route?.params?.id, res);
    setData(res.data[0].settingValue);    
  }

  const validateForm = () => {
    const blankField = ['greetingText'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    return true;
  }

  const onSave = async (id) => {
    setError({ at: '', message: '' });
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    let res;
    res = await globalState.client.service('settings').patch(id, {
      settingValue: data,
    });
    setLoading(false);
    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลเรียบร้อย' } });
    navigation.goBack(2);
  }

  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5, }}>
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <ScrollView contentContainerStyle={[
        { paddingBottom: 260, paddingHorizontal: 20 }, Platform.OS == 'web' ? { height: '80vh' } : null
      ]}>
        {
          FIELD_LIST.map(item =>
            <CustomItem key={item.id.toString()} item={item} setData={setData} setLoading={setLoading} error={error} />
          )}

        {data?.type &&
        <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 20, flex: 1 }}>
          <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onSave(route?.params?.id)} >
            <View style={{
              height: 40, borderRadius: 10,
              justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color1
            }}>
              <Text style={{ color: 'white' }}>บันทึก</Text>
            </View>
          </TouchableOpacity>
        </View> 
        }

        {loading &&
          <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size='large' />
          </View>
        }
      </ScrollView>
      {/* </TouchableWithoutFeedback> */}
    </SafeAreaView>
  )
}

function CustomItem({ item, setData, error }) {
  if (item.type == 'text') {
    return <CustomText item={item} setData={setData} error={error} />
  } else if (item.type == 'quickReply') {
    return <QuickReply item={item} setData={setData} error={error} viewMode={false} />
  } else if (item.type == 'image') {
    return <CustomImage item={item} setData={setData} error={error} viewMode={false} />
  } else if (item.type == 'label') {
    return <CustomLabel item={item} />
  }
}

function QuickReply({ item, setData, error, viewMode }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <View style={{
        paddingRight: 20, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 10
      }}>
        {[
          { id: 1, title: 'แสดงอาการเบื้องต้น', value: true },
          { id: 2, title: 'ไม่แสดงอาการเบื้องต้น', value: false },
        ].map(item2 => (
          <TouchableOpacity key={item2.id.toString()} style={{ marginHorizontal: 10 }} onPress={() => !viewMode ? setData(prev => ({ ...prev, [item.fieldName]: item2.value })) : null}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <CustomIcon name={item2.value == item?.value ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
              <Text style={{ marginLeft: 5 }}>{item2.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}

function CustomLabel({ item }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <Text style={[{ fontSize: 14, color: 'grey' }]}> - {item.value}</Text>
    </View>
  )
}

function CustomText({ item, setData, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <TextInput
        {...item.textInputProp}
        value={item.value}
        onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
        style={[item.textInputStyle, { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' }, error?.at == item.fieldName && { borderColor: 'red' }]}
      />
      {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
    </View>
  )
}

function CustomImage({ item, setData, error, viewMode }) {
  const fileRef1 = useRef();
  const uploadPhoto1 = async (e) => {
    uploadPhoto2(e.target.files)
  }

  const uploadPhoto2 = async (targetFiles) => {
    let images;
    try {
      images = await uploadPhoto('shop', false, 'photolib', targetFiles);
    } catch (e) {
      alert('มีปัญหาในการอัพโหลดรูปภาพ');
    }
    console.log('images!!', images);
    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }
    for (const item of images) {
      setData(prev => ({ ...prev, greetingPhoto: `${server_var.server_api}/images/shop/${item.filename}` }))
    }
  }

  if (item?.value) {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <View style={{ marginVertical: 10 }}>
          <Image style={{ width: 150, height: 150 }}
            source={{ uri: item?.value }}
            resizeMode="contain"
          />
          {!viewMode &&
            <TouchableOpacity
              style={{ position: "absolute", top: 0, left: 0 }}
              onPress={() => {
                setData(prev => ({ ...prev, greetingPhoto: '' }))
              }}
            >
              <View style={{ marginTop: 0, marginLeft: 0 }}>
                <CustomIcon name="close-circle" size={20} color={"red"} />
              </View>
            </TouchableOpacity>
          }
        </View>
      </View>
    )
  } else {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <TouchableOpacity
          style={{
            borderColor: "gray", borderWidth: 1, borderRadius: 6, width: 100, height: 100,
            margin: 10, justifyContent: "center", alignItems: "center", padding: 5
          }}
          onPress={() => {
            if (!viewMode) {
              if (Platform.OS == 'web') {
                fileRef1.current.click();
              } else {
                uploadPhoto2('')
              }
            }
          }}
        >
          <CustomIcon name="plus-circle" size={20} color="gray" />
          <Text style={{ color: "gray", marginTop: 5 }}>ใส่รูปภาพ</Text>
        </TouchableOpacity>
        {
          Platform.OS == 'web' && (
            <div style={{ display: 'flex' }}>
              <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadPhoto1} multiple accept=".jpg, .png" />
            </div>
          )
        }
      </View>
    )
  }
}
