import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, ActivityIndicator, TouchableWithoutFeedback, Keyboard, Alert } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { uploadPhoto } from '../component/custom';
// import DateTimePicker from '@react-native-community/datetimepicker';
import moment from 'moment';
import { CustomIcon, CustomDatePicker5, CustomTimePicker5 } from '../component/custom';

export function FeedNotiPart({ navigation }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState({
    title: null, text: null, link: 'https://', image: null, publicDate: null,
    publicTime: null, scheduleFlag: false, feedType: 'PromoMessage'
  });
  const [viewMode, setViewMode] = useState(false)

  const FIELD_LIST = [
    {
      id: 1, type: 'radio', fieldName: 'feedType',
      radioChoice: [
        { id: 1, title: 'ส่งการแจ้งเตือนเข้ากระดิ่ง (สำหรับผู้ใช้งานที่ยอมรับข่าวสารและโปรโมชัน)', value: 'PromoMessage' },
        { id: 2, title: 'ส่งการแจ้งเตือนเข้ากระดิ่ง (สำหรับผู้ใช้งานทุกคน)', value: 'Message' },
        { id: 3, title: 'ส่งการแจ้งเตือนแบบ Pop Up Banner (สำหรับผู้ใช้งานที่ยอมรับข่าวสารและโปรโมชัน)', value: 'AlertImageFull' }
      ]
    },
    { id: 2, type: 'break' },
    {
      id: 3, type: 'radio', fieldName: 'scheduleFlag',
      radioChoice: [{ id: 1, title: 'ส่งตอนนี้', value: 0 }, { id: 2, title: 'ตั้งเวลา', value: 1 }]
    },
    {
      id: 4, type: 'datetime', title: 'วัน/เวลา',
    },
    { id: 5, type: 'break' },
    {
      id: 6, type: 'text', title: 'หัวข้อ', titleStyle: { marginTop: 10 }, fieldName: 'title', value: data?.title,
      hide: data?.feedType == 'AlertImageFull',
      textInputStyle: { height: 40, marginTop: 5 },
      textInputProp: { editable: !viewMode }
    },
    {
      id: 7, type: 'text', title: 'เนื้อหา', titleStyle: { marginTop: 10 }, fieldName: 'text', value: data?.text, textInputProp:
        { multiline: true, editable: !viewMode },
      hide: data?.feedType == 'AlertImageFull',
      textInputStyle: { height: 120, marginTop: 5, padding: 5, textAlignVertical: "top" },
    },
    {
      id: 8, type: 'text', title: 'ลิงค์', titleStyle: { marginTop: 10 }, fieldName: 'link', value: data?.link,
      textInputStyle: { height: 40, marginTop: 5 },
      textInputProp: { editable: !viewMode }
    },
    {
      id: 9, type: 'imageupload', fieldName: 'image'
    },
  ]

  useEffect(() => {
    const loadUserRole = async () => {
      const res = await globalState.client.service('users').get(globalState?.user?.id, { query: { $select: ['roles'] } });
      setViewMode(['cs', 'rapd'].includes(res?.roles));
    }
    if (globalState.user) {
      loadUserRole();
    }
  }, [])

  const proceedSendNoti = async () => {
    const sendNow = moment().utc().format('YYYY-MM-DD HH:mm');
    const publicDateTime =
      data?.scheduleFlag == 0 ?
        sendNow :
        ((data?.publicDate && data?.publicTime) ?
          moment(data['publicDate'] + ' ' + data['publicTime'], 'DD-MM-YYYY HH:mm').utc().format('YYYY-MM-DD HH:mm') :
          sendNow
        );
    const res = await globalState.client.service('feeds').create({
      userId: globalState.user.id,
      feedType: data?.feedType,
      title: data?.title,
      content: data?.text,
      feedPhoto1: data?.image,
      feedLink: (data?.link == 'https://') ? null : data?.link,
      feedPublic: 0,
      publicDateTime: publicDateTime,
    });

    dispatch({
      type: 'showToast',
      payload: { text: `เริ่มส่งการแจ้งเตือน (${data?.scheduleFlag == 0 ? 'ส่งตอนนี้' : 'ตั้งเวลา'})` },
    });
    await delay(1000);
    navigation.goBack(1);
  }

  const sendNoti = async () => {
    if (data?.feedType == 'PromoMessage' && (!data?.title || !data?.text)) {
      alert('หัวข้อ/เนื้อหา ไม่สามารถเว้นว่างได้');
    } else if (data?.feedType == 'Message' && (!data?.title || !data?.text)) {
      alert('หัวข้อ/เนื้อหา ไม่สามารถเว้นว่างได้');
    } else if (data?.feedType == 'AlertImageFull' && (!data?.image)) {
      alert('รูป ไม่สามารถเว้นว่างได้');
    } else {
      if (Platform.OS == 'web') {
        if (window.confirm('ยืนยันส่งการแจ้งเตือน')) {
          proceedSendNoti();
        }
      } else {
        return Alert.alert('ยืนยันส่งการแจ้งเตือน', 'การแจ้งเตือนนี้จะถูกส่งไปยังผู้ใช้ทุกคน !!',
          [
            { text: 'ยกเลิก' },
            { text: 'ยืนยัน', onPress: () => proceedSendNoti() },
          ]
        );
      }
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5 }}>
      <ScrollView style={[{ paddingHorizontal: 20 }, Platform.OS == 'web' ? { height: '80vh' } : null]}>
        <View style={{ flex: 1, alignItems: 'flex-end', marginTop: 10, marginBottom: 10 }}>
          <TouchableOpacity style={{
            flexDirection: 'row', borderColor: "gray", borderWidth: 0, borderRadius: 6, height: 40,
            justifyContent: "center", alignItems: "center", paddingHorizontal: 10, backgroundColor: themeColor.color1
          }} onPress={() => navigation.navigate('ViewFeedNoti')} >
            <CustomIcon name="clock-alert-outline" size={20} color="white" />
            <Text style={{ color: "white", marginLeft: 5 }}>ดูรายการตั้งเวลาส่ง</Text>
          </TouchableOpacity>
        </View>

        {FIELD_LIST.map(item => <CustomView key={item.id.toString()} item={item} setData={setData} data={data} viewMode={viewMode} />)}

        {!viewMode &&
          <TouchableOpacity
            style={{
              marginTop: 15, alignSelf: 'center', height: 40, width: '80%',
              backgroundColor: themeColor.color1, borderRadius: 10, justifyContent: 'center', alignItems: 'center'
            }}
            onPress={() => sendNoti()}
          >
            <Text style={{ color: 'white' }}>ส่งการแจ้งเตือน</Text>
          </TouchableOpacity>
        }
        <View style={{ width: '80%', height: 260 }} />
      </ScrollView>
    </SafeAreaView>
  )
}

function CustomView({ item, setData, data, viewMode }) {
  const fileRef1 = useRef()
  if (!item?.hide) {
    if (item?.type == 'text') {
      return (
        <View >
          <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
          <TextInput
            {...item.textInputProp}
            value={item.value}
            onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
            style={[item.textInputStyle, { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' }]}
          />
        </View>
      )
    } else if (item?.type == 'radio') {
      return (
        <View style={{
          paddingRight: 20, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 10, marginTop: 15
        }}>
          {item.radioChoice.map(item2 => (
            <TouchableOpacity key={item2.id.toString()} style={{ marginHorizontal: 10, marginTop: 5 }}
              onPress={() => !viewMode ? setData(prev => ({ ...prev, [item?.fieldName]: item2.value })) : null}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CustomIcon name={item2.value == data[item?.fieldName] ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
                <Text style={{ marginLeft: 10 }}>{item2.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      )
    } else if (item?.type == 'datetime') {
      return (
        <DateAndTimeSchedule data={data} setData={setData} />
      )
    } else if (item?.type == 'break') {
      return (
        <View
          key={item.id.toString()}
          style={{ flex: 1, borderBottomWidth: 1, borderColor: themeColor.color12, marginVertical: 10 }}
        />
      )
    } else if (item?.type == 'imageupload') {
      if (data[item.fieldName]) {
        return (
          <View style={{ flexDirection: 'row', marginVertical: 10, justifyContent: "center", alignItems: "center", }} >
            <TouchableOpacity>
              <Image style={{ width: 150, height: 150 }}
                source={{ uri: data[item.fieldName] }}
                resizeMode="contain"
              />
              <TouchableOpacity
                style={{ position: "absolute", top: 0, right: 0 }}
                onPress={() => !viewMode ? setData(prev => ({ ...prev, [item.fieldName]: null })) : null}
              >
                <View style={{ marginTop: 0, marginLeft: 0 }}>
                  <CustomIcon name="close-circle" size={20} color={"red"} />
                </View>
              </TouchableOpacity>
            </TouchableOpacity>
          </View>
        )
      } else {
        return (
          <View style={{ flexDirection: 'row', marginVertical: 10, justifyContent: "center", alignItems: "center", }} >
            <TouchableOpacity
              style={{
                borderColor: "gray", borderWidth: 1, borderRadius: 6, width: 100, height: 100,
                margin: 10, justifyContent: "center", alignItems: "center", padding: 5
              }}
              onPress={() => {
                if (!viewMode) {
                  if (Platform.OS == 'web') {
                    fileRef1.current.click();
                  } else {
                    uploadPhoto2(item?.fieldName, setData)
                  }
                }
              }}
            >
              <CustomIcon name="plus-circle" size={20} color="gray" />
              <Text style={{ color: "gray", marginTop: 5 }}>ใส่รูปภาพ</Text>
            </TouchableOpacity>
            {
              Platform.OS == 'web' && (
                <div style={{ display: 'flex' }}>
                  <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={e => uploadPhoto1(e, item?.fieldName, setData)} multiple accept=".mp4, .jpg, .png" />
                </div>
              )
            }
          </View>
        )
      }
    }
  } else {
    return <View />
  }

}

const uploadPhoto1 = async (e, fieldName, setData) => {
  uploadPhoto2(fieldName, setData, e.target.files)
}

const uploadPhoto2 = async (fieldName, setData, targetFiles) => {
  let images;
  try {
    images = await uploadPhoto('feed', false, 'photolib', targetFiles);
  } catch { }
  console.log('Banner images : ', images);
  if (!images) return;
  if (!Array.isArray(images)) {
    images = [images];
  }

  for (const item of images) {
    setData(prev => ({ ...prev, [fieldName]: `${server_var.server_api}/images/feed/${item.filename}` }))
  }
}

const uploadFeedPhoto = async (fieldName, setData) => {
  let images;
  try {
    images = await uploadPhoto('feed', false, 'photolib');
  } catch { }
  console.log('images!!', images);
  if (!images) return;
  if (!Array.isArray(images)) {
    images = [images];
    console.log('hello images', images);
  }
  for (const item of images) {
    setData(prev => ({ ...prev, [fieldName]: `${server_var.server_api}/images/feed/${item.filename}` }))
  }
}

function DateAndTimeSchedule({ data, setData }) {
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  return (
    <View>
      {data?.scheduleFlag == 1 && (
        <View style={{ marginVertical: 10, marginHorizontal: 10, flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flex: 0.3 }}>
            <Text style={{ fontSize: 14 }}>วันที่</Text>
          </View>
          <View style={{ flex: 0.7 }}>
            <CustomDatePicker5
              value={data?.publicDate}
              onChange={(selectedDate) => {
                setData(prev => ({ ...prev, publicDate: moment(selectedDate).format('DD/MM/YYYY') }))
              }}
              dialogWidth={dialogWidth}
            />
          </View>
        </View>
      )}
      {data?.scheduleFlag == 1 && (
        <View style={{ marginVertical: 10, marginHorizontal: 10, flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flex: 0.3 }}>
            <Text style={{ fontSize: 14 }}>เวลา</Text>
          </View>
          <View style={{ flex: 0.7 }}>
            <CustomTimePicker5
              value={data?.publicTime}
              onChange={(selectedTime) => {
                setData(prev => ({ ...prev, publicTime: moment(selectedTime).format('HH:mm') }))
              }}
              dialogWidth={dialogWidth}
            />
          </View>
        </View>
      )}
    </View>
  )
}

const delay = ms => new Promise(res => setTimeout(res, ms));