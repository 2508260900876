import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, SafeAreaView, ScrollView, Dimensions, Image, TouchableOpacity, Linking } from 'react-native';
import { store } from '../sharewebapp/store'
import { fontStyles, server_var, themeColor } from '../config/servervar';
import { CustomWebView, CustomIcon } from '../component/custom';

export function ViewWebSimplePart({ navigation, route }) {
  const { globalState, dispatch } = useContext(store);
  const { title, uri, actionList, userId, mobileNumber, hideBackButton, otpCode, uriPDF } = route?.params;
  const screenWidth = Dimensions.get('window').width

  useEffect(() => {
    if (uriPDF) {
      navigation.setOptions(
        {
          headerRight: () => (
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity style={{ marginRight: 10 }} onPress={() => { 
                Linking.openURL(uriPDF);
              }}>
                <CustomIcon name="tray-arrow-up" size={28} color="gray" />
              </TouchableOpacity>
            </View>
          ),
        }
      )
    }
  }, [uri])  

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
      <View style={{ flex: 1, justifyContent: 'space-between' }}>
        <CustomWebView
          style={{ marginTop: 0, paddingHorizontal: 10 }}
          source={{ uri }}
        />
      </View >
    </SafeAreaView >
  )
}
