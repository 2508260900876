import React, { useState, useCallback, useEffect, useContext } from 'react'
import { Button, TouchableOpacity, Text, View, SafeAreaView, FlatList, Dimensions, Image, StatusBar, Platform, TextInput } from 'react-native'
import { themeColor, fontStyles, server_var } from '../config/servervar';
import { store } from './store'
import moment from 'moment';
import { numberWithCommas, getTotalPriceText } from './common1'
import { DELIVER_LIST, ORDER_STATUS, ORDER_VOID_STATUS } from './common1';
import EventEmitter from "./EventEmitter";

export function ManageOrderVoidPart({ navigation, route }) {
  const forAdmin = route?.params?.forAdmin;
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    EventEmitter.addListener('ManageOrderVoid:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('ManageOrderVoid:refresh', handleRefresh);
    };
  }, [])

  const handleRefresh = () => {
    loadData(0);
  }

  useEffect(() => {
    loadData(0);
  }, [])

  const loadData = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      let res;
      let query = { orderVoidStatus: { $in: ['void_request', 'void_process'] }, $skip: pSkip ?? skip, $sort: { id: -1 } }

      try {
        //alert(JSON.stringify(query));
        res = await globalState.client.service('ordermains').find({ query });
      } catch { }

      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }

      setLoading(false);
    }
  }

  const onManageOrderVoidPress = (pressParam) => {
    navigation.navigate('ViewOrder', pressParam);
  }

  return (
    <View style={{ flex: 1, backgroundColor: 'white', margin: 10, borderRadius: 10 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <FlatList
          keyExtractor={(item) => item.id.toString()}
          data={dataList}
          style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: '85vh' } : null]}
          onEndReached={(xxx) => loadData()}
          onEndReachedThreshold={0.5}
          onRefresh={() => loadData(0)}
          refreshing={(loading)}
          renderItem={({ item }) => (
            <Item item={item} onManageOrderVoidPress={onManageOrderVoidPress} />
          )}
        />
      </SafeAreaView>
    </View>
  )
}

function Item({ item, onManageOrderVoidPress }) {
  return (
    <TouchableOpacity onPress={() => onManageOrderVoidPress({ ordermainId: item.id })}>
      <View style={{
        paddingVertical: 10, borderBottomWidth: 1,
        borderColor: 'lightgray', paddingBottom: 15, marginHorizontal: 20,
      }}>    
        {[
          { id: 1, title: 'หมายเลขคำสั่งซื้อ', value: item?.id, textStyle: { color: themeColor.color1 } },
          { id: 2, title: 'ผู้รับบริการ', value: item?.user?.displayName ?? '', textStyle: {} },
          { id: 3, title: 'สาขาที่ให้บริการ', value: item?.shop?.shopThName, textStyle: {} },
          { id: 4, title: 'การจัดส่ง', value: DELIVER_LIST.find(item2 => item2.value == item?.deliverType)?.title, textStyle: {} },
          { id: 5, title: 'สถานะ', value: ORDER_VOID_STATUS.find(item2 => item2.value == item?.orderVoidStatus)?.title, textStyle: {} },
          { id: 6, title: 'เหตุผลการยกเลิก', value: item?.orderVoidOtherReasonText ?? (item?.orderVoidReasonName ?? ''), textStyle: {} },
          { id: 7, title: 'ยอดชำระ', value: getTotalPriceText(item), textStyle: {} },
          { id: 8, title: 'วันและเวลาที่สั่งซื้อ', value: moment(item.createdAt).format('DD/MM/YYYY HH:mm'), textStyle: { color: 'gray' } },
        ].map(item3 => (
          <View key={item3.id.toString()} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 0.4 }}>
              <Text style={item3?.textStyle}>{item3?.title}</Text>
            </View>
            <View style={{ flex: 0.1 }}>
              <Text style={item3?.textStyle}>:</Text>
            </View>
            <View style={{ flex: 0.5 }}>
              <Text style={item3?.textStyle}>{item3?.value}</Text>
            </View>
          </View>
        ))}
      </View>
    </TouchableOpacity>
  )
}
