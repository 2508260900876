import React from 'react'
import { Refine } from "@pankod/refine-core";
import { Layout, ReadyPage, notificationProvider, ErrorComponent, LoginPage, AntdLayout, Icon, Icons } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "./component/dataProvider";
import authProvider from "./component/authProvider";
import { CustomSider } from "./component/sider";
import { Layout as AntLayout, Grid, Button } from "antd";
import { useLogin, useLogout } from "@pankod/refine-core";
import "@pankod/refine-antd/dist/styles.min.css";
import "./App.css";
import { CustomHeader } from "./component/header";
import { Login } from "./pages/login";
// import { store } from "../src/component/store";
import { store } from "../src/sharewebapp/store";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
// import { UserList, UserShow, UserEdit, UserCreate } from "./pages/users";
// import { PharmacistList, PharmacistShow, PharmacistEdit, PharmacistCreate } from './pages/pharmacists';
import liveProvider from "./component/liveProvider";
import { Test1 } from "./pages/test1";
import { useCommon } from './sharewebapp/common1';
import { ManageOrderScreen } from './pages/manageorder';
import { ComCenterScreen } from './pages/comcenter';
// import { HomePharmacist } from './pages/homepharmacist';
import { HomeScreen } from './pages/home';
import { SettingCenterScreen } from './pages/settingcenter';
import { server_var } from './config/servervar';
import { ShopCenterScreen } from './pages/shopcenter';
import { mdiShare, mdiAccountCircle, mdiBasket, mdiHistory, mdiChat, mdiStore, mdiHome, mdiLogout } from '@mdi/js';
import { View, Text } from 'react-native-web';
import Icon5 from '@mdi/react';
import { AppPage } from './pages/apppage';
import { TestScreen1 } from './pages/testscreen1';
import { AppRemovePage } from './pages/appremove1';
import { AppPage2 } from './pages/apppage2';
import { AmbAppPage } from './pages/ambapppage';

// console.log = () => { }
// console.error = () => { }
// console.debug = () => { }

const CustomDashboardPage = () => (
  <div style={{ width: '100vw', height: '80vh', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <img src="allpharmasee_logo1.png" alt="All PharmaSee Logo" style={{ width: 400 }} />
      <div style={{ marginTop: 20 }}>
        version {server_var.version}
      </div>
    </div>
  </div>);

const App = () => {
  const { globalState, dispatch } = useContext(store);
  useCommon(globalState, dispatch);

  return (
    <div>
      <Refine
        authProvider={authProvider(globalState, dispatch)}
        routerProvider={{
          ...routerProvider,
          routes: [
            { element: <Test1 />, path: '/test1', layout: true },
            { element: <AppPage />, path: '/app', layout: false },
            { element: <AppPage2 />, path: '/app2', layout: false },
            { element: <AmbAppPage />, path: '/amb', layout: false },
            { element: <AppRemovePage />, path: '/how_to_remove_account', layout: false },
            { element: <TestScreen1 />, path: '/testscreen1', layout: true },
            { element: <ManageOrderScreen />, path: '/manage_order', layout: true },
            { element: <ComCenterScreen />, path: '/com_center', layout: true },
            // { element: <HomePharmacist />, path: '/home_pharmacist', layout: true },
            // { element: <HomeScreen />, path: '/home_pharmacist', layout: true },
            { element: <HomeScreen />, path: '/', layout: true },
            { element: <ShopCenterScreen />, path: '/shopcenter', layout: true },
            { element: <SettingCenterScreen />, path: '/settingcenter', layout: true },

          ]
        }}
        liveProvider={liveProvider(globalState, dispatch)}
        dataProvider={dataProvider(server_var.server_api)}
        Layout={CustomLayout}
        ReadyPage={ReadyPage}
        notificationProvider={notificationProvider}
        catchAll={<ErrorComponent />}
        LoginPage={Login}
        Sider={CustomSider}
        Header={() => (<CustomHeader />)}
        Footer={() => (<CustomFooter />)}
        liveMode="auto"
        syncWithLocation={true}
        // DashboardPage={CustomDashboardPage}
        DashboardPage={HomeScreen}
        resources={[
          // { name: 'wait_to_consult', icon: <Icons.HourglassOutlined />, options: { label: 'รอปรึกษา', badge: globalState?.totalUnread?.queline } },
          // { name: 'Dashboard', options: { label: 'หน้าแรก' } },
          // { name: 'Dashboard', options: { label: 'หน้าแรก' } },
          { name: '', icon: <Icon5 path={mdiHome} size={0.8} color={'white'} />, options: { label: 'หน้าหลัก' } },
          // { name: 'home_pharmacist', icon: <Icon5 path={mdiHome} size={0.8} color={'white'} />, options: { label: 'หน้าหลัก' } },
          { name: 'com_center', icon: <Icon5 path={mdiChat} size={0.8} color={'white'} />, options: { label: 'แชท', badge: (globalState?.totalUnread?.queline + globalState?.totalUnread?.u2s) } },
          { name: 'manage_order', icon: <Icon5 path={mdiBasket} size={0.8} color={'white'} />, options: { label: 'จัดการคำสั่งซื้อ' } },
          { name: 'shopcenter', icon: <Icon5 path={mdiStore} size={0.8} color={'white'} />, options: { label: 'ร้านของฉัน' } },
          { name: 'settingcenter', icon: <Icon5 path={mdiAccountCircle} size={0.8} color={'white'} />, options: { label: 'บัญชีของฉัน' } },
          // { name: 'shop_chat', icon: <Icons.CommentOutlined />, options: { label: 'กำลังปรึกษา', badge: globalState?.totalUnread?.u2s } },
          // { name: 'system' },
          // {
          //   name: "users",
          //   parentName: 'system',
          //   list: UserList,
          //   show: UserShow,
          //   edit: UserEdit,
          //   create: UserCreate,
          //   canDelete: true,
          //   icon: <Icons.UserOutlined />,
          //   options: { label: 'ลูกค้า' }
          // },
          // {
          //   name: "users",
          //   parentName: 'system',
          //   list: PharmacistList,
          //   show: PharmacistShow,
          //   edit: PharmacistEdit,
          //   create: PharmacistCreate,
          //   canDelete: true,
          //   icon: <Icons.UserOutlined />,
          //   options: { 
          //     label: 'เภสัชกร',
          //     // route:'/users' 
          //     // route: '/system/users'
          //   }
          // },
          // { name: 'test1', parentName: 'system', icon: <Icons.MehOutlined />, options: { label: 'ทดสอบ' } },
          // { name: 'test2', parentName: 'system', icon: <Icons.MehOutlined />, options: { label: 'ทดสอบ 2' } },
          { name: 'logout', icon: <Icon5 path={mdiLogout} size={0.8} color={'white'} />, options: { label: 'Logout' } },
        ]}
      />
    </div>
  );
};

export const CustomLayout = ({ children, Header, Sider, Footer, OffLayoutArea }) => {
  const breakpoint = Grid.useBreakpoint();

  const SiderToRender = Sider;
  const HeaderToRender = Header;

  return (
    <AntLayout style={{ minHeight: "98vh", flexDirection: "column" }}>
      <AntLayout>
        <SiderToRender />
        <AntLayout.Content>
          <div style={{ padding: 0, minHeight: "98vh", display: 'flex' }}>
            {children}
          </div>
          <Footer />
        </AntLayout.Content>
      </AntLayout>
      <ToastView />
    </AntLayout>
  );
};

const CustomFooter = () => {
  return (
    // <div style={{
    //   backgroundColor: "white", height: "64px", display: "flex", justifyContent: "center", alignItems: "center"
    // }}
    // >
    //   Custom Footer Content
    // </div>
    <div></div>
  )
}

function ToastView() {
  const { globalState, dispatch } = useContext(store);
  useEffect(() => {
    if (globalState.toast.visible) {
      setTimeout(() => {
        dispatch({ type: 'hideToast' });
      }, globalState.toast.timeout);
    }
  }, [globalState.toast.visible]);

  if (globalState.toast.visible) {
    return (
      <View
        style={{
          position: 'absolute', bottom: 80, justifyContent: 'center', alignItems: 'center',
          alignSelf: 'center', backgroundColor: 'lightgray', padding: 10, borderRadius: 10,
        }}>
        <Text style={{ color: 'black' }}>{globalState.toast.text}</Text>
      </View>
    );
  } else {
    return <View />;
  }
}


export default App;