import React, { useState, useEffect, useCallback, useContext, forwardRef, useRef, useImperativeHandle } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, SafeAreaView, ScrollView, Dimensions, Modal, Alert, Linking } from 'react-native';

import { server_var, themeColor } from '../config/servervar';
import { store } from '../sharewebapp/store'
import { CustomIcon } from '../component/custom';
import moment from 'moment';
import EventEmitter from '../sharewebapp/EventEmitter';
import { getJwtToken } from '../component/custom';

export function ViewPharmaNotePart(props) {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={{ height: '60vh' }}>
        <ViewPharmaNoteView {...props} />
      </ScrollView>
    </SafeAreaView>
  )
}

export function ViewPharmaNoteView({ showEditPharmaNote, route, navigation }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState();
  const [orderList, setOrderList] = useState([]);
  const { pharmaNoteId } = route?.params;
  const [dialog, setDialog] = useState({ visible: false });

  useEffect(() => {
    EventEmitter.addListener('ViewPharmaNote:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('ViewPharmaNote:refresh', handleRefresh);
    };
  }, [])

  const handleRefresh = () => {
    loadData();
  }

  useEffect(() => {
    loadData()
  }, [route])

  const loadData = async () => {
    if (pharmaNoteId) {
      const res = await globalState.client.service('pharmanotes').get(pharmaNoteId);
      setData(res);
      const ordermainId = res?.oldqueline?.ordermain?.id;
      if (ordermainId) {
        const res1 = await globalState.client.service('orderitems').find({ query: { ordermainId, itemType: 'normal', $limit: 200 } });
        setOrderList(res1.data);
      }
    }
  }

  const paidAlreadyFlag = ['in_basket', 'cancel_by_admin', 'cancel_by_system', 'cancel']
    .includes(data?.oldqueline?.ordermain?.orderStatus) ? false : true;

  const COMPONENT_LIST = [
    { id: 1, type: 'inline', title: 'ชื่อผู้รับบริการ', field: data?.user?.displayName },
    { id: 2, type: 'inline', title: data?.shop?.shopType == 'special1' ? 'ผู้ให้บริการ' : 'เภสัชกร', field: data?.pharmacist?.displayName },
    { id: 3, type: 'inline', title: 'ร้านยา', field: `${data?.shop?.shopThName}` },
    { id: 4, type: 'inline', title: 'วันและเวลาที่รับบริการ', field: moment(data?.startTime).format('DD/MM/YYYY HH:mm') },
    { id: 5, type: 'break' },
    { id: 6, type: 'nextline', title: 'อาการเบื้องต้น', field: data?.symtom },
    { id: 7, type: 'break' },
    { id: 8, type: 'nextline', title: 'บันทึกภายใน', field: data?.internal, hide: (!globalState?.user?.staffFlag) },
    { id: 9, type: 'break', hide: (!globalState?.user?.staffFlag) },
    { id: 10, type: 'medicinelist', title: 'ยาที่แนะนำ', field: orderList, hide: (data?.psctype == 'not_prescribe' || !paidAlreadyFlag) },
    { id: 11, type: 'break', hide: (data?.psctype == 'not_prescribe' || !paidAlreadyFlag) },
    { id: 12, type: 'nextline', title: 'คำแนะนำเพิ่มเติม', field: data?.notes, hide: (data?.psctype == 'not_prescribe') },
    { id: 13, type: 'break', hide: (data?.psctype == 'not_prescribe' || !paidAlreadyFlag) },
    { id: 14, type: 'attachment', title: '', field: data?.attachment, hide: (data?.psctype == 'not_prescribe' || !paidAlreadyFlag) },
  ]

  return (
    <View>
      <View style={{ flexDirection: 'row', paddingHorizontal: 20, marginTop: 10, justifyContent: 'space-between' }}>
        <Text style={{ fontWeight: 'bold', }}>
          การปรึกษา
          <Text style={{ fontWeight: 'normal', color: 'gray', fontSize: 12 }}> เลขที่ {data?.id}</Text>
        </Text>
        {showEditPharmaNote && (
          <TouchableOpacity onPress={() =>
            navigation.navigate('PharmaNote', { pharmaNoteId: data?.id })
          }>
            <View><CustomIcon name="note-edit-outline" size={26} color={themeColor.color1} /></View>
          </TouchableOpacity>
        )}
        {!showEditPharmaNote && (
          <TouchableOpacity onPress={() => setDialog({ visible: true, pharmaNoteId: pharmaNoteId })}>
            <View><CustomIcon name="file-move" size={26} color={themeColor.color1} /></View>
          </TouchableOpacity>
        )}
      </View>

      <View style={{
        paddingHorizontal: 20, paddingVertical: 10, backgroundColor: 'white',
        marginHorizontal: 20, borderRadius: 10, marginTop: 10, marginBottom: 20
      }}>
        {COMPONENT_LIST.map(item => <CustomCom key={item.id.toString()} item={item} />)}
      </View>
      <Dialog dialog={dialog} setDialog={setDialog} />
    </View>
  )
}

function CustomCom({ item }) {
  const [seeMore, setSeeMore] = useState(false);

  if (item?.hide) {
    return <View />
  }

  if (item.type == 'medicinelist') {
    if (seeMore) {
      return (
        <View >
          <Text>{item.title}</Text>
          <View>
            {item.field.map((item2, index2) => (
              <View key={item2.id.toString()}
                style={[{ marginTop: 10, paddingTop: 10 }, index2 > 0 ? { borderTopWidth: 1, borderColor: 'lightgray' } : null]}
              >
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontSize: 12 }}>
                    {item2.amount}x {item2?.product?.thProductName} ({item2?.product?.unitSizeDesc})
                  </Text>
                </View>
                {item2?.product?.thDosage && (
                  <View style={{ marginTop: 5 }}>
                    <Text style={{ color: 'gray', fontSize: 12 }}>วิธีใช้ {item2?.product?.thDosage}</Text>
                  </View>
                )}
              </View>
            ))}
          </View>
          <TouchableOpacity style={{ marginTop: 5 }} onPress={() => setSeeMore(prev => !prev)}>
            <Text style={{ color: themeColor.color1, fontSize: 12 }}>ดูเพิ่มเติม {seeMore ? '<<' : '>>'}</Text>
          </TouchableOpacity>
        </View>
      )
    } else {
      return (
        <View >
          <Text>{item.title}</Text>
          <Text style={{ fontSize: 12 }}>{item.field.map(item2 => (`${item2.amount} x ${item2?.product?.thProductName}(${item2?.product?.unitSizeDesc})`)).join(' , ')}</Text>
          <TouchableOpacity style={{ marginTop: 5 }} onPress={() => setSeeMore(prev => !prev)}>
            <Text style={{ color: themeColor.color1, fontSize: 12 }}>ดูเพิ่มเติม {seeMore ? '<<' : '>>'}</Text>
          </TouchableOpacity>
          {seeMore && <Text>{JSON.stringify(item.field, '', 2)}</Text>}
        </View>
      )
    }

  } else if (item.type == 'break') {
    return <View style={{ height: 2, backgroundColor: themeColor.color8, marginVertical: 10, marginHorizontal: -20 }} />
  } else if (item.type == 'inline') {
    return (
      <View style={{ flexDirection: 'row', marginVertical: 2 }}>
        <Text style={{ flex: 0.4 }}>{item.title}</Text>
        <Text style={{ flex: 0.6 }}>{item.field}</Text>
      </View>
    )
  } else if (item.type == 'nextline') {
    return (
      <View >
        <Text>{item.title}</Text>
        <Text>{item.field}</Text>
      </View>
    )
  } else if (item.type == 'attachment') {
    return (
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginHorizontal: -10 }}>
        {item?.field?.map(item2 => (
          <TouchableOpacity
            key={item2.id.toString()}
            onPress={() => Linking.openURL(`${server_var.server_api}/${item2.path}`)}
            style={{ width: '46%', marginHorizontal: 5, marginVertical: 5, }}
          >
            <View key={item2.id.toString()} style={{
              height: 40, padding: 5, backgroundColor: 'lightgray', flexDirection: 'row', borderRadius: 10
            }}>
              <CustomIcon name="file-pdf-box" size={24} />
              <View style={{ flexWrap: 'wrap', flexDirection: 'row', flex: 1 }}>
                <Text style={{ fontSize: 12 }}>{item2?.title}</Text>
              </View>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    )
  } else {
    return <View />
  }
}

function Dialog({ dialog, setDialog, onBack }) {
  const { globalState, dispatch } = useContext(store);
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const [error, setError] = useState({ at: '', message: '' });

  const validateForm = () => {
    if (!dialog.email) {
      setError({ at: 'email', message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }

    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(dialog.email) === false) {
      setError({ at: 'email', message: 'อีเมลไม่ถูกต้อง' })
      return false;
    }

    return true;
  }

  const mailPharmaNote = async (pharmaNoteId, email) => {
    const jwtToken = await getJwtToken();
    const res = await fetch(`${server_var.server_api}/mailPharmaNote`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
      body: JSON.stringify({ pharmaNoteId, email })
    });
    const resJson = await res.json();
    console.log('send mail pharmanote : ', resJson);

    if (resJson.result == 'OK' && resJson.fileJwt) {
      dispatch({ type: 'showToast', payload: { text: 'ส่งประวัติการปรึกษาเรียบร้อยแล้ว' }, });
      Linking.openURL(`${server_var.server_api}/getpdf/${resJson.fileJwt}/pharma_note_${pharmaNoteId}.pdf`);
    } else {
      dispatch({ type: 'showToast', payload: { text: 'ไม่สามารถส่งอีเมลได้ กรุณาลองใหม่อีกครั้ง' }, });
    }
    setError({ at: '', message: '' })
    setDialog({ visible: false });
  }

  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <View style={{
          width: dialogWidth, height: dialogWidth * 1.5, paddingTop: 10, paddingBottom: 30, justifyContent: "flex-start",
          alignItems: 'center', borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
        }}>
          <ScrollView>
            <View style={{ marginVertical: 20, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 20 }}>
              <Text style={{ color: themeColor.color1, marginTop: 10, textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>ส่งประวัติการปรึกษา</Text>
              <Text style={{ color: themeColor.color1, marginBottom: 20, textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>ไปยังอีเมลของคุณ</Text>
              <Text style={{ textAlign: 'center', color: 'gray' }}>
                โปรดตรวจสอบให้แน่ใจว่าอีเมลที่คุณใส่นั้นถูกต้อง เพื่อให้เราสามารถส่งประวัติการปรึกษาให้คุณได้
              </Text>
              <TextInput
                value={dialog.email}
                onChangeText={text => setDialog(prev => ({ ...prev, email: text }))}
                placeholder={'กรุณาใส่อีเมลของคุณ'}
                style={[{ height: 40, width: "100%", borderRadius: 5, borderWidth: 1, paddingLeft: 10, alignSelf: 'center', marginTop: 20, textAlign: "left", backgroundColor: 'white' },
                error['at'] == 'email' ? { borderColor: themeColor.color6 } : { borderColor: 'lightgray' }]}
              //{ alignSelf: 'center', marginTop: 20, marginHorizontal: 20, width: "100%", borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40, paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }
              />
              <Text style={{ color: themeColor.color6 }}>{error['at'] == 'email' ? error['message'] : ''}</Text>
            </View>
          </ScrollView>
          <View style={{ flexDirection: 'row' }}>
            {
              [
                {
                  id: 1, title: 'ยกเลิก', onPress: () => {
                    setError({ at: '', message: '' });
                    setDialog({ visible: false });
                  },
                  viewStyle: { backgroundColor: 'white' }, textStyle: { color: themeColor.color1 }
                },
                {
                  id: 2, title: 'ส่ง', onPress: async () => {
                    if (!validateForm()) {
                      return;
                    }
                    mailPharmaNote(dialog.pharmaNoteId, dialog.email);
                  },
                  viewStyle: { backgroundColor: themeColor.color1 }, textStyle: { color: 'white' }
                }
              ].map(item => (
                <TouchableOpacity key={item.id.toString()} onPress={() => item.onPress()} style={{ marginHorizontal: 10 }}>
                  <View style={[{
                    width: 120, height: 35, borderRadius: 10, borderWidth: 1, borderColor: themeColor.color1,
                    justifyContent: 'center', alignItems: 'center'
                  }, item.viewStyle]}>
                    <Text style={[item.textStyle]}>{item.title}</Text>
                  </View>
                </TouchableOpacity>
              ))
            }
          </View>
          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => {
              setError({ at: '', message: '' });
              setDialog({ visible: false });
            }}>
              <CustomIcon name="close-circle-outline" size={25} color="gray" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

