import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator, Alert, TouchableWithoutFeedback, Keyboard } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import { CustomIcon } from '../component/custom';
import { uploadPhoto } from '../component/custom';
import EventEmitter from '../sharewebapp/EventEmitter';
// import * as Location from 'expo-location';

export function EditShopPart({ navigation, route }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState();
  const [staffData, setStaffData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({ visible: false, data: null });
  const [error, setError] = useState({ at: '', message: '' });
  const [adminType, setAdminType] = useState();
  const [viewMode, setViewMode] = useState(false)

  const FIELD_LIST = [
    {
      id: 1, type: 'text', title: 'รหัสสาขา', titleStyle: { marginTop: 10 }, fieldName: 'shopIdBranch', value: data?.shopIdBranch, textInputStyle: { height: 40, marginTop: 5 }, textInputProp: { editable: !viewMode }
    },
    {
      id: 2, type: 'text', title: 'ชื่อร้าน', titleStyle: { marginTop: 10 }, fieldName: 'shopThName', value: data?.shopThName, textInputStyle: { height: 40, marginTop: 5 }, textInputProp: { editable: !viewMode }
    },
    {
      id: 3, type: 'text', title: 'รายละเอียดร้าน', titleStyle: { marginTop: 10 }, fieldName: 'shopDesc', value: data?.shopDesc, textInputProp: { multiline: true, editable: !viewMode }, textInputStyle: { height: 120, marginTop: 5, padding: 5, textAlignVertical: "top" }
    },
    {
      id: 4, type: 'text', title: 'เบอร์โทรร้าน', titleStyle: { marginTop: 10 }, fieldName: 'shopTel', value: data?.shopTel, textInputProp: { keyboardType: 'number-pad', editable: !viewMode }, textInputStyle: { height: 40, marginTop: 5 }
    },
    {
      id: 6, type: 'shoptype', title: 'ประเภทร้าน', titleStyle: { marginTop: 10 }, fieldName: 'shopType', value: data?.shopType, textInputStyle: { height: 40, marginTop: 5 }
    },
    {
      id: 7, type: 'capability', title: 'บริการจัดส่ง', titleStyle: { marginTop: 10 }, fieldName: 'capability', value: data?.capability, textInputStyle: { height: 40, marginTop: 5 }
    },
    {
      id: 8, type: 'image', title: 'รูปหน้าปกร้าน', titleStyle: { marginTop: 10 }, fieldName: 'shopPhoto1', value: data?.shopPhoto1, textInputProp: { multiline: true }, textInputStyle: { height: 120, marginTop: 5, padding: 5, textAlignVertical: "top" }
    },
    {
      id: 9, type: 'location', title: 'ตำแหน่งที่ตั้ง', titleStyle: { marginTop: 10 }, fieldName: ['shopLat', 'shopLong'], value: [data?.shopLat, data?.shopLong], textInputStyle: { width: '100%', height: 40, marginTop: 5 }, textInputProp: { editable: !viewMode }
    },
    {
      id: 10, type: 'text', title: 'ระยะทางให้บริการ (กม.)', titleStyle: { marginTop: 10 }, fieldName: 'serviceDistance', value: data?.serviceDistance?.toString(), textInputProp: { keyboardType: 'number-pad', editable: !viewMode }, textInputStyle: { height: 40, marginTop: 5 }
    },
    {
      id: 11, type: 'staff', title: 'เภสัชกรประจำสาขา', titleStyle: { marginTop: 10 }, fieldName: 'shopStaff', value: staffData,
    },
  ]

  useEffect(() => {
    const loadUserRole = async () => {
      const res = await globalState.client.service('users').get(globalState?.user?.id, { query: { $select: ['roles'] } });
      setAdminType(res?.roles);
      setViewMode(['cs', 'rapd'].includes(res?.roles));
    }
    if (globalState.user) {
      loadUserRole();
    }
  }, [])

  useEffect(() => {
    if (route?.params?.id > 0) {
      loadData();
      loadStaffData();
    }
  }, [route?.params?.id])

  const loadData = async () => {
    let query = {
      id: route?.params?.id,
      $select: [
        'id', 'shopIdBranch', 'shopThName', 'shopDesc', 'shopTel', 'shopOpenCloseTime',
        'shopPhoto1', 'shopLat', 'shopLong', 'shopType', 'capability', 'serviceDistance'
      ]
    };
    const res = await globalState.client.service('shops').find({ query });
    console.log('\nshop : ', route?.params?.id, res);
    if (res.total == 0) {
      dispatch({ type: 'showToast', payload: { text: 'ไม่มีข้อมูลร้านนี้' }, });
      EventEmitter.notify('ManageShop:refresh');
      navigation.goBack();
    }
    setData(res.data[0]);
  }

  const loadStaffData = async () => {
    const resShopUsers = await globalState.client.service('shopusers').find({
      query: { shopId: route?.params?.id, $limit: 100 }
    });
    setStaffData(resShopUsers.data);
  }

  const onDelete = (id) => {
    const delAction = async () => {
      await globalState.client.service('shops').remove(id);
      EventEmitter.notify('ManageShop:refresh');
      navigation.goBack();
    }
    if (staffData.length > 0) {
      setError({ at: 'shopStaff', message: 'ร้านต้องไม่มีพนักงานในร้านจึงจะลบร้านได้' })
      return;
    }
    if (Platform.OS == 'web' && window.confirm('คุณต้องการลบร้านค้านี้')) {
      delAction();
    } else {
      Alert.alert('ลบร้านค้า', 'คุณต้องการลบร้านค้านี้ ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => delAction() },
        ]
      );
    }

  }

  const onRemoveStaff = async (userId) => {
    const removeAction = async () => {
      await globalState.client.service('shopusers').remove(null, { query: { shopId: route?.params?.id, userId } });
      loadStaffData();
    }

    if (Platform.OS == 'web' && window.confirm('คุณต้องการลบเภสัชกรคนนี้')) {
      removeAction();
    } else {
      Alert.alert('ลบเภสัชกร', 'คุณต้องการลบเภสัชกรคนนี้ ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => removeAction() },
        ]
      );
    }

  }

  const validateForm = () => {
    const blankField = ['shopThName', 'shopTel', 'shopType'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    return true;
  }


  const onSave = async (id) => {
    setError({ at: '', message: '' });
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    let res;
    if (id == 0) {
      res = await globalState.client.service('shops').create({
        shopIdBranch: data?.shopIdBranch,
        shopThName: data?.shopThName,
        shopDesc: data?.shopDesc,
        shopTel: data?.shopTel,
        shopOpenCloseTime: data?.shopOpenCloseTime,
        shopLat: data?.shopLat,
        shopLong: data?.shopLong,
        shopPhoto1: data?.shopPhoto1,
        shopType: data?.shopType,
        capability: data?.capability,
        serviceDistance: data?.serviceDistance,
        shopStatus: 'A',
      });
      try {
        await globalState.client.service('shopusers').remove(null, { query: { shopId: id } });
      } catch (error) {
        console.log(error)
      }
      staffData.forEach(async (staff) => {
        await globalState.client.service('shopusers').create({ shopId: res.id, userId: staff?.userId });
      })

    } else {
      res = await globalState.client.service('shops').patch(id, {
        shopIdBranch: data?.shopIdBranch,
        shopThName: data?.shopThName,
        shopDesc: data?.shopDesc,
        shopTel: data?.shopTel,
        shopOpenCloseTime: data?.shopOpenCloseTime,
        shopLat: data?.shopLat,
        shopLong: data?.shopLong,
        shopPhoto1: data?.shopPhoto1,
        shopType: data?.shopType,
        capability: data?.capability,
        serviceDistance: data?.serviceDistance,
      });
      try {
        await globalState.client.service('shopusers').remove(null, { query: { shopId: id } });
      } catch (error) {
        console.log(error)
      }
      staffData.forEach(async (staff) => {
        await globalState.client.service('shopusers').create({ shopId: id, userId: staff?.userId });
      })
    }
    setLoading(false);
    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลเรียบร้อย' }, });
    EventEmitter.notify('ManageShop:refresh');
    navigation.goBack(2);
  }

  const onBack = async (item) => {
    const tmpStaff = { shopId: route?.params?.id, userId: item.id, user: item };
    setStaffData(prev => [...prev, tmpStaff]);
  }

  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5, }}>
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <ScrollView contentContainerStyle={[{ paddingBottom: 260, paddingHorizontal: 20 }, Platform.OS == 'web' ? { height: '70vh' } : null]}>
        {
          FIELD_LIST.map(item =>
            <CustomItem
              key={item.id.toString()} item={item} setData={setData} setLoading={setLoading}
              setDialog={setDialog} staffData={staffData} setStaffData={setStaffData}
              error={error} onRemoveStaff={onRemoveStaff} viewMode={viewMode} adminType={adminType}
            />
          )}

        <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 10 }}>
          {((route?.params?.id != 0) && !viewMode) &&
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onDelete(route?.params?.id)} >
              <View style={{
                height: 40, borderRadius: 10,
                justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color6
              }}>
                <Text style={{ color: 'white' }}>ลบ</Text>
              </View>
            </TouchableOpacity>
          }
          {['admin', 'cs'].includes(adminType) &&
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onSave(route?.params?.id)} >
              <View style={{
                height: 40, borderRadius: 10,
                justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color1
              }}>
                <Text style={{ color: 'white' }}>บันทึก</Text>
              </View>
            </TouchableOpacity>
          }
        </View>

        {loading &&
          <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size='large' />
          </View>
        }
        <StaffDialog dialog={dialog} setDialog={setDialog} onBack={onBack} />
      </ScrollView>
      {/* </TouchableWithoutFeedback> */}
    </SafeAreaView>
  )
}

function CustomItem({ item, setData, setLoading, setDialog, staffData, setStaffData, error, onRemoveStaff, viewMode, adminType }) {
  if (item.type == 'text') {
    return <CustomText item={item} setData={setData} error={error} />
  } else if (item.type == 'shoptype') {
    return <ShopType item={item} setData={setData} error={error} viewMode={viewMode} />
  } else if (item.type == 'capability') {
    return <ShopCapability item={item} setData={setData} error={error} viewMode={viewMode} />
  } else if (item.type == 'location') {
    return <ShopLocation item={item} setData={setData} setLoading={setLoading} error={error} viewMode={viewMode} />
  } else if (item.type == 'staff') {
    return <ShopStaff item={item} setStaffData={setStaffData} setDialog={setDialog} staffData={staffData} error={error} onRemoveStaff={onRemoveStaff} adminType={adminType} />
  } else if (item.type == 'image') {
    return <ShopImage item={item} setData={setData} error={error} viewMode={viewMode} />
  }
}

function ShopType({ item, setData, error, viewMode }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <View style={[{
        paddingRight: 20, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 10
      }, error?.at == 'shopType' && { borderColor: 'red', borderWidth: 1 }]}>
        {[
          { id: 1, title: 'ทั่วไป', value: 'general1' },
          { id: 2, title: 'พิเศษ', value: 'special1' },
          { id: 3, title: '24Hour', value: '24hour' },
          { id: 4, title: 'Minegene', value: 'minegene' },
        ].map(item2 => (
          <TouchableOpacity key={item2.id.toString()} style={{ marginHorizontal: 10 }} onPress={() => !viewMode ? setData(prev => ({ ...prev, [item.fieldName]: item2.value })) : null}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <CustomIcon name={item2.value == item?.value ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
              <Text style={{ marginLeft: 5 }}>{item2.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
      {error?.at == 'shopType' && <Text style={{ textAlign: 'center', color: 'red' }}>{error?.message}</Text>}
    </View>
  )
}

function ShopCapability({ item, setData, error, viewMode }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <View style={{
        paddingRight: 20, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 10
      }}>
        {[
          { id: 1, title: 'มีบริการจัดส่ง', value: 'delivery' },
          { id: 2, title: 'ไม่มีบริการจัดส่ง', value: null },
        ].map(item2 => (
          <TouchableOpacity key={item2.id.toString()} style={{ marginHorizontal: 10 }} onPress={() => !viewMode ? setData(prev => ({ ...prev, [item.fieldName]: item2.value })) : null}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <CustomIcon name={item2.value == item?.value ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
              <Text style={{ marginLeft: 5 }}>{item2.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}

function ShopImage({ item, setData, error, viewMode }) {
  const fileRef1 = useRef();
  const uploadPhoto1 = async (e) => {
    uploadPhoto2(e.target.files)
  }

  const uploadPhoto2 = async (targetFiles) => {
    let images;
    try {
      images = await uploadPhoto('shop', false, 'photolib', targetFiles);
    } catch { }
    console.log('images!!', images);
    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }
    for (const item of images) {
      setData(prev => ({ ...prev, shopPhoto1: `${server_var.server_api}/images/shop/${item.filename}` }))
    }
  }

  if (item?.value) {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <View style={{ marginVertical: 10 }}>
          <Image style={{ width: 150, height: 150 }}
            source={{ uri: item?.value }}
            resizeMode="contain"
          />
          {!viewMode &&
            <TouchableOpacity
              style={{ position: "absolute", top: 0, left: 0 }}
              onPress={() => {
                setData(prev => ({ ...prev, shopPhoto1: null }))
              }}
            >
              <View style={{ marginTop: 0, marginLeft: 0 }}>
                <CustomIcon name="close-circle" size={20} color={"red"} />
              </View>
            </TouchableOpacity>
          }
        </View>
      </View>
    )
  } else {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <TouchableOpacity
          style={{
            borderColor: "gray", borderWidth: 1, borderRadius: 6, width: 100, height: 100,
            margin: 10, justifyContent: "center", alignItems: "center", padding: 5
          }}
          onPress={() => {
            if (!viewMode) {
              if (Platform.OS == 'web') {
                fileRef1.current.click();
              } else {
                uploadPhoto2('')
              }
            }
          }}
        >
          <CustomIcon name="plus-circle" size={20} color="gray" />
          <Text style={{ color: "gray", marginTop: 5 }}>ใส่รูปภาพ</Text>
        </TouchableOpacity>
        {
          Platform.OS == 'web' && (
            <div style={{ display: 'flex' }}>
              <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadPhoto1} multiple accept=".mp4, .jpg, .png" />
            </div>
          )
        }
      </View>
    )
  }
}

function CustomText({ item, setData, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <TextInput
        {...item.textInputProp}
        value={item.value}
        onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
        style={[item.textInputStyle, { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' }, error?.at == item.fieldName && { borderColor: 'red' }]}
      />
      {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
    </View>
  )
}

function ShopLocation({ item, setData, setLoading, error, viewMode }) {
  // const getCurrentLocation = async () => {
  //   setLoading(true);
  //   const { status } = await Location.requestForegroundPermissionsAsync();
  //   if (status !== 'granted') {
  //     alert('คุณไม่ได้อนุญาตให้แอปนี้เข้าถึงจีพีเอส!');
  //     return;
  //   }
  //   const location = await Location.getCurrentPositionAsync({});
  //   setData(prev => (
  //     { ...prev, shopLat: location.coords.latitude.toString(), shopLong: location.coords.longitude.toString() }
  //   ));
  //   setLoading(false);
  // }

  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* <View style={{ flex: 0.7, flexDirection: 'column', justifyContent: "center", alignItems: "center" }}> */}
        <View style={{ flex: 1, flexDirection: 'column', justifyContent: "center", alignItems: "center" }}>
          <TextInput
            {...item.textInputProp}
            value={item?.value[0]}
            onChangeText={text => setData(prev => ({ ...prev, [item.fieldName[0]]: text }))}
            style={[item.textInputStyle, { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' }]}
          />
          <TextInput
            {...item.textInputProp}
            value={item?.value[1]}
            onChangeText={text => setData(prev => ({ ...prev, [item.fieldName[1]]: text }))}
            style={[item.textInputStyle, { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' }]}
          />
        </View>
        {/* <View style={{ flex: 0.3, flexDirection: 'column', justifyContent: "center", alignItems: 'center' }}>
          <TouchableOpacity
            style={{
              flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              marginLeft: 10, marginRight: 10, height: 80, width: 80
            }} onPress={() => !viewMode ? getCurrentLocation() : null}
          >
            <View
              style={{ height: 50, width: 50, borderRadius: 25, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderWidth: 1, borderColor: 'lightgray' }}
            >
              <CustomIcon name='map-marker-radius-outline' size={24} color="gray" />
            </View>
            <Text style={{ color: 'gray', textAlign: 'center', fontSize: 12, marginTop: 5 }}>ตำแหน่งปัจจุบัน</Text>
          </TouchableOpacity>
        </View> */}
      </View>
    </View>
  )
}

function ShopStaff({ item, setStaffData, setDialog, staffData, error, onRemoveStaff, adminType }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <View style={{ flexDirection: 'column', marginVertical: 10, paddingHorizontal: 10 }}>
        {
          staffData && staffData.length > 0 && staffData.map((item2, i) => (
            <View key={i.toString()} style={{ flexDirection: 'row', marginVertical: 10 }} >
              <View style={{ flex: 0.3 }}>
                <Image
                  style={{ width: 50, height: 50, borderRadius: 25 }} source={item2?.user?.avatar ? { uri: item2?.user?.avatar } : { uri: `${server_var.server_api}/images/unknown-user-pic.jpeg` }}
                />
                {['admin', 'cs'].includes(adminType) &&
                  <TouchableOpacity
                    style={{ position: "absolute", top: -5, left: -5 }}
                    onPress={() => {
                      onRemoveStaff(item2?.userId);
                    }}
                  >
                    <CustomIcon name="close-circle" size={20} color={"red"} />
                  </TouchableOpacity>
                }
              </View>
              <View style={{ flex: 0.7 }}>
                <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item2?.user?.displayName}</Text>
                <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item2?.user?.staffId}</Text>
              </View>
            </View>
          ))
        }
        <TouchableOpacity
          style={{
            flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            marginVertical: 20, height: 50, width: 50
          }}
          onPress={() => ['admin', 'cs'].includes(adminType) ? setDialog(prev => ({ ...prev, visible: true })) : null}
        >
          <View
            style={{
              height: 50, width: 50, borderRadius: 25, flexDirection: 'column',
              justifyContent: 'center', alignItems: 'center', borderWidth: 1, borderColor: 'lightgray'
            }}
          >
            <CustomIcon name='plus' size={24} color="gray" />
          </View>
          <Text style={{ color: 'gray', textAlign: 'center', fontSize: 12, marginTop: 10 }}>เพิ่ม</Text>
        </TouchableOpacity>
      </View>
      {error?.at == 'shopStaff' && <Text style={{ color: 'red', textAlign: 'center' }}>{error?.message}</Text>}
    </View>
  )
}

function StaffDialog({ dialog, setDialog, onBack }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;

  useEffect(() => {
    loadDataList(0);
  }, [debouncedSearchTerm])

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query = { staffFlag: 1, $skip: pSkip ?? skip, $sort: { id: 1 }, $limit: 10 };
      if (searchArray.length > 0) {
        const tmpSearch = searchArray.join('%');
        query = {
          ...query,
          $or: [
            { displayName: { $like: `%${tmpSearch}%` } },
            { staffPhoneNumber: { $like: `%${tmpSearch}%` } },
            { staffId: { $like: `%${tmpSearch}%` } },
          ]
        };
      }
      let res;
      try {
        res = await globalState.client.service('users').find({ query });
      } catch { }
      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }

  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <View style={{
          width: dialogWidth, height: dialogWidth * 1.5, paddingTop: 10, paddingBottom: 30,
          alignItems: 'center', borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
        }}>
          <View style={{ width: dialogWidth, height: (dialogWidth * 1.5) - 30 }}>
            <TextInput
              value={searchText}
              onChangeText={text => setSearchText(text)}
              placeholder={'ชื่อหรือรหัสพนักงาน'}
              style={{
                alignSelf: 'center', borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40,
                paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white', marginHorizontal: 20,
                width: (dialogWidth - 30)
              }}
            />
            <FlatList
              keyExtractor={(item) => item.id.toString()}
              data={dataList}
              onEndReached={(xxx) => loadDataList()}
              onEndReachedThreshold={0.5}
              onRefresh={() => loadDataList(0)}
              refreshing={(loading && skip == 0)}
              style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: 100 } : null]}
              renderItem={({ item }) => (<StaffItem item={item} setDialog={setDialog} onBack={onBack} />)}
            />
          </View>

          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: false }))}>
              <CustomIcon name="close-circle-outline" size={25} color="gray" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

function StaffItem({ item, setDialog, onBack }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5, paddingHorizontal: 20, }}
      onPress={() => {
        onBack(item);
        setDialog(prev => ({ ...prev, visible: false }));
      }}>
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1, flex: 1 }}>
        <View style={{ flex: 0.3, paddingBottom: 10, paddingTop: 5 }}>
          <Image
            style={{ width: 40, height: 40, borderRadius: 20 }}
            source={item?.avatar ? { uri: item?.avatar } : { uri: `${server_var.server_api}/images/unknown-user-pic.jpeg` }}
          />
        </View>
        <View style={{ flex: 0.7, paddingBottom: 10, paddingTop: 5 }}>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item?.displayName}</Text>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item?.staffId}</Text>
        </View>
      </View>
    </TouchableOpacity >
  )
}
