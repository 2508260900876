import React from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import { useState, useEffect, useCallback, useContext } from 'react';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';
import moment from 'moment';

export function LalamoveStatusPart({ navigation, route }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  const { lalaId, ordermainId } = route?.params

  useEffect(() => {
    loadDataList(0);
  }, [lalaId])

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      const query = {
        type: 'lalamoveOrder',
        $skip: pSkip ?? skip,
        $sort: { id: -1 },
        $or: [
          { altId1: { $in: lalaId.split(',') } },
          { altId2: ordermainId }
        ]
      }
      console.log('query' + JSON.stringify(query));
      const res = await globalState.client.service('apilogs').find({ query });
      console.log('res')
      if ((pSkip ?? skip) === 0) {
        setDataList(res.data);
      } else {
        setDataList(prev => [...prev, ...res.data]);
      }
      setSkip((res.skip + res.limit));
      setLoading(false);
      console.log(res.data);
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
      <Text style={{ textAlign: 'center', marginTop: 10 }}>Lalamove Ref Id: {lalaId} : {ordermainId}</Text>
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onEndReached={(xxx) => loadDataList()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}
        style={{ marginTop: 10 }}
        renderItem={({ item }) => (
          <LalamoveItem item={item} />
        )}
      />
    </SafeAreaView>
  )
}

function LalamoveItem({ item }) {
  const [expandFlag, setExpandFlag] = useState(false);
  const tmpTextStyle = item?.altId2 ? { color: 'gray' } : { color: 'black' }
  const LALAMOVE_STATUS_LIST = [
    { lalaStatus: 'COMPLETED', text: 'จัดส่งสินค้าสำเร็จ' },
    { lalaStatus: 'PICKED_UP', text: 'พนักงานจัดส่งเข้ารับสินค้าแล้ว' },
    { lalaStatus: 'ON_GOING', text: 'พนักงานจัดส่งกำลังเข้ารับสินค้า' },
    { lalaStatus: 'ASSIGNING_DRIVER', text: 'กำลังค้นหาพนักงานจัดส่ง' }
  ]
  const translateLalamoveStatus = (LALAMOVE_STATUS_LIST.find(item2 => item2.lalaStatus == (item.data?.data?.order?.status))?.text) ?? (item.data?.data?.order?.status)


  if (item?.data?.eventType == 'ORDER_STATUS_CHANGED') {
    return (
      <View style={{ flexDirection: 'row', marginTop: 10, marginHorizontal: 20, borderBottomWidth: 1, borderColor: 'lightgray', paddingBottom: 10 }}>
        <View style={{ flex: 0.9 }}>
          <Text style={tmpTextStyle}>หมายเลขรับงานขนส่ง: {item?.altId1}</Text>
          {/* <Text style={tmpTextStyle}>Event Type: {item.data?.eventType}</Text> */}
          <Text style={tmpTextStyle}>สถานะ: <Text style={{ color: themeColor.color1 }}>{translateLalamoveStatus}</Text></Text>
          <Text style={tmpTextStyle}>เวลาที่แจ้ง: <Text style={{ color: 'gray', fontSize: 12 }}>{item.data?.data?.updatedAt}</Text></Text>
          {expandFlag && <Text style={{ color: 'gray', fontSize: 12 }}>{JSON.stringify(item?.data?.data, '', 2)}</Text>}

        </View>
        <View style={{ flex: 0.1 }}>
          <TouchableOpacity onPress={() => setExpandFlag(prev => !prev)}>
            <CustomIcon name={expandFlag ? "chevron-up-circle" : "chevron-down-circle"} size={20} color={themeColor.color1} />
          </TouchableOpacity>
        </View>
      </View>
    )
  }

}

