import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import EventEmitter from '../sharewebapp/EventEmitter';

export function ManageRolePart({ navigation }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);

  useEffect(() => {
    EventEmitter.addListener('ManageRole:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('ManageRole:refresh', handleRefresh);
    }
  }, [searchText])

  const handleRefresh = () => {
    setSearchText('');
    loadDataList(0);
  }

  useEffect(() => {
    loadDataList(0);
  }, [debouncedSearchTerm])

  const onEdit = (userId) => {
    navigation.navigate('EditRole', { userId })
  }

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query = { $skip: pSkip ?? skip, $sort: { id: 1 }, $limit: 20, staffFlag: 1 };
      if (searchArray.length > 0) {
        const tmpSearch = searchArray.join('%');
        query = {
          ...query,
          $or: [
            { displayName: { $like: `%${tmpSearch}%` } },
            { staffPhoneNumber: { $like: `%${tmpSearch}%` } },
            { staffId: { $like: `%${tmpSearch}%` } },
          ],
        };
      } else {
        query = {
          ...query,
          roles: { $ne: 'pharmacist' }
        };
      }
      let res;
      try {
        res = await globalState.client.service('users').find({ query });
      } catch { }
      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', marginHorizontal: 20 }}>
        <View style={{ flex: 1, paddingBottom: 10, paddingTop: 5, marginTop: 5 }}>
          <TextInput
            value={searchText}
            onChangeText={text => setSearchText(text)}
            placeholder={'ค้นหาจากชื่อหรือรหัสพนักงาน'}
            style={{ alignSelf: 'center', width: "100%", borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40, paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }}
          />
        </View>
      </View>

      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onEndReached={(xxx) => loadDataList()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}
        style={{ marginTop: 10 }}
        renderItem={({ item, index }) => (
          <UserItem item={item} index={index} onEdit={onEdit} />
        )}
      />
    </SafeAreaView>
  )
}

function UserItem({ item, index, onEdit }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5, marginHorizontal: 20, }}
      onPress={() => onEdit(item.id)}
    >
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1, flex: 1 }}>
        <View style={{ flex: 0.2, paddingBottom: 10, paddingTop: 5 }}>
          <Image
            style={{ width: 40, height: 40, borderRadius: 20 }}
            source={item?.avatar ? { uri: item?.avatar } : { uri: `${server_var.server_api}/images/unknown-user-pic.jpeg` }}
          />
        </View>
        <View style={{ flex: 0.5, paddingBottom: 10, paddingTop: 5 }}>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item?.displayName}</Text>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item?.staffId}</Text>
        </View>
        <View style={{ flex: 0.3, paddingBottom: 10, paddingTop: 10 }}>
          <View style={{ height: 40, width: '100%', borderRadius: 6, justifyContent: 'center', alignItems: 'center', backgroundColor: themeColor.color1 }}>
            <Text style={{ color: 'white' }}>
              {
                [
                  { id: 1, title: 'Admin', value: 'admin' },
                  { id: 2, title: 'Customer Service', value: 'cs' },
                  { id: 3, title: 'Marketing', value: 'marketing' },
                  { id: 4, title: 'RAPD', value: 'rapd' },
                  { id: 5, title: 'Pharmacist', value: 'pharmacist' },
                ].find(item2 => (item2.value == item?.roles))?.title
              }
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity >
  )
}
