import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, StyleSheet, Modal, Dimensions, ScrollView, SafeAreaView, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import ParsedText from 'react-native-parsed-text';

export function FeedViewPart({ route }) {
  const { globalState } = useContext(store);
  const [data, setData] = useState();
  const [imageSize, setImageSize] = useState();

  const loadData = async (id) => {
    const res = await globalState.client.service('notilogs').get(id);
    setData(res);
  }

  useEffect(() => {
    loadData(route?.params?.id);
  }, [route?.params?.id])

  useEffect(() => {
    if (data?.data?.image) {
      Image.getSize(data?.data?.image, (width, height) => {
        setImageSize({ width, height });
      })
    }
  }, [data?.data?.image])

  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;

  return (
    <SafeAreaView>
      <ScrollView contentContainerStyle={[
        Platform.OS == 'web' ? { height: '95vh' } : null
      ]}>
        <View style={{ flex: 1, alignItems: 'center', marginTop: 10, marginBottom: 80 }}>
          {data?.data?.title && (
            <Text style={{ marginBottom: 10, fontSize: 16, color: themeColor.color1, fontWeight: 'bold' }}>{data?.data?.title}</Text>
          )}
          {imageSize?.width && data?.data?.image && (
            <Image
              source={{ uri: data?.data?.image }}
              style={{
                width: dialogWidth,
                height: imageSize.height / imageSize.width * (dialogWidth),
                marginBottom: 10,
              }}
              resizeMode="contain"
            />
          )}
          <View style={{ paddingHorizontal: 10 }}>
            <ParsedText
              parse={[{ type: 'url', style: styles.url, onPress: handleUrlPress }]}
              style={{ textAlign: 'center' }}
            >
              {data?.data?.text}
            </ParsedText>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

function handleUrlPress(url, matchIndex) {
  Linking.openURL(url)
}

const styles = StyleSheet.create({
  url: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
});
