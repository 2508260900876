import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator, Alert, TouchableWithoutFeedback, Keyboard } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon, uploadPhoto } from '../component/custom';
import moment from 'moment';
import EventEmitter from '../sharewebapp/EventEmitter';
import { Linking } from 'react-native';

export function EditRolePart({ route, navigation }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ at: '', message: '' });
  const [chgPwdMode, setChgPwdMode] = useState(false)
  const { userId, staffFlag } = route.params;

  const FIELD_LIST = [
    {
      id: 1, type: 'avatar', title: 'รูปโปรไฟล์', fieldName: 'avatar', value: data?.avatar
    },
    {
      id: 2, type: 'text', title: 'เบอร์โทรศัพท์', fieldName: 'staffPhoneNumber', value: data?.staffPhoneNumber, textInputProp: { keyboardType: 'number-pad' }
    },
    {
      id: 3, type: 'text', title: 'ชื่อ', fieldName: 'firstName', value: data?.firstName
    },
    {
      id: 4, type: 'text', title: 'นามสกุล', fieldName: 'lastName', value: data?.lastName
    },
    {
      id: 5, type: 'gender', title: 'เพศ', fieldName: 'gender', value: data?.gender
    },
    {
      id: 6, type: 'date', title: 'วันเดือนปีเกิด เป็น คศ. (25/06/1990)', fieldName: 'birthDate', value: data?.birthDate, textInputProp: { keyboardType: 'number-pad' }, visible: true
    },
    {
      id: 7, type: 'roles', title: 'การจัดการสิทธิ์', fieldName: 'roles', value: data?.roles
    },
    {
      id: 8, type: 'link', title: 'ดูรายละเอียดสิทธิ์แต่ละประเภทที่นี่ >>'
    },

  ]

  useEffect(() => {
    if (userId > 0) {
      loadData();
    } else {
      setChgPwdMode(true);
    }
  }, [userId])

  const loadData = async () => {
    const res = await globalState.client.service('users').get(userId);
    if (!res) {
      dispatch({ type: 'showToast', payload: { text: 'ไม่มีข้อมูลผู้ใช้คนนี้' }, });
      navigation.goBack();
    }
    setData(res);
  }

  const onDelete = (id) => {
    const delAction = async () => {
      await globalState.client.service('users').remove(id);
      navigation.goBack();
    }
    if (Platform.OS == 'web' && window.confirm('คุณต้องการลบผู้ใช้คนนี้อย่างถาวร ?')) {
      delAction();
    } else {
      Alert.alert('ลบบัญชีผู้ใช้', 'คุณต้องการลบผู้ใช้คนนี้อย่างถาวร ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => delAction() },
        ]
      );
    }

  }

  const validateForm = () => {
    const blankField = ['mobileNumber', 'firstName', 'lastName'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    // if (!moment(data.birthDate, 'DD/MM/YYYY').isValid()) {
    //   setError({ at: 'birthDate', message: 'วันที่ไม่ถูกต้อง' });
    //   return false
    // }
    return true;
  }

  const onSave = async (id) => {
    setError({ at: '', message: '' });
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    let res;
    let tmpDate;
    if (data?.birthDate) {
      if (data?.birthDate.indexOf("/") == -1) {
        tmpDate = moment(data?.birthDate).format('YYYY/MM/DD');
      } else {
        tmpDate = moment(data?.birthDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
      }
    }

    let tmpEdit = {
      avatar: data?.avatar,
      staffPhoneNumber: data?.staffPhoneNumber,
      firstName: data?.firstName,
      lastName: data?.lastName,
      displayName: `${data?.firstName} ${data?.lastName}`,
      gender: data?.gender,
      birthDate: tmpDate,
      roles: data?.roles,
    }
    res = await globalState.client.service('users').patch(id, tmpEdit);

    setLoading(false);
    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลเรียบร้อย' }, });
    EventEmitter.notify('ManageRole:refresh');
    navigation.goBack();
  }

  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5, }}>
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <ScrollView contentContainerStyle={[{ paddingBottom: 260, paddingHorizontal: 20 }, Platform.OS == 'web' ? { height: '80vh' } : null]}>
        {
          FIELD_LIST.map(item =>
            <CustomItem key={item.id.toString()} item={item} setData={setData} error={error} navigation={navigation} />
          )}
        <View style={{ marginTop: 20 }} />
        {[
          { id: 1, title: 'บันทึก', visible: true, onPress: () => onSave(userId), style: { backgroundColor: themeColor.color1 } },
          // { id: 2, title: 'ลบ', onPress: () => (userId == 0) ? null : onDelete(userId), style: { backgroundColor: themeColor.color6 } }
        ].map(item => {
          if (item.visible) {
            return (
              <TouchableOpacity key={item.id.toString()} style={{ marginTop: 10, alignSelf: 'center', width: '90%' }} onPress={() => item.onPress()}>
                <View style={[{
                  height: 40,
                  borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginTop: 10
                }, item.style]}>
                  <Text style={{ color: 'white' }}>{item?.title}</Text>
                </View>
              </TouchableOpacity>
            )
          } else {
            return <View key={item.id.toString()} />
          }

        })}
        {loading &&
          <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size='large' />
          </View>
        }
      </ScrollView>
      {/* </TouchableWithoutFeedback> */}
    </SafeAreaView >
  )
}

function CustomItem({ item, setData, error, navigation }) {
  if (item.type == 'avatar') {
    return <UserAvatar item={item} setData={setData} />
  } else if (item.type == 'text') {
    return <CustomText item={item} setData={setData} error={error} />
  } else if (item.type == 'gender') {
    return <UserGender item={item} setData={setData} error={error} />
  } else if (item.type == 'date') {
    return <UserDate item={item} setData={setData} error={error} />
  } else if (item.type == 'roles') {
    return <UserRole item={item} setData={setData} error={error} />
  } else if (item.type == 'link') {
    return <CustomLink item={item} setData={setData} error={error} navigation={navigation} />
  }
}

function UserAvatar({ item, setData }) {
  const fileRef1 = useRef();
  const uploadPhoto1 = async (e) => {
    uploadPhoto2(e.target.files)
  }

  const uploadPhoto2 = async (targetFiles) => {
    let images;
    try {
      images = await uploadPhoto('profile', false, 'photolib', targetFiles);
    } catch { }
    console.log('images!!', images);
    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }
    for (const item of images) {
      setData(prev => ({ ...prev, avatar: `${server_var.server_api}/images/profile/${item.filename}` }))
    }
  }

  return (
    <View style={{ alignItems: 'center', paddingHorizontal: 10 }}>
      <TouchableOpacity onPress={() => {
        if (Platform.OS == 'web') {
          fileRef1.current.click();
        } else {
          uploadPhoto2('')
        }
      }}>
        <View>
          <Image
            source={{ uri: item?.value }}
            style={{ width: 100, height: 100, borderRadius: 50, marginVertical: 15 }}
          />
          <View style={{ position: 'absolute', right: 0, bottom: 10 }}>
            <CustomIcon name="plus-circle" size={25} color={themeColor.color1} />
          </View>
        </View>
      </TouchableOpacity>
      {
        Platform.OS == 'web' && (
          <div style={{ display: 'flex' }}>
            <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadPhoto1} multiple accept=".mp4, .jpg, .png" />
          </div>
        )
      }
    </View>
  )
}

function CustomText({ item, setData, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14, marginTop: 10 }]}>{item?.title}</Text>
      <TextInput
        {...item.textInputProp}
        value={item.value}
        onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
        style={[
          { height: 40, marginTop: 5 },
          { borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white', borderWidth: 1, },
          (error?.at == item.fieldName) ? { borderColor: 'red' } : { borderColor: 'lightgray' },
        ]}
      />
      {error?.at == item.fieldName && <Text style={{ color: 'red', textAlign: 'center' }}>{error.message}</Text>}
    </View>
  )
}

function UserGender({ item, setData, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14, marginTop: 10 }]}>{item?.title}</Text>
      <View style={[
        { paddingRight: 20, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 10 },
        error?.at == item.fieldName && { borderColor: 'red', borderWidth: 1 }
      ]}>
        {[
          { id: 1, title: 'ชาย', value: 'M' },
          { id: 2, title: 'หญิง', value: 'F' },
        ].map(item2 => (
          <TouchableOpacity key={item2.id.toString()} style={{ marginHorizontal: 10 }} onPress={() => setData(prev => ({ ...prev, [item.fieldName]: item2.value }))}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <CustomIcon name={item2.value == item?.value ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
              <Text style={{ marginLeft: 5 }}>{item2?.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
      {error?.at == item.fieldName && <Text style={{ color: 'red', textAlign: 'center' }}>{error.message}</Text>}
    </View>
  )
}

function UserDate({ item, setData, error }) {
  const [tmpBirthDate1, setTmpBirthDate1] = useState();

  useEffect(() => {
    const a = moment(tmpBirthDate1, 'DD/MM/YYYY');
    if (a.isValid() && tmpBirthDate1.length == 10) {
      setData(prev => ({ ...prev, [item.fieldName]: a.format('YYYY-MM-DD') }))
    }
  }, [tmpBirthDate1])

  useEffect(() => {
    const tmpDate = item?.value ? moment(item?.value).format('DD/MM/YYYY') : '';
    setTmpBirthDate1(tmpDate);
  }, [item?.value])

  return (
    <View>
      <Text style={[{ fontSize: 14, marginTop: 10 }]}>{item?.title}</Text>
      <TextInput
        {...item.textInputProp}
        // defaultValue={tmpDate}
        value={tmpBirthDate1}
        onChangeText={text => setTmpBirthDate1(prev => getBirthDateOutput(prev, text))}
        style={[
          { height: 40, marginTop: 5 },
          { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' },
          error?.at == item.fieldName && { borderColor: 'red' }
        ]}
      />
      {error?.at == item.fieldName && <Text style={{ color: 'red', textAlign: 'center' }}>{error.message}</Text>}
    </View>
  )
}

function getBirthDateOutput(prev, text) {
  let tmpString = text.split('');
  if (tmpString.length > 10) return prev;

  if (prev?.length < text?.length) {
    if (text.length == 2) {
      tmpString.splice(2, 0, '/')
    } else if (text.length >= 2 && tmpString.length >= 3 && tmpString[2] != '/' && tmpString[1] != '/') {
      tmpString.splice(2, 0, '/')
    } else if (text.length == 5) {
      tmpString.splice(5, 0, '/')
    } else if (text.length >= 4 && tmpString.length >= 6 && tmpString[5] != '/' && tmpString[4] != '/' ) {
      tmpString.splice(5, 0, '/')
    }
  } else if (prev?.length > text?.length) {
    if (text.length == 3) {
      tmpString.splice(2, 1);
    }
    if (text.length == 6) {
      tmpString.splice(5, 1);
    }
  }
  return tmpString.join('');
}

function UserRole({ item, setData, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14, marginTop: 10 }]}>{item?.title}</Text>
      <View style={[
        { flexDirection: 'column', justifyContent: 'flex-start', marginVertical: 10 },
        error?.at == item.fieldName && { borderColor: 'red', borderWidth: 1 }
      ]}>
        {[
          { id: 1, title: 'Admin', value: 'admin' },
          { id: 2, title: 'Customer Service', value: 'cs' },
          { id: 3, title: 'Marketing', value: 'marketing' },
          { id: 4, title: 'RAPD', value: 'rapd' },
          { id: 5, title: 'Pharmacist', value: 'pharmacist' },
        ].map(item2 => (
          <TouchableOpacity key={item2.id.toString()} style={{ marginVertical: 5 }} onPress={() => setData(prev => ({ ...prev, [item.fieldName]: item2.value }))}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <CustomIcon name={item2.value == item?.value ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
              <Text style={{ marginLeft: 5 }}>{item2?.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
      {error?.at == item.fieldName && <Text style={{ color: 'red', textAlign: 'center' }}>{error.message}</Text>}
    </View>
  )
}

function CustomLink({ item, setData, error, navigation }) {
  return (
    <View>
      <TouchableOpacity onPress={() => {
        if (Platform.OS == 'web') {
          Linking.openURL(`${server_var.server_api}/authorized`);
        } else {
          navigation.navigate('ViewWeb', {
            title: 'ประเภทสิทธิ์',
            uri: `${server_var.server_api}/authorized`,
          });
        }

      }}>
        <Text style={[{ fontSize: 14, marginTop: 10, textDecorationLine: 'underline', color: themeColor.color1 }]}>{item?.title}</Text>
      </TouchableOpacity>
    </View>
  )
}
