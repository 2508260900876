import React, { useState, useCallback, useEffect, useContext, useRef } from 'react'
import { Button, TouchableOpacity, Text, View, SafeAreaView, FlatList, Dimensions, Image, StatusBar } from 'react-native'
import { themeColor, fontStyles } from '../config/servervar';
import { store } from '../sharewebapp/store'
import moment from 'moment';
import { Col, Row } from '@pankod/refine-antd';
import { ViewUserPart } from '../sharewebapp/viewuserpart';
import { useLocation } from 'react-router-dom';
import { ManageOrderPart } from '../sharewebapp/manageorderpart'
import { ViewOrderPart } from '../sharewebapp/vieworderpart';
import { PrintOrderPart } from '../sharewebapp/printorderpart';
import { PrintQrCodePart } from '../sharewebapp/printqrcodepart';
import { ViewProductPart } from '../sharewebapp/viewproductpart';
import { PharmaNotePart } from '../sharewebapp/pharmanotepart';
import { LalamoveStatusPart } from '../sharewebapp/lalamovestatuspart';
import { GrabStatusPart } from '../sharewebapp/grabstatuspart';
import { VatRequestPart } from '../sharewebapp/vatrequestpart';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { PanelHeader, getPanelIndex, getFlexIndex } from './webcommon';
import { Refine, Authenticated, AuthProvider } from "@pankod/refine-core";
import { ManageOrderVoidPart } from '../sharewebapp/manageordervoid';

const TABINDEXLIST = [
  { tabIndex: 0, status: ['in_basket'] },
  { tabIndex: 1, status: ['customer_paid', 'partner_reject'] },
  { tabIndex: 2, status: ['exta_start_deliver', 'partner_start_deliver', 'self_pickup_start', '7deli_start_deliver', 'speedd_start_deliver', 'post_start_deliver'] },
  { tabIndex: 3, status: ['partner_complete_deliver', '7deli_complete_deliver', 'self_pickup_complete', 'speedd_complete_deliver', 'post_complete_deliver'] },
  { tabIndex: 4, status: ['customer_got_deliver', 'exta_complete_deliver'] },
  { tabIndex: 5, status: ['cancel', 'cancel_by_system', 'cancel_by_admin', 'selfpickup_not_pickup'] }
]

export function ManageOrderScreen() {
  const { globalState } = useContext(store);
  const location = useLocation();
  const [listPanel, setListPanel] = useState([]);
  const listPanelRef = useRef(listPanel);

  const queryParameters = new URLSearchParams(window.location.search)
  const ordermainId = queryParameters.get("ordermainId")

  useEffect(() => {
    if (ordermainId) {
      loadOrder(ordermainId);
    } else {
      setListPanel([
        { name: 'ManageOrder', params: { forAdmin: location?.state?.forAdmin }, index: 0 }
      ]);
    }
  }, []);

  const loadOrder = async (id) => {
    const res = await globalState.client.service('ordermains').get(id)
    // alert(`Hello all: ${JSON.stringify(res)}`);
    const tmpTabIndex = TABINDEXLIST?.find(item => item.status.includes(res.orderStatus))?.tabIndex;
    setListPanel([
      { name: 'ManageOrder', params: { forAdmin: location?.state?.forAdmin, forceTabIndex: tmpTabIndex }, index: 0 },
      { name: 'ViewOrder', params: { ordermainId: id }, index: 1 }
    ])
  }

  useEffect(() => {
    listPanelRef.current = listPanel;
  }, [listPanel])

  const navigation = {
    navigate: (navName, navParams) => {
      const panelIndex = listPanelRef.current.findIndex(item => item.name == navName);
      if (panelIndex >= 0) {
        setListPanel(prev => {
          prev.length = panelIndex;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'ViewOrder') {
        setListPanel(prev => {
          //prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'PharmaNote') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'PrintOrder') {
        setListPanel(prev => {
          // prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'PrintQrCode') {
        setListPanel(prev => {
          // prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ViewProduct') {
        setListPanel(prev => {
          // prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'LalamoveStatus') {
        setListPanel(prev => {
          // prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'GrabStatus') {
        setListPanel(prev => {
          // prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'VatRequest') {
        setListPanel(prev => {
          // prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManageOrderVoid') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }      
      // alert('navName:'+navName)
    },
    setOptions: (optionParams, index) => {
      setListPanel(prev => {
        const tmpListPanel = [...prev];
        if (index >= 0) {
          tmpListPanel[index] = { ...tmpListPanel[index], options: optionParams }
        } else {
          tmpListPanel[tmpListPanel.length - 1] = { ...tmpListPanel[tmpListPanel.length - 1], options: optionParams }
        }
        return tmpListPanel
      })
    },
    goBack: (index) => {
      setListPanel(prev => {
        if (index >= 0) {
          prev.length = index;
        }
        return [...prev];
      })
    },
    dispatch: () => {
      console.log('dispatch')
    }
  }

  return (
    <Authenticated>
      <Row style={{ flex: 1 }}>
        <Col span={8} style={{ display: getFlexIndex(0, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[0]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(1, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[1]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(2, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[2]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(3, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[3]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(4, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[4]} navigation={navigation} />
        </Col>
      </Row>
    </Authenticated>
  )
}

function Panel({ panel, navigation }) {
  if (panel?.name == 'ManageOrder') {
    return (
      <SafeAreaProvider>
        <ManageOrderPart navigation={navigation} route={{ params: panel.params }} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewOrder') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} navigation={navigation} />
        <ViewOrderPart route={{ params: panel.params }} navigation={navigation} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'PrintOrder') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} navigation={navigation} />
        <PrintOrderPart route={{ params: panel.params }} navigation={navigation} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'PrintQrCode') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} navigation={navigation} />
        <PrintQrCodePart route={{ params: panel.params }} navigation={navigation} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewProduct') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} navigation={navigation} />
        <ViewProductPart route={{ params: panel.params }} navigation={navigation} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'PharmaNote') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} navigation={navigation} />
        <PharmaNotePart route={{ params: panel.params }} navigation={navigation} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'LalamoveStatus') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} navigation={navigation} />
        <LalamoveStatusPart route={{ params: panel.params }} navigation={navigation} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'GrabStatus') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} navigation={navigation} />
        <GrabStatusPart route={{ params: panel.params }} navigation={navigation} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'VatRequest') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} navigation={navigation} />
        <VatRequestPart route={{ params: panel.params }} navigation={navigation} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageOrderVoid') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} navigation={navigation} />
        <ManageOrderVoidPart route={{ params: panel.params }} navigation={navigation} />
      </SafeAreaProvider>
    )
  }  
}