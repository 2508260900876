import React, { createContext, useReducer } from 'react';
import { server_var } from "../config/servervar"
import { Platform } from 'react-native';
import { getSocketClient } from "../component/custom";
const { socket, client } = getSocketClient();

const initialState = {
  client,
  socket,
  user: null,
  onlineStatus: 'online',
  inChatScreen: { chatWithId: 0, chatWithType: null, chatFromId: 0, chatFromType: null },
  inPayScreen: false,
  inWaitScreen: false,
  deferCloseCase: false,
  totalUnread: { u2u: 0, s2u: 0, u2s: 0, queline: 0, noti: 0 },
  toast: { visible: false, text: '' },
  initInfo: { appVersion: 0, termVersion: 0, consentVersion: 0 },
  curAddress: { address: null, lat: null, long: null, locType: 'current', id: 0 },
  delAddress: { address: null, lat: null, long: null, locType: null, id: null },
  deferNoti: null,
  basketAmount: 0,
  notiAlertView: { visible: false, data: [], type: 'showNotiPopUp' },
  showInCallInfo: { visible: false },
  lastPinTime: 0,
  flowList: [],
  flowListAllMem: [],
  flowListAllCus: [],
  flowType: null,
  doneInfo: null,
  fromMainTab: null,
  flowInfo: {},
  forwardInfo: { timeStamp: 0 },
  guideFlag: false,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer((globalState, action) => {
    var newState = {};
    switch (action.type) {
      case 'setOnlineStatus':
        newState = {
          ...globalState,
          onlineStatus: action.payload
        }
        return newState
      case 'setDeferNoti':
        newState = {
          ...globalState,
          deferNoti: action.payload
        }
        return newState;
      case 'setInitInfo':
        newState = {
          ...globalState,
          initInfo: action.payload,
        };
        return newState;
      case 'setUser':
        newState = {
          ...globalState,
          user: action.payload,
        };
        return newState;
      case 'changeUserPhoto':
        newState = {
          ...globalState,
          user: { ...globalState.user, avatar: action.payload },
        };
        return newState;
      case 'setUserFingerFlag':
        newState = {
          ...globalState,
          user: { ...globalState.user, fingerFlag: action.payload },
        };
        return newState;
      case 'showToast':
        newState = {
          ...globalState,
          toast: {
            visible: true,
            text: action.payload.text,
            timeout: action.payload.timeout ? action.payload.timeout : 3000,
          },
        };
        return newState;
      case 'hideToast':
        newState = {
          ...globalState,
          toast: { visible: false },
        };
        return newState;
      case 'setDeferCloseCase':
        newState = {
          ...globalState,
          deferCloseCase: action.payload,
        };
        return newState;
      case 'setInWaitScreen':
        newState = {
          ...globalState,
          inWaitScreen: action.payload,
        };
        return newState;
      case 'setInPayScreen':
        newState = {
          ...globalState,
          inPayScreen: action.payload,
        };
        return newState;
      case 'setChatWith':
        newState = {
          ...globalState,
          inChatScreen: action.payload,
        };
        return newState;
      case 'setMsgTotalUnreadU2U':
        newState = {
          ...globalState,
          totalUnread: { ...globalState.totalUnread, u2u: action.payload },
        };
        return newState;
      case 'setMsgTotalUnreadS2U':
        newState = {
          ...globalState,
          totalUnread: { ...globalState.totalUnread, s2u: action.payload },
        };
        return newState;
      case 'setMsgTotalUnreadU2S':
        newState = {
          ...globalState,
          totalUnread: { ...globalState.totalUnread, u2s: action.payload },
        };
        return newState;
      case 'setQuelineTotalUnread':
        newState = {
          ...globalState,
          totalUnread: { ...globalState.totalUnread, queline: action.payload },
        };
        return newState;
      case 'setCurAddress':
        newState = {
          ...globalState,
          curAddress: action.payload
        }
        return newState;
      case 'setDelAddress':
        const { createdAt, updatedAt, defaultFlag, ...rest } = action.payload;
        newState = {
          ...globalState,
          delAddress: rest
        }
        return newState;
      case 'setBasketAmount':
        newState = {
          ...globalState,
          basketAmount: action.payload
        }
        return newState;
      case 'setUnreadNoti':
        newState = {
          ...globalState,
          totalUnread: { ...globalState.totalUnread, noti: action.payload },
        }
        return newState;
      case 'hideNotiAlertView':
        newState = {
          ...globalState,
          notiAlertView: { ...globalState.notiAlertView, visible: false },
        }
        return newState;
      case 'showNotiAlertView':
        newState = {
          ...globalState,
          notiAlertView: { ...globalState.notiAlertView, visible: true },
        }
        return newState;
      case 'addNotiAlertData':
        newState = {
          ...globalState,
          notiAlertView: {
            visible: globalState.notiAlertView.visible,
            type: globalState.notiAlertView.type,
            data: [...globalState.notiAlertView.data, action.payload],
          },
        }
        return newState;
      case 'clearNotiAlertData':
        newState = {
          ...globalState,
          notiAlertView: {
            visible: globalState.notiAlertView.visible,
            type: globalState.notiAlertView.type,
            data: [],
          },
        }
        return newState;
      case 'setNotiAlertData':
        newState = {
          ...globalState,
          notiAlertView: { ...globalState.notiAlertView, data: action.payload },
        }
        return newState;
      case 'setInCallInfo':
        newState = {
          ...globalState,
          showInCallInfo: action.payload,
        }
        return newState;
      case 'zeroAllNoti':
        newState = {
          ...globalState,
          totalUnread: { u2u: 0, s2u: 0, u2s: 0, queline: 0, noti: 0 },
        }
        return newState;
      case 'setLastPinTime':
        newState = {
          ...globalState,
          lastPinTime: action.payload
        }
        return newState;
      case 'setFlowListAndFlowType':
        newState = {
          ...globalState,
          flowList: action.payload?.flowList,
          flowListAllCus: action.payload?.flowListAllCus,
          flowListAllMem: action.payload?.flowListAllMem,
          flowType: action.payload?.flowType
        };
        return newState;
      case 'setFlowList':
        newState = {
          ...globalState,
          flowList: action.payload.flowList,
          flowListAllCus: action.payload.flowListAllCus,
          flowListAllMem: action.payload.flowListAllMem,
        };
        return newState;
      case 'setDoneInfo':
        newState = {
          ...globalState,
          doneInfo: action.payload
        }
        return newState;
      case 'setFlowInfo':
        console.log('setFlowInfo', action.payload);
        newState = {
          ...globalState,
          flowInfo: {
            ...(action?.payload?.shopType ? { shopType: action?.payload?.shopType } : { shopType: globalState?.flowInfo?.shopType }),
            ...(action?.payload?.pinEnterMode ? { pinEnterMode: action?.payload?.pinEnterMode } : { pinEnterMode: globalState?.flowInfo?.pinEnterMode }),
            ...(action?.payload?.otpMode ? { otpMode: action?.payload?.otpMode } : { otpMode: globalState?.flowInfo?.otpMode }),
            ...(action?.payload?.mobileNumber ? { mobileNumber: action?.payload?.mobileNumber } : { mobileNumber: globalState?.flowInfo?.mobileNumber }),
            ...(action?.payload?.userId ? { userId: action?.payload?.userId } : { userId: globalState?.flowInfo?.userId }),
            ...(action?.payload?.password ? { password: action?.payload?.password } : { password: globalState?.flowInfo?.password }),
            ...(action?.payload?.otpCode ? { otpCode: action?.payload?.otpCode } : { otpCode: globalState?.flowInfo?.otpCode }),
            ...(action?.payload?.debugNum ? { debugNum: action?.payload?.debugNum } : { debugNum: globalState?.flowInfo?.debugNum })
          }
        }
        return newState;
      case 'setFlowType':
        newState = { ...globalState, flowType: action.payload }
        return newState;
      case 'setFromMainTab':
        newState = { ...globalState, fromMainTab: action.payload }
        return newState;
      case 'setForwardInfo':
        newState = { ...globalState, forwardInfo: action.payload }
        return newState;
      case 'setGuideFlag':
        newState = { ...globalState, guideFlag: action.payload }
        return newState;
      default:
        throw new Error();
    }
  }, initialState);
  return <Provider value={{ globalState, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
