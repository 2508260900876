import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';

import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { useDebounce } from '../sharewebapp/common1';
import EventEmitter from '../sharewebapp/EventEmitter';
import { CustomIcon } from '../component/custom';

export function ManagePromotionPart({ navigation, route }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadDataList();
  }, [])

  useEffect(() => {
    EventEmitter.addListener('Promotion:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('Promotion:refresh', handleRefresh);
    }
  }, [])

  const handleRefresh = () => {
    loadDataList();
  }

  const loadDataList = async () => {
    if (!loading) {
      setLoading(true);
      const res = await globalState.client.service('feeds').find({ query: { feedType: 'PromoBanner', $sort: { feedScore: 1 } } });
      setDataList(res.data);
      setLoading(false);
      //console.log('\nPromotionBanner : ', res.data);
    }
  }

  const onEdit = (id) => {
    navigation.navigate('EditPromotion', { id });
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', marginHorizontal: 20, borderBottomColor: 'lightgray', borderBottomWidth: 1 }}>
        <View style={{ paddingVertical: 5 }}>
          <TouchableOpacity style={{
            height: 40, backgroundColor: themeColor.color1,
            borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginVertical: 10, paddingHorizontal: 20
          }} onPress={() => navigation.navigate('EditPromotion', { id: 0 })}>
            <Text style={{ color: 'white' }}><CustomIcon name="plus-circle-outline" size={16} color='white' /> เพิ่ม</Text>
          </TouchableOpacity>
        </View>
      </View>
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onRefresh={(item) => loadDataList()}
        refreshing={(loading)}
        style={{ marginTop: 0 }}
        renderItem={({ item }) => (
          <PromoItem item={item} onEdit={onEdit} />
        )}
      />
    </SafeAreaView>
  )
}

function PromoItem({ item, onEdit }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5, marginHorizontal: 20, }}
      onPress={() => onEdit(item.id)}
    >
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1 }}>
        <View style={{ paddingBottom: 10, flex: 1 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingTop: 5 }}>
            <View style={{ flexDirection: 'column' }}>
              <Text style={{ color: 'black', fontSize: 14, fontWeight: 'bold', flex: 1, flexWrap: 'wrap' }}>{item?.title}</Text>
              <Text style={{ color: 'grey', fontSize: 14, fontWeight: 'bold', flex: 1, flexWrap: 'wrap' }}>การแสดงลำดับที่ : {item?.feedScore}</Text>
            </View>
            <CustomIcon name="chevron-right-circle" size={20} color="gray" style={{ paddingHorizontal: 10 }} />
          </View>
          <Image
              resizeMode="contain"
              source={{ uri: item?.feedPhoto1 }}
              style={{
                borderRadius: 15,
                width: (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : (Dimensions.get('window').width - 40),
                height: (Platform.OS == 'web') ? Dimensions.get('window').width / 10 : ((Dimensions.get('window').width - 20) / 2.3) - 5,
                marginTop: 10 }} />
        </View>
      </View>
    </TouchableOpacity >
  )
}
