import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Keyboard, Text, Platform } from 'react-native';
import { Send, Actions } from 'react-native-gifted-chat';
// import { Composer } from 'react-native-gifted-chat';
import { Composer } from './CustomCompose';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
const Color = {
  defaultColor: '#b2b2b2',
  backgroundTransparent: 'transparent',
  defaultBlue: '#0084ff',
  leftBubbleBackground: '#f0f0f0',
  black: '#000',
  white: '#fff',
  carrot: '#e67e22',
  emerald: '#2ecc71',
  peterRiver: '#3498db',
  wisteria: '#8e44ad',
  alizarin: '#e74c3c',
  turquoise: '#1abc9c',
  midnightBlue: '#2c3e50',
  optionTintColor: '#007AFF',
  timeTextColor: '#aaa',
};
const StylePropType = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.object,
  PropTypes.number,
  PropTypes.bool,
]);

const styles = StyleSheet.create({
  container: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: Color.defaultColor,
    backgroundColor: Color.white,
    // bottom: 0,
    // left: 0,
    // right: 0,
  },
  primary: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  // accessory: {
  //   height: 44,
  // },
});
export function InputToolbar(props) {
  const [position, setPosition] = useState('absolute');
  const insets = useSafeAreaInsets();
  useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener('keyboardWillShow', () => setPosition('relative'));
    const keyboardWillHideListener = Keyboard.addListener('keyboardWillHide', () => setPosition('absolute'));
    return () => {
      keyboardWillShowListener === null || keyboardWillShowListener === void 0 ? void 0 : keyboardWillShowListener.remove();
      keyboardWillHideListener === null || keyboardWillHideListener === void 0 ? void 0 : keyboardWillHideListener.remove();
    };
  }, []);
  const { containerStyle, ...rest } = props;
  const { renderActions, onPressActionButton, renderComposer, renderSend, renderAccessory, } = rest;
  return (
    <View style={[
      styles.container,
      containerStyle,
      position == 'relative' ? { bottom: 0 } : null,
    ]}>
      <View style={[styles.primary, props.primaryStyle]}>
        {
          (
            renderActions === null || renderActions === void 0 ?
              void 0 : renderActions(rest)
          ) ||
          (onPressActionButton && <Actions {...rest} />)
        }
        {
          (renderComposer === null || renderComposer === void 0 ? void 0 : renderComposer(props)) ||
          <Composer
            {...props}
            textInputStyle={{ marginTop: 4 }}
            textInputProps={{
              ...props.textInputProps,
              blurOnSubmit: Platform.OS == 'web',
              onSubmitEditing: async () => {
                if (Platform.OS == 'web') {
                  await props.onSend({ text: props.text.trim() }, true);
                  document.getElementById("chat_text_input1").focus();
                }

              },
            }}

          />
        }
        {
          (renderSend === null || renderSend === void 0 ?
            void 0 : renderSend(props)) || <Send {...props} />
        }
      </View>

      {renderAccessory != undefined && (
        <View style={[styles.accessory, props.accessoryStyle]}>
          {renderAccessory(props)}
        </View>
      )}
    </View>
  );
}

InputToolbar.propTypes = {
  renderAccessory: PropTypes.func,
  renderActions: PropTypes.func,
  renderSend: PropTypes.func,
  renderComposer: PropTypes.func,
  onPressActionButton: PropTypes.func,
  containerStyle: StylePropType,
  primaryStyle: StylePropType,
  accessoryStyle: StylePropType,
};

