import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import { CustomIcon } from '../component/custom';
import EventEmitter from '../sharewebapp/EventEmitter';

export function ManageUserPart({ navigation }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [staffFlag, setStaffFlag] = useState(1);
  const [adminType, setAdminType] = useState();

  useEffect(() => {
    const loadUserRole = async () => {
      const res = await globalState.client.service('users').get(globalState?.user?.id, { query: { $select: ['roles'] } });
      setAdminType(res?.roles);
    }
    if (globalState.user) {
      loadUserRole();
    }
  }, [])

  useEffect(() => {
    EventEmitter.addListener('ManageUser:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('ManageUser:refresh', handleRefresh);
    }
  }, [searchText, staffFlag])

  const handleRefresh = () => {
    loadDataList(0);
  }

  useEffect(() => {
    loadDataList(0);
  }, [debouncedSearchTerm, staffFlag])

  const onEdit = (userId) => {
    let tmpFlag = false;
    if (staffFlag == 1) tmpFlag = true;
    navigation.navigate('EditUser', { userId, staffFlag: tmpFlag })
  }

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query = { $skip: pSkip ?? skip, $sort: { id: 1 }, $limit: 20, staffFlag };
      if (searchArray.length > 0) {
        const tmpSearch = searchArray.join('%');
        query = {
          ...query,
          $or: [
            { displayName: { $like: `%${tmpSearch}%` } },
            { mobileNumber: { $like: `%${tmpSearch}%` } },
            { staffPhoneNumber: { $like: `%${tmpSearch}%` } },
            { staffId: { $like: `%${tmpSearch}%` } },
          ],
        };
      }
      let res;
      try {
        res = await globalState.client.service('users').find({ query });
      } catch { }
      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {['admin', 'cs'].includes(adminType) &&
        <View style={{
          paddingRight: 20, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 20
        }}>
          {[
            { id: 2, title: 'พนักงาน', value: 1 },
            { id: 3, title: 'ลูกค้า', value: 0 },
          ].map(item => (
            <TouchableOpacity key={item.id.toString()} style={{ marginHorizontal: 10 }} onPress={() => setStaffFlag(item.value)}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CustomIcon name={item.value == staffFlag ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
                <Text style={{ marginLeft: 5 }}>{item.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      }
      <View style={{ flexDirection: 'row', marginHorizontal: 20 }}>
        {(staffFlag == 1 && ['admin'].includes(adminType)) &&
          <View style={{ flex: 0.3, paddingBottom: 10, paddingTop: 5 }}>
            <TouchableOpacity style={{
              height: 40, width: "100%", backgroundColor: themeColor.color1,
              borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginTop: 10
            }} onPress={() => navigation.navigate('EditUser', { userId: 0, staffFlag })}>
              <Text style={{ color: 'white' }}><CustomIcon name="plus-circle-outline" size={16} color='white' /> สร้าง</Text>
            </TouchableOpacity>
          </View>
        }

        <View style={{ flex: (staffFlag == 1 && ['admin'].includes(adminType)) ? 0.7 : 1, paddingBottom: 10, paddingTop: 5, marginTop: 5, marginLeft: (staffFlag == 1 && ['admin'].includes(adminType)) ? 15 : 0 }}>
          <TextInput
            value={searchText}
            onChangeText={text => setSearchText(text)}
            placeholder={(staffFlag == 1) ? 'ค้นหาจากชื่อหรือรหัสพนักงาน' : 'ค้นหาจากชื่อหรือหมายเลขโทรศัพท์มือถือ'}
            style={{ alignSelf: 'center', width: "100%", borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40, paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }}
          />
        </View>
      </View>

      <FlatList
        keyExtractor={(item) => item.id.toString()}
        style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: '60vh' } : null]}
        data={dataList}
        onEndReached={(xxx) => loadDataList()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item, index }) => (
          <UserItem item={item} index={index} onEdit={onEdit} />
        )}
      />
    </SafeAreaView>
  )
}

function UserItem({ item, index, onEdit }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5, marginHorizontal: 20, }}
      onPress={() => onEdit(item.id)}
    >
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1, flex: 1 }}>
        <View style={{ flex: 0.2, paddingBottom: 10, paddingTop: 5 }}>
          <Image
            style={{ width: 40, height: 40, borderRadius: 20 }}
            source={item?.avatar ? { uri: item?.avatar } : { uri: `${server_var.server_api}/images/unknown-user-pic.jpeg` }}
          />
        </View>
        <View style={{ flex: 0.8, paddingBottom: 10, paddingTop: 5 }}>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item?.displayName}</Text>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item?.staffFlag == 1 ? item?.staffId : item?.mobileNumber}</Text>
        </View>
      </View>
    </TouchableOpacity >
  )
}
