import React, { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator, Alert, TouchableWithoutFeedback, Keyboard, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';
import moment from 'moment';

export function EditPreOrderPart({ navigation, route }) {
  const { globalState, dispatch } = useContext(store);
  //const [data, setData] = useState({ orderType: 'ยาในเล่ม' });
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ at: '', message: '' });
  const [newOrder, setNewOrder] = useState(false);

  const FIELD_LIST = [
    // {
    //   id: 1, type: 'ordertype', title: 'รายการ Pre-Order', titleStyle: { marginTop: 10, color: themeColor.color1, fontWeight: 'bold' }, fieldName: 'orderType', value: data?.orderType, textInputStyle: { height: 40, marginTop: 5 }
    // },
    {
      id: 1, type: 'checkbox', title: 'กลุ่มสินค้าสั่งจอง',
      list: [{ title: 'สินค้า PMA18 ALL Products', value: 'all' }, { title: 'สินค้า PMA18 Pack Link (สินค้าสุขภาพราคายกแพ็ก)', value: 'pack' }, { title: 'สินค้า PMA18 Pre-Order (Medicine Catalogue)', value: 'pre' }],
      titleStyle: { marginTop: 10, color: themeColor.color1, fontWeight: 'bold' }, fieldName: 'productType', value: data?.productType, textInputStyle: { height: 40, marginTop: 5 }
    },       
    {
      id: 2, type: 'text', title: 'รายการสินค้าสั่งจอง', subtitle: '*กรุณาระบุ รหัสสินค้า, ชื่อสินค้า, จำนวน และราคา', titleStyle: { marginTop: 10 }, fieldName: 'orderDetail', value: data?.orderDetail, textInputProp: { multiline: true }, textInputStyle: { height: 120, marginTop: 5, padding: 5, textAlignVertical: "top" }
    },
    {
      id: 3, type: 'text', title: 'ยอดเงินรวม (บาท)', titleStyle: { marginTop: 10 }, fieldName: 'orderPrice', value: data?.orderPrice?.toString(), textInputStyle: { height: 40, marginTop: 5 }, textInputProp: { keyboardType: 'number-pad' }
    },   
  ]

  useEffect(() => {
    loadData();
  }, [route?.params?.queId])

  const loadData = async () => {
    let query = {
      queId: route?.params?.queId,
      //$select: ['id', 'orderType', 'orderDetail', 'orderPrice']
    };
    const res = await globalState.client.service('preorders').find({ query });
    //console.log('\nPromoBanner : ', route?.params?.id, res);
    if (res.total == 0) {
      setData(prev => ({ ...prev, userId: route?.params?.userId, pharmaId: globalState?.user?.id, shopId: route?.params?.shopId, queId: route?.params?.queId, orderStatus: 'pre_ordered' }));
      setNewOrder(true);
    } else {
      setData(res.data[0]);
      setNewOrder(false);
    }
  }

  const onDelete = async (id) => {
    const delAction = async () => {
      await globalState.client.service('preorders').remove(id);
      dispatch({ type: 'showToast', payload: { text: 'ลบข้อมูลเรียบร้อย' } });
      navigation.goBack(2);
    }
    if (Platform.OS == 'web' && window.confirm('คุณต้องการลบข้อมูล Pre-Order ?')) {
      delAction()
    } else {
      return Alert.alert('ลบ Pre-Order', 'คุณต้องการลบข้อมูล Pre-Order ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => delAction() },
        ]
      );
    }
  }

  const validateForm = () => {
    const blankField = ['productType', 'orderDetail', 'orderPrice'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    let isnum = /^([,|.]?[0-9])+$/.test(data.orderPrice);
    if (!isnum) {
      setError({ at: 'orderPrice', message: 'ต้องเป็นตัวเลขเท่านั้น' });
      return false;
    }    
    return true;
  }

  const onSave = async (id) => {
    setError({ at: '', message: '' });
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    let res;
    if (newOrder) {
      //ตรวจสอบว่ามีการสร้าง pharmanotes แล้วหรือยัง
      const resPhamarNote = await globalState.client.service('pharmanotes').find(
        { query: { userId: data?.userId, shopId: data?.shopId, queId: data?.queId, pharmaId: data?.pharmaId } }
      )
      if (resPhamarNote.total == 0) {
        const resOldQueLine = await globalState.client.service('oldquelines').find({ query: { oldId: data?.queId } });
        globalState.client.service('pharmanotes').create({
          queId: data?.queId, shopId: data?.shopId, userId: data?.userId, pharmaId: data?.pharmaId, startTime: resOldQueLine?.data?.[0]?.acceptStartTime, psctype: 'not_prescribe',
        })
      }
      res = await globalState.client.service('preorders').create({
        userId: data?.userId,
        pharmaId: data?.pharmaId,
        shopId: data?.shopId,
        queId: data?.queId,
        //orderType: data?.orderType,
        productType: data?.productType,
        orderStatus: data?.orderStatus,
        orderDetail: data?.orderDetail,
        orderPrice: data?.orderPrice,
        orderDate: moment().utc().format('YYYY-MM-DD HH:mm')
      });
    } else {
      res = await globalState.client.service('preorders').patch(id, {
        //orderType: data?.orderType,
        productType: data?.productType,
        orderDetail: data?.orderDetail,
        orderPrice: data?.orderPrice,
      });
    }
    setLoading(false);
    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลเรียบร้อย' } });
    navigation.goBack(2);
  }

  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5, }}>
      <ScrollView contentContainerStyle={[
        { paddingBottom: 260, paddingHorizontal: 20 }, Platform.OS == 'web' ? { height: '80vh' } : null
      ]}>
        {
          FIELD_LIST.map(item =>
            <CustomItem key={item.id.toString()} item={item} setData={setData} error={error} />
          )}

        <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 30, flex: 1 }}>
          { (!newOrder) &&
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onDelete(data?.id)} >
              <View style={{
                height: 40, borderRadius: 10,
                justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color6
              }}>
                <Text style={{ color: 'white' }}>ลบ</Text>
              </View>
            </TouchableOpacity>
          }
          <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onSave(data?.id)} >
            <View style={{
              height: 40, borderRadius: 10,
              justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color1
            }}>
              <Text style={{ color: 'white' }}>บันทึก</Text>
            </View>
          </TouchableOpacity>
        </View>

        <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline', marginTop: 20 }}>หมายเหตุ</Text>
        <Text style={{ marginTop: 10 }}>กรณีลูกค้าต้องการสินค้าด่วน สามารถใช้กระบวนการจัดส่ง แบบ Express ได้ โดยมียอดขั้นต่ำ 500 บาท</Text>

        {loading &&
          <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size='large' />
          </View>
        }
      </ScrollView>
    </SafeAreaView>
  )
}

function CustomItem({ item, setData, error }) {
  if (item.type == 'text') {
    return <CustomText item={item} setData={setData} error={error} />
  } else if (item.type == 'ordertype') {
    return <OrderType item={item} setData={setData} />
  } else if (item.type == 'checkbox') {
    return <CheckBox item={item} setData={setData} error={error} />
  }
}

function CustomText({ item, setData, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <TextInput
        {...item.textInputProp}
        value={item.value}
        onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
        style={[item.textInputStyle, { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' }, error?.at == item.fieldName && { borderColor: 'red' }]}
      />
      {item?.subtitle && <Text style={[{ fontSize: 12, color: 'grey', marginTop: 5 }, item.subtitleStyle ?? null]}>{item?.subtitle}</Text> }
      {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
    </View>
  )
}

function OrderType({ item, setData }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <View style={{
        paddingRight: 20, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 10
      }}>
        {[
          { id: 1, title: 'ยาในเล่ม', value: 'ยาในเล่ม' },
          { id: 2, title: 'ยาในร้าน', value: 'ยาในร้าน' },
        ].map(item2 => (
          <TouchableOpacity key={item2.id.toString()} style={{ marginHorizontal: 10 }} onPress={() => setData(prev => ({ ...prev, [item.fieldName]: item2.value }))}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <CustomIcon name={item2.value == item?.value ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
              <Text style={{ marginLeft: 5 }}>{item2.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}

function CheckBox({ item, setData, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <View style={{ flexDirection: 'column', justifyContent: 'flex-start', marginTop: 10 }}>
        {item.list.map(item5 => (
          <TouchableOpacity key={item5.value} style={{ marginHorizontal: 10 }} onPress={() => {
            setData(prev => {
              if (prev?.[item.fieldName]?.includes(item5.value)) {
                const tmp = prev?.[item.fieldName].filter(item6 => item6 != item5.value)
                return { ...prev, [item.fieldName]: tmp }
              } else {
                return { ...prev, [item.fieldName]: [...((prev?.[item.fieldName]) ?? []), item5.value] }
              }
            })
          }
          }>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <CustomIcon name={item?.value?.includes(item5.value) ? "checkbox-marked-outline" : "checkbox-blank-outline"} size={20} color={'gray'} />
              <Text style={{ marginLeft: 3 }}>{item5.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
      {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
    </View>
  )
}
