import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import { CustomIcon } from '../component/custom';
import EventEmitter from '../sharewebapp/EventEmitter';
import moment from 'moment';

export function ManageCouponPage({ navigation }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [couponStatus, setCouponStatus] = useState('All');
  const statusList = [
    { id: 1, title: 'ทั้งหมด', value: 'All' },
    { id: 2, title: 'ใช้งาน', value: 'active' },
    { id: 3, title: 'เลิกใช้งาน', value: 'disable' }
  ];

  useEffect(() => {
    EventEmitter.addListener('ManageCoupon:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('ManageCoupon:refresh', handleRefresh);
    }
  }, [searchText])

  const handleRefresh = () => {
    loadDataList(0, couponStatus);
  }

  useEffect(() => {
    loadDataList(0, couponStatus);
  }, [debouncedSearchTerm])

  const onEdit = (couponId) => {
    navigation.navigate('EditCoupon', { couponId })
  }

  const loadDataList = async (pSkip, pCouponStatus) => {
    if (!loading) {
      setLoading(true);
      let res;
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query = { $skip: pSkip ?? skip, $sort: { id: -1 }, $limit: 20 };
      if (searchArray.length > 0) {
        const tmpSearch = searchArray.join('%');
        query = {
          ...query,
          title: { $like: `%${tmpSearch}%` },
        };
      }
      if (pCouponStatus != 'All') {
        query = {
          ...query,
          couponStatus: pCouponStatus,
        };
      }

      try {
        res = await globalState.client.service('coupons').find({ query });
      } catch { }

      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      console.log('point4')
      setLoading(false);
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', marginHorizontal: 20 }}>
        <View style={{ flex: 0.3, paddingBottom: 10, paddingTop: 5 }}>
          <TouchableOpacity style={{
            height: 40, width: "100%", backgroundColor: themeColor.color1,
            borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginTop: 10
          }} onPress={() => navigation.navigate('EditCoupon', { couponId: 0 })}>
            <Text style={{ color: 'white' }}><CustomIcon name="plus-circle-outline" size={16} color='white' /> สร้าง</Text>
          </TouchableOpacity>
        </View>
        <View style={{ flex: 0.7, paddingBottom: 10, paddingTop: 5, marginTop: 5, marginLeft: 10 }}>
          <TextInput
            value={searchText}
            onChangeText={text => setSearchText(text)}
            placeholder={'ค้นหาคูปอง'}
            style={{ alignSelf: 'center', width: "100%", borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40, paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }}
          />
        </View>        
      </View>
      <View style={{ marginTop: 5, marginBottom: 5 }}>
        <ScrollView horizontal style={{ paddingHorizontal: 20 }}>
          {statusList?.map(item => (
            <View key={item.id.toString()} style={{ marginTop: 5, marginBottom: 5 }}>
              <TouchableOpacity
                style={{ padding: 10, borderRadius: 10, backgroundColor: couponStatus == item.value ? themeColor.color1 : 'lightgray', marginRight: 10 }}
                onPress={() => {
                  loadDataList(0, item.value);
                  setCouponStatus(item.value);
                }}
              >
                <Text style={{ color: couponStatus == item.value ? 'white' : 'black' }}>{item.title}</Text>
              </TouchableOpacity>
            </View>
          ))}
        </ScrollView>
      </View>      

      <FlatList
        keyExtractor={(item) => item.id.toString()}
        style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: '60vh' } : null]}
        data={dataList}
        onEndReached={(xxx) => loadDataList(null, couponStatus)}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadDataList(0, couponStatus)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item, index }) => (
          <CouponItem item={item} onEdit={onEdit} />
        )}
      />
    </View>
  )
}

function CouponItem({ item, onEdit }) {
  return (
    <TouchableOpacity onPress={() => onEdit(item.id)} >
      <View style={{
        flexDirection: 'row', alignItems: 'center', height: 100,
        borderRadius: 10, backgroundColor: 'white', paddingHorizontal: 10, paddingVertical: 5,
        marginVertical: 5, marginHorizontal: 20
      }}>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <View style={{ flex: 0.4, alignItems: 'center', justifyContent: 'center' }}>
            <Image style={{ width: 168, height: 70 }}
              source={item?.photo1 ? { uri: item?.photo1 } : require('../assets/images/logo.png')}
              resizeMode="cover"
            />
          </View>
          <View style={{ flex: 0.6 }}>
            <View style={{ flexDirection: 'column', flex: 1, justifyContent: 'space-around' }}>
              <Text style={{ fontWeight: 'bold', flexWrap: 'wrap' }}>{item?.id}. {item?.title}</Text>
              <Text style={{ fontSize: 10, color: 'gray' }}> </Text>
              <Text style={{ fontSize: 10, color: 'gray' }}>วันที่สร้าง    {moment(item?.createdAt).format('DD/MM/YYYY')}</Text>
              <Text style={{ fontSize: 10, color: 'gray' }}>วันที่เริ่มต้น  {moment(item?.startDate).format('DD/MM/YYYY')}</Text>
              <Text style={{ fontSize: 10, color: 'gray' }}>วันที่สิ้นสุด   {moment(item?.endDate).format('DD/MM/YYYY')}</Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity >
  )
}
