import React, { useState, useEffect, useCallback, useContext, forwardRef, useRef, useImperativeHandle } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, SafeAreaView, ScrollView, Dimensions, Modal, Alert, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from '../sharewebapp/store'
import { CustomIcon } from '../component/custom';
import moment from 'moment';
// import { ViewPharmaNoteView } from './ViewPharmaNote';
import { ViewPharmaNoteView } from './viewpharmanote';
import { ViewOrderView } from '../sharewebapp/vieworderpart';
import { Dialog } from '../sharewebapp/pharmanotepart';
import { Appointment, CancelAppointment } from '../sharewebapp/pharmanotepart';
import EventEmitter from './EventEmitter';

export function ViewPharmaNoteOrderAppointmentPart({ navigation, route }) {
  const { globalState, dispatch } = useContext(store);
  const { ordermainId, pharmaNoteId, appointmentId, showAppAction, showEditPharmaNote } = route?.params;
  const [dialog, setDialog] = useState({ visible: false, type: null });

  const onBackDialog = (a) => {
    console.log('hello')
  }

  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const comRef = useRef();
  const dialogProp = { dialog, onBackDialog, setDialog, dialogWidth };
  const dialogContent = [
    {
      type: 'cancelappointment',
      title: 'ยกเลิกนัดหมาย',
      com: <CancelAppointment ref={comRef} {...dialogProp} pharmaNoteId={pharmaNoteId} />,
      actionList: [
        {
          id: 1, title: 'ยืนยันการยกเลิกนัดหมาย', onPress: () => {
            EventEmitter.notify('ManageAppointment:refresh');
            comRef.current.remove();
            setDialog(prev => ({ ...prev, visible: false }));
            navigation.goBack(2);
          }, touchStyle: { flex: 0.8 }
        },
      ]
    },
    {
      type: 'appointment2',
      title: 'นัดหมาย',
      com: <Appointment ref={comRef}  {...dialogProp} pharmaNoteId={pharmaNoteId} />,
      actionList: [
        {
          id: 1, title: 'ยกเลิก', onPress: () => setDialog(prev => ({ ...prev, visible: false })),
          style: { backgroundColor: 'white', borderColor: themeColor.color1, borderWidth: 1, },
          textStyle: { color: themeColor.color1 },
        },
        { id: 2, title: 'บันทึก', onPress: () => comRef.current.save(), },
      ]
    },
  ];


  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={[Platform.OS == 'web' ? { height: '70vh' } : null]}>
        <View>
          {appointmentId && <ViewAppointmentView route={route} />}
          {pharmaNoteId && <ViewPharmaNoteView showEditPharmaNote={showEditPharmaNote} route={route} navigation={navigation} />}
          {ordermainId && <ViewOrderView ordermainId={ordermainId} />}
        </View>
      </ScrollView>
      {showAppAction && (
        <View style={{ height: 40, flexDirection: 'row', justifyContent: 'space-around', marginBottom: 10 }}>
          {[
            { id: 1, title: 'แก้ไขนัดหมาย', style: { backgroundColor: themeColor.color1, }, onPress: () => setDialog(prev => ({ ...prev, visible: !prev.visible, type: 'appointment2' })) },
            {
              id: 2, title: 'ลบนัดหมาย', style: { backgroundColor: themeColor.color6, }, onPress: () => {
                setDialog(prev => ({ ...prev, visible: !prev.visible, type: 'cancelappointment' }));
              }
            },
          ].map(item2 => (
            <TouchableOpacity
              key={item2.id.toString()}
              onPress={() => item2.onPress()}
              style={{ flex: 0.45 }}
            >
              <View style={[{ alignItems: 'center', justifyContent: 'center', height: 40, borderRadius: 5 }, item2.style]}>
                <Text style={{ color: 'white' }}>{item2.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      )}
      <Dialog
        dialog={dialog} setDialog={setDialog} pharmaNoteId={pharmaNoteId} onBackDialog={onBackDialog}
        dialogContent={dialogContent} dialogWidth={dialogWidth}
      />
    </SafeAreaView >
  )
}

function ViewAppointmentView({ route }) {
  const { appointmentId } = route?.params;
  const [data, setData] = useState();
  const { globalState } = useContext(store);

  const COMPONENT_LIST = [
    { id: 1, type: 'inline', title: 'ชื่อผู้รับบริการ', field: data?.user?.displayName },
    { id: 2, type: 'inline', title: data?.shop?.shopType == 'special1' ? 'ผู้ให้บริการ' : 'เภสัชกร', field: data?.pharmacist?.displayName },
    { id: 3, type: 'inline', title: 'ร้านยา', field: `${data?.shop?.shopThName}` },
    { id: 4, type: 'inline', title: 'วันและเวลาที่รับบริการ', field: moment(data?.appDateTime).format('DD/MM/YYYY HH:mm') },
  ];

  const loadData = async () => {
    const res = await globalState.client.service('appointments').get(appointmentId);
    setData(res);
  }

  useEffect(() => {
    loadData();
  }, [data])

  return (
    <View>
      <Text style={{ paddingHorizontal: 20, fontWeight: 'bold', marginTop: 10 }}>
        การนัดหมาย
        <Text style={{ fontWeight: 'normal', color: 'gray', fontSize: 12 }}> เลขที่ {appointmentId}</Text>
      </Text>
      <View style={{
        paddingHorizontal: 20, paddingVertical: 10, backgroundColor: 'white',
        marginHorizontal: 20, borderRadius: 10, marginTop: 10, marginBottom: 20
      }}>
        {COMPONENT_LIST.map(item => <CustomCom key={item.id.toString()} item={item} />)}
      </View>
    </View>
  )
}

function CustomCom({ item }) {
  const [seeMore, setSeeMore] = useState(false);
  if (item.type == 'break') {
    return <View style={{ height: 2, backgroundColor: themeColor.color8, marginVertical: 10, marginHorizontal: -20 }} />
  } else if (item.type == 'inline') {
    return (
      <View style={{ flexDirection: 'row', marginVertical: 2 }}>
        <Text style={{ flex: 0.4 }}>{item.title}</Text>
        <Text style={{ flex: 0.6 }}>{item.field}</Text>
      </View>
    )
  } else if (item.type == 'nextline') {
    return (
      <View >
        <Text>{item.title}</Text>
        <Text>{item.field}</Text>
      </View>
    )
  } else if (item.type == 'attachment') {
    return (
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginHorizontal: -10 }}>
        {item?.field?.map(item2 => (
          <TouchableOpacity
            key={item2.id.toString()}
            onPress={() => Linking.openURL(`${server_var.server_api}/${item2.path}`)}
            style={{ width: '46%', marginHorizontal: 5, marginVertical: 5, }}
          >
            <View key={item2.id.toString()} style={{
              height: 40, padding: 5, backgroundColor: 'lightgray', flexDirection: 'row', borderRadius: 10
            }}>
              <CustomIcon name="file-pdf-box" size={24} />
              <View style={{ flexWrap: 'wrap', flexDirection: 'row', flex: 1 }}>
                <Text style={{ fontSize: 12 }}>{item2?.title}</Text>
              </View>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    )
  } else {
    return <View />
  }
}

