import React, { useState, useCallback, useEffect, useContext } from 'react'
import { Refine, Authenticated, AuthProvider } from "@pankod/refine-core";
import { store } from "../sharewebapp/store";
import { TouchableOpacity, View, Text, Alert } from "react-native-web"
import { GiftedChat } from 'react-native-gifted-chat'
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Typography } from '@pankod/refine-antd';
import { message, Popconfirm } from '@pankod/refine-antd';
import { Button, Modal } from '@pankod/refine-antd';
// import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';
// import { WebView } from 'react-native-web-webview';

export const Test1 = () => {
  const { globalState, dispatch } = useContext(store);
  const [messages, setMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setMessages([
      {
        _id: 1,
        text: 'Hello developer',
        createdAt: new Date(),
        user: {
          _id: 2,
          name: 'React Native',
          avatar: 'https://placeimg.com/140/140/any',
        },
      },
    ])
  }, [])

  const onSend = useCallback((messages = []) => {
    setMessages(previousMessages => GiftedChat.append(previousMessages, messages))
  }, [])

  const confirm = (e) => {
    console.log(e);
    message.success('Click on Yes');
  };
  const cancel = (e) => {
    console.log(e);
    message.error('Click on No');
  };

  const showModal = () => {
    // alert('hj')
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Authenticated>
      <SafeAreaProvider>
        <View>
          <Typography.Text>Hello eeeee</Typography.Text>
          <Typography.Title level={5}>Hello eeeee</Typography.Title>
          <Typography.Text>{JSON.stringify(globalState?.totalUnread)}</Typography.Text>
        </View>
        <Icon path={mdiAccount}
          size={1}
          horizontal
          vertical
          rotate={90}
          color="red" />
        {/* <Popconfirm
          title="Delete the task"
          placement="left"
          description="Are you sure to delete this task?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <a href="#">Delete</a>
        </Popconfirm> */}
        <Button type="primary" onClick={showModal} style={{ width: 200 }}>
          Open Modal
        </Button>
        <div style={{ position: 'relative', width: 200, height: 200 }}>
          <Modal title="Basic Modal" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} style={{ width: 200, height: 200 }}>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
          </Modal>
          wer
        </div>
        <div>
          <Typography>ewrwe</Typography>
        </div>

        <Typography>ere</Typography>
        <TouchableOpacity onPress={() => {
          window.confirm("Hello")
        }}>
          <Text>Text Button</Text>
        </TouchableOpacity>
        {/* <WebView source={{ uri: 'https://www.gurugooroo.com' }} style={{ height: 470, width: 550 }} /> */}
      </SafeAreaProvider>
    </Authenticated>
  );
};

