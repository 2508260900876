import React, { useState, useCallback, useEffect, useContext } from 'react'
import { Button, TouchableOpacity, Text, View, SafeAreaView, FlatList, Dimensions, Image, StatusBar } from 'react-native'
import { themeColor, fontStyles, server_var } from '../config/servervar';
import { store } from './store'
import moment from 'moment';
import { getJwtToken } from '../component/custom';

export function WaitingPart({ onPress, refreshSeed }) {
  const { globalState, dispatch } = useContext(store);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    loadData(0);
  }, [refreshSeed])

  useEffect(() => {
    globalState.client.service('notitemplogs').on('created', onNotiListener);
    return () => {
      globalState.client.service('notitemplogs').removeListener('created', onNotiListener);
    };
  }, [dataList]);

  const onNotiListener = (payload) => {
    if (payload.toUserId == globalState.user.id && payload.type == 'RefreshQueline') {
      loadData(0);
    }
  };

  const loadData = async (pSkip) => {
    const limit = 20;
    let res;
    try {
      const jwtToken = await getJwtToken();
      const resRaw = await fetch(`${server_var.server_api}/findQuelinesByPharmaId`, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
        body: JSON.stringify({ pharmaId: globalState?.user?.id, skip: pSkip, limit })
      });
      res = await resRaw.json();
    } catch { }
    console.log('\n\nquelines res', res);
    if (res) {
      if ((pSkip) == 0) {
        setDataList(res.data);
      } else {
        setDataList(prev => [...prev, ...res.data]);
      }
      setSkip((res.skip + res.limit));
      if (res.total == 0) {
        dispatch({ type: 'setQuelineTotalUnread', payload: 0 });
      }
    }
    // setDataList(res);
  }

  return (
    <View style={{ flex: 1 }}>
      {(!dataList || dataList.length == 0) && (
        <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: 'gray' }}>ยังไม่มีคิวรอปรึกษา</Text>
        </View>
      )}
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onEndReached={(xxx) => loadData(skip)}
        onEndReachedThreshold={0.8}
        onRefresh={() => loadData(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item }) => (
          <WaitingItem item={item} onPress={onPress} />
        )}
      />
    </View>
  )
}

function WaitingItem({ item, onPress }) {
  const { globalState } = useContext(store);
  const [shortFlag, setShortFlag] = useState(false);
  return (
    <TouchableOpacity style={{ marginVertical: 10, flex: 1 }} onPress={() => {
      globalState.client.service('quelines').patch(item.id, { unreadNum: 0 });
      onPress(item);
    }}>
      <View style={{
        flexDirection: 'row', flex: 1, marginHorizontal: 10, borderBottomWidth: 1, borderColor: 'lightgray', paddingBottom: 10
      }}
      >
        <Image source={{ uri: item?.user?.avatar }} style={{ width: 40, height: 40, borderRadius: 20 }} />
        <View style={{ marginLeft: 10, flex: 1 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={{ fontWeight: 'bold' }}>
              {item?.user?.displayName}
            </Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ color: 'gray' }}>
                {moment(item.createdAt).format('YYYY-MM-DD HH:mm')}
              </Text>
              {item.unreadNum ? (
                <View
                  style={{
                    marginLeft: 5, width: 26, height: 26, borderRadius: 13, backgroundColor: themeColor.color6,
                    justifyContent: 'center', alignItems: 'center'
                  }}>
                  <Text style={[fontStyles.body1, { fontWeight: 'bold', color: 'white' }]}>
                    {item.unreadNum ? '!' : ''}
                  </Text>
                </View>
              ) : (
                <View style={{ width: 26, height: 26 }} />
              )}
            </View>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
            <Text style={{ fontSize: 14, textAlign: 'center' }}>รอปรึกษาร้าน {item?.shop?.shopThName}</Text>
            {item?.queType != null && (
              <View style={{ backgroundColor: themeColor.color1, paddingHorizontal: 10, borderRadius: 5 }}>
                <Text style={{ fontSize: 12, color: 'white' }}>
                  {[
                    { id: 1, title: 'ส่งต่อ/ปรึกษาเพิ่ม', value: 'forward_consult' },
                    { id: 2, title: 'ส่งต่อ/สั่งซื้อสินค้า', value: 'forward_order' },
                    { id: 3, title: 'มาจากนัดหมาย', value: 'from_appointment' }
                  ].find(item2 => item2.value == item?.queType).title}
                </Text>
              </View>
            )}
          </View>
          {item?.fromShopId && (
            <View>
              <Text style={{ fontSize: 14, color: themeColor.color1, fontWeight: 'bold' }}>
                ส่งต่อมาจากร้าน: {item?.fromShop?.shopThName}
              </Text>

              <TouchableOpacity onPress={() => {
                if (item?.queText?.length > 80 && !shortFlag) {
                  setShortFlag(true);
                } else if (item?.queText?.length > 80 && shortFlag) {
                  setShortFlag(false);
                }
              }}>
                <View style={{ flexDirection: 'row', flex: 1 }}>
                  <Text style={{ fontSize: 12, alignItems: 'center', flexWrap: 'wrap' }}>
                    ข้อความจากร้าน:
                    {item?.queText?.length > 80 && !shortFlag && (item?.queText.substr(0, 50) + ' ... ดูเพิ่มเติม')}
                    {item?.queText?.length > 80 && shortFlag && (item?.queText + ' ...ซ่อน')}
                    {item?.queText?.length <= 80 && (item?.queText)}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity >
  )
}