import React, { createContext, useReducer } from "react";
import io from "socket.io-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";
import rest from "@feathersjs/rest-client";
import axios from "axios";
import { server_var } from "../config/servervar";
import { appLogin,appLogout } from "../sharewebapp/common1"

const authProvider = (globalState, dispatch) => {
  return {
    login: async ({ username, password,redirectPath }) => {
      let res;
      try {
        res = await appLogin(username, password, globalState, dispatch)
        return Promise.resolve(redirectPath);
      } catch (e) {
        return Promise.reject(e)
      }
    },
    logout: async () => {
      await appLogout(globalState,dispatch);
      // await globalState.client.logout();
      return Promise.resolve();
    },
    checkError: () => {
      return Promise.resolve();
    },
    checkAuth: async () => {
      if (localStorage.getItem("feathers-jwt")) {
        const client = feathers();
        client.configure(rest(server_var.server_api).axios(axios));
        client.configure(auth({ storage: window.localStorage }));
        try {
          const res = await client.reAuthenticate();
          return Promise.resolve();
        } catch (e) {
          return Promise.reject(e)
        }
      } else {
        return Promise.reject()
      }
    },
    getPermissions: async () => {
      if (localStorage.getItem("feathers-jwt")) {
        const client = feathers();
        client.configure(rest(server_var.server_api).axios(axios));
        client.configure(auth({ storage: window.localStorage }));
        try {
          const res = await client.reAuthenticate();
          return Promise.resolve(res?.user?.roles);
        } catch (e) {
          return Promise.reject(e)
        }
      } else {
        return Promise.reject()
      }
    },
  }

};

export default authProvider;