import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, StyleSheet, Modal, Dimensions, ScrollView, SafeAreaView, Linking, Alert } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';
import ParsedText from 'react-native-parsed-text';
import moment from 'moment';
import EventEmitter from '../sharewebapp/EventEmitter';

export function ViewFeedNotiDetailPart({ navigation, route }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState();
  const [imageSize, setImageSize] = useState();

  const loadData = async (id) => {
    const res = await globalState.client.service('feeds').get(id);
    setData(res);
    //console.log('\nfeeds : ', res);
  }

  useEffect(() => {
    loadData(route?.params?.id);
  }, [route?.params?.id])

  useEffect(() => {
    if (data?.feedPhoto1) {
      Image.getSize(data?.feedPhoto1, (width, height) => {
        setImageSize({ width, height });
      })
    }

  }, [data?.feedPhoto1])

  const onDelete = async (id) => {
    const delAction = async () => {
      await globalState.client.service('feeds').remove(id);
      dispatch({ type: 'showToast', payload: { text: 'ลบข้อมูลเรียบร้อย' } });
      EventEmitter.notify('ViewFeedNoti:refresh');
      navigation.goBack(3);
    }
    if (Platform.OS == 'web' && window.confirm('คุณต้องการลบการแจ้งเตือนนี้ ?')) {
      delAction()
    } else {
      Alert.alert('ลบการแจ้งเตือน', 'คุณต้องการลบการแจ้งเตือนนี้ ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => delAction() },
        ]
      );
    }
  }

  return (
    <SafeAreaView style={{ paddingVertical: 15, marginHorizontal: 20, flex: 1 }}>
      <ScrollView>
        <View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>วันและเวลาที่ส่ง : </Text>
            <Text style={{ fontSize: 12 }}>{moment(data?.publicDateTime).format('DD-MM-YYYY HH:mm')}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>ประเภทการส่ง : </Text>
            <Text style={{ fontSize: 12, flex: 1, flexWrap: 'wrap' }}>{
              [
                { id: 1, title: 'ส่งการแจ้งเตือนเข้ากระดิ่ง (สำหรับผู้ใช้งานที่ยอมรับข่าวสารและโปรโมชัน)', value: 'PromoMessage' },
                { id: 2, title: 'ส่งการแจ้งเตือนเข้ากระดิ่ง (สำหรับผู้ใช้งานทุกคน)', value: 'Message' },
                { id: 3, title: 'ส่งการแจ้งเตือนแบบ Pop Up Banner (สำหรับผู้ใช้งานที่ยอมรับข่าวสารและโปรโมชัน)', value: 'AlertImageFull' }
              ].find(item2 => item2.value == data?.feedType)?.title}
            </Text>
          </View>
        </View>

        <View style={{ flex: 1, borderBottomWidth: 1, borderColor: themeColor.color12, marginVertical: 10 }} />

        <View style={{ flex: 1, alignItems: 'center', marginTop: 10, marginBottom: 80 }}>
          {data?.title && (
            <Text style={{ marginBottom: 10, fontSize: 16, color: themeColor.color1, fontWeight: 'bold' }}>{data?.title}</Text>
          )}
          {imageSize?.width && data?.feedPhoto1 && (
            <TouchableOpacity onPress={() => data?.feedLink ? Linking.openURL(data?.feedLink) : null} >
              <Image
                source={{ uri: data?.feedPhoto1 }}
                style={{
                  width: (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8,
                  height: imageSize.height / imageSize.width * ((Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8),
                  marginBottom: 10,
                }}
                resizeMode="contain"
              />
            </TouchableOpacity>
          )}
          <View style={{ paddingHorizontal: 10 }}>
            <ParsedText
              parse={[{ type: 'url', style: styles.url, onPress: handleUrlPress }]}
              style={{ textAlign: 'center' }}
            >
              {data?.content}
            </ParsedText>
          </View>
          <TouchableOpacity style={{ marginTop: 20, alignSelf: 'center', width: '45%' }} onPress={() => onDelete(route?.params?.id)} >
            <View style={{
              height: 40, borderRadius: 10,
              justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color6
            }}>
              <Text style={{ color: 'white' }}>ลบ</Text>
            </View>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

function handleUrlPress(url, matchIndex) {
  Linking.openURL(url)
}

const styles = StyleSheet.create({
  url: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
});
