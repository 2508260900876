import React, { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator, Alert, TouchableWithoutFeedback, Keyboard, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { uploadPhoto, uploadFile, CustomIcon } from '../component/custom';
import EventEmitter from '../sharewebapp/EventEmitter';
import { CustomVideo } from '../component/custom';

export function EditFaqPart({ navigation, route }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState({ category: '01', img: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ at: '', message: '' });

  const FIELD_LIST = [
    {
      id: 1, type: 'text', title: 'หัวข้อ', titleStyle: { marginTop: 10 }, fieldName: 'title', value: data?.title, textInputStyle: { height: 40, marginTop: 5 }
    },
    {
      id: 2, type: 'text', title: 'เนื้อหา', titleStyle: { marginTop: 10 }, fieldName: 'text', value: data?.text, textInputProp: { multiline: true }, textInputStyle: { height: 120, marginTop: 5, padding: 5, textAlignVertical: "top" }
    },
    {
      id: 3, type: 'text', title: 'Keyword', titleStyle: { marginTop: 10 }, fieldName: 'keyword', value: data?.keyword, textInputProp: { multiline: true }, textInputStyle: { height: 60, marginTop: 5, padding: 5, textAlignVertical: "top" }
    },
    {
      id: 4, type: 'category', title: 'กลุ่มหัวข้อ', titleStyle: { marginTop: 10 }, fieldName: 'category', value: data?.category
    },
    {
      id: 5, type: 'image', title: 'ไฟล์แนบ (รูปภาพ)', titleStyle: { marginTop: 10 }, fieldName: 'img', value: data?.img
    },
    {
      id: 6, type: 'video', title: 'ไฟล์แนบ (วิดีโอ)', titleStyle: { marginTop: 10 }, fieldName: 'video', value: data?.video
    },
    {
      id: 7, type: 'pdf', title: 'ไฟล์แนบ (pdf)', titleStyle: { marginTop: 10 }, fieldName: 'pdf', value: data?.pdf
    },
  ]

  useEffect(() => {
    if (route?.params?.id > 0) {
      loadData();
    }
  }, [route?.params?.id])

  const loadData = async () => {
    let query = {
      id: route?.params?.id,
      $select: ['id', 'title', 'text', 'category', 'keyword', 'img', 'video', 'pdf']
    };
    const res = await globalState.client.service('faqs').find({ query });
    console.log('\nFAQ : ', route?.params?.id, res);
    if (res.total == 0) {
      dispatch({ type: 'showToast', payload: { text: 'ไม่มีข้อมูล FAQ นี้' }, });
      navigation.goBack();
    }
    setData(res.data[0]);
  }

  const onDelete = async (id) => {
    const delAction = async () => {
      await globalState.client.service('faqs').remove(id);
      dispatch({ type: 'showToast', payload: { text: 'ลบข้อมูลเรียบร้อย' } });
      EventEmitter.notify('Faq:refresh');
      navigation.goBack(2);
    }
    if (Platform.OS == 'web' && window.confirm('คุณต้องการลบ FAQ หัวข้อนี้ ?')) {
      delAction()
    } else {
      return Alert.alert('ลบ FAQ', 'คุณต้องการลบ FAQ หัวข้อนี้ ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => delAction() },
        ]
      );
    }
  }

  const validateForm = () => {
    const blankField = ['title', 'text'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    return true;
  }

  const onSave = async (id) => {
    setError({ at: '', message: '' });
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    let res;
    if (id == 0) {
      res = await globalState.client.service('faqs').create({
        title: data?.title,
        text: data?.text,
        img: data?.img,
        category: data?.category,
        keyword: data?.keyword,
        video: data?.video,
        pdf: data?.pdf,
      });
    } else {
      res = await globalState.client.service('faqs').patch(id, {
        title: data?.title,
        text: data?.text,
        img: data?.img,
        category: data?.category,
        keyword: data?.keyword,
        video: data?.video,
        pdf: data?.pdf,
      });
    }
    setLoading(false);
    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลเรียบร้อย' } });
    EventEmitter.notify('Faq:refresh');
    navigation.goBack();
  }

  const uploadFaqPhoto = async (targetFiles) => {
    let images;
    setLoading(true);
    try {
      images = await uploadPhoto('faq', true, 'photolib', targetFiles);
    } catch (e) {
      alert('มีปัญหาในการอัพโหลดไฟล์');
    }
    setLoading(false);

    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }
    console.log('faq_image : ', images);

    let tmpImg = data?.img ?? [];
    for (const item of images) {
      const tmpPath = { filename: `${server_var.server_api}/images/faq/${item.filename}` };
      if (item.filename.split('.').pop().toLowerCase() != 'mp4') {
        tmpImg.push(tmpPath);
      }
    }
    setData(prev => ({ ...prev, img: JSON.parse(JSON.stringify(tmpImg)) }));
  }

  const uploadFaqVideo = async (targetFiles) => {
    let images;
    setLoading(true);
    try {
      images = await uploadPhoto('faq', false, 'photolib', targetFiles);
    } catch (e) {
      alert('มีปัญหาในการอัพโหลดไฟล์');
    }
    setLoading(false);

    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }
    console.log('faq_video : ', images);

    let tmpPath = '';
    for (const item of images) {
      if (item.filename.split('.').pop().toLowerCase() == 'mp4') {
        tmpPath = `${server_var.server_api}/images/faq/${item.filename}`;
      }
    }
    if (tmpPath.length == 0) {
      alert('กรุณาเลือกไฟล์วิดีโอ mp4 เท่านั้น!');
      return;
    } else {
      setData(prev => ({ ...prev, video: tmpPath }));
    }
  }

  const uploadFaqPdf = async (targetFiles) => {
    let res;
    setLoading(true);
    try {
      res = await uploadFile('faq', targetFiles);
    } catch (e) {
      alert('มีปัญหาในการอัพโหลดไฟล์');
    }
    setLoading(false);

    if (!res) return;
    if (res?.originalFileName && res?.newFileName) {
      let tmpPath = '';
      if (res.originalFileName.split('.').pop().toLowerCase() == 'pdf') {
        tmpPath = `${server_var.server_api}/images/faq/${res.newFileName}`;
      }
      if (tmpPath.length == 0) {
        alert('กรุณาเลือกไฟล์ pdf เท่านั้น!');
      } else {
        setData(prev => ({ ...prev, pdf: tmpPath }));
      }
    } else {
      alert('มีปัญหาในการอัพโหลดไฟล์');
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5, }}>
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <ScrollView contentContainerStyle={[
        { paddingBottom: 260, paddingHorizontal: 20 }, Platform.OS == 'web' ? { height: '80vh' } : null
      ]}>
        {
          FIELD_LIST.map(item =>
            <CustomItem
              key={item.id.toString()} item={item} setData={setData} setLoading={setLoading}
              uploadFaqPhoto={uploadFaqPhoto} uploadFaqVideo={uploadFaqVideo}
              uploadFaqPdf={uploadFaqPdf} error={error} navigation={navigation}
            />
          )}

        <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 10, flex: 1 }}>
          {(route?.params?.id != 0) &&
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onDelete(route?.params?.id)} >
              <View style={{
                height: 40, borderRadius: 10,
                justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color6
              }}>
                <Text style={{ color: 'white' }}>ลบ</Text>
              </View>
            </TouchableOpacity>
          }
          <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onSave(route?.params?.id)} >
            <View style={{
              height: 40, borderRadius: 10,
              justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color1
            }}>
              <Text style={{ color: 'white' }}>บันทึก</Text>
            </View>
          </TouchableOpacity>
        </View>

        {loading &&
          <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size='large' />
          </View>
        }
      </ScrollView>
      {/* </TouchableWithoutFeedback> */}
    </SafeAreaView>
  )
}

function CustomItem({ item, setData, uploadFaqPhoto, uploadFaqVideo, uploadFaqPdf, error, navigation }) {
  if (item.type == 'text') {
    return <CustomText item={item} setData={setData} error={error} />
  } else if (item.type == 'category') {
    return <Category item={item} setData={setData} error={error} />
  } else if (item.type == 'image') {
    return <FaqImage item={item} setData={setData} uploadFaqPhoto={uploadFaqPhoto} error={error} navigation={navigation} />
  } else if (item.type == 'video') {
    return <FaqVideo item={item} setData={setData} uploadFaqVideo={uploadFaqVideo} error={error} />
  } else if (item.type == 'pdf') {
    return <FaqPdf item={item} setData={setData} uploadFaqPdf={uploadFaqPdf} error={error} />
  }
}

function Category({ item, setData, error }) {
  const { globalState, dispatch } = useContext(store);
  const [catList, setCatList] = useState();

  useEffect(() => {
    const loadCatList = async () => {
      const res = await globalState.client.service('cats').find({ query: { catType: 'faq' } });
      let res2 = res.data.filter(cat => cat.catAltId !== '00');
      setCatList(res2);
    }
    loadCatList();
  }, [])

  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <ScrollView horizontal style={{ marginVertical: 10 }}>
        {catList?.map(item2 => (
          <View key={item2.id.toString()} style={{ marginTop: 5, marginBottom: 5 }}>
            <TouchableOpacity
              style={{ padding: 10, borderRadius: 10, backgroundColor: item.value == item2.catAltId ? themeColor.color1 : 'grey', marginRight: 10 }}
              onPress={() => {
                setData(prev => ({ ...prev, [item.fieldName]: item2.catAltId }))
              }}>
              <Text style={{ color: item.value == item2.catAltId ? 'white' : 'lightgrey' }}>{item2.catName}</Text>
            </TouchableOpacity>
          </View>
        ))}
      </ScrollView>
    </View>
  )
}

function FaqImage({ item, setData, uploadFaqPhoto, error, navigation }) {
  const fileRef1 = useRef()
  if (item?.value?.length > 0) {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginVertical: 10, alignItems: "center", }} >
          {item?.value?.map((item2, index) => (
            <View key={index.toString()} style={{ padding: 10 }} >
              <TouchableOpacity onPress={() => navigation.navigate('ViewImage1', { uri: item2?.filename })}>
                <Image style={{ width: 120, height: 120 }}
                  source={{ uri: item2?.filename }}
                  resizeMode="cover"
                />
                <TouchableOpacity
                  style={{ position: "absolute", top: -5, left: -5 }}
                  onPress={() => {
                    setData(prev => ({ ...prev, [item.fieldName]: item?.value.filter(img => img.filename !== item2?.filename) }));
                  }}
                >
                  <View style={{ marginTop: -5, marginLeft: -5 }}>
                    <CustomIcon name="close-circle" size={20} color={"red"} />
                  </View>
                </TouchableOpacity>
              </TouchableOpacity>
            </View>
          ))}
        </View>
        <View>
          <TouchableOpacity style={{
            borderColor: "gray", borderWidth: 1, borderRadius: 6, width: 100, height: 100,
            margin: 10, justifyContent: "center", alignItems: "center", padding: 5
          }}
            onPress={() => {
              if (Platform.OS == 'web') {
                fileRef1.current.click();
              } else {
                uploadFaqPhoto();
              }
            }} >
            <CustomIcon name="file-image-plus" size={30} color="gray" />
            <Text style={{ color: "gray", marginTop: 5 }}>เพิ่มรูปภาพ</Text>
          </TouchableOpacity>
          {
            Platform.OS == 'web' && (
              <div style={{ display: 'flex' }}>
                <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={e => uploadFaqPhoto(e.target.files)} multiple accept=".jpg, .png" />
              </div>
            )
          }
        </View>
      </View>
    )
  } else {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <TouchableOpacity style={{
          borderColor: "gray", borderWidth: 1, borderRadius: 6, width: 100, height: 100,
          margin: 10, justifyContent: "center", alignItems: "center", padding: 5
        }}
          onPress={() => {
            if (Platform.OS == 'web') {
              fileRef1.current.click();
            } else {
              uploadFaqPhoto();
            }
          }} >
          <CustomIcon name="file-image-plus" size={30} color="gray" />
          <Text style={{ color: "gray", marginTop: 5 }}>เพิ่มรูปภาพ</Text>
        </TouchableOpacity>
        {
          Platform.OS == 'web' && (
            <div style={{ display: 'flex' }}>
              <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={e => uploadFaqPhoto(e.target.files)} multiple accept=".jpg, .png" />
            </div>
          )
        }
      </View>
    )
  }
}

function FaqVideo({ item, setData, uploadFaqVideo, error }) {
  const fileRef1 = useRef()
  const videoRef = useRef()
  const videoWidth = 250;
  const [videoHeight, setVideoHeight] = useState(videoWidth);
  const updateVideoRatioOnDisplay = (videoDetails) => {
    const { width, height } = videoDetails.naturalSize;
    setVideoHeight(videoWidth / width * height);
  }

  if (item?.value) {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <View style={{ flexDirection: 'row', marginVertical: 10 }} >
          {(Platform.OS == 'web') &&
            <video width={Dimensions.get('window').width / 6} height={Dimensions.get('window').width / 6 * 3 / 4} controls>
              <source src={item?.value} type="video/mp4" />
            </video>
          }
          <CustomVideo
            ref={videoRef}
            style={{ width: videoWidth, height: videoHeight }}
            source={{ uri: item?.value }}
            useNativeControls
            resizeMode="contain"
            isLooping={false}
            onReadyForDisplay={updateVideoRatioOnDisplay}
          />
          <TouchableOpacity
            style={{ position: "absolute", top: -5, left: -5 }}
            onPress={() => {
              setData(prev => ({ ...prev, [item.fieldName]: null }))
            }}
          >
            <View style={{ marginTop: -5, marginLeft: -5 }}>
              <CustomIcon name="close-circle" size={20} color={"red"} />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    )
  } else {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <TouchableOpacity style={{
          borderColor: "gray", borderWidth: 1, borderRadius: 6, width: 100, height: 100,
          margin: 10, justifyContent: "center", alignItems: "center", padding: 5
        }}
          onPress={() => {
            if (Platform.OS == 'web') {
              fileRef1.current.click();
            } else {
              uploadFaqVideo();
            }
          }} >
          <CustomIcon name="video-plus" size={35} color="gray" />
          <Text style={{ color: "gray", marginTop: 5 }}>เพิ่มวิดีโอ</Text>
        </TouchableOpacity>
        {
          Platform.OS == 'web' && (
            <div style={{ display: 'flex' }}>
              <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={e => uploadFaqVideo(e.target.files)} multiple accept=".mp4" />
            </div>
          )
        }
      </View>
    )
  }
}

function FaqPdf({ item, setData, uploadFaqPdf, error }) {
  const fileRef1 = useRef()
  if (item?.value) {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <View style={{ flexDirection: 'row', marginVertical: 10 }} >
          <TouchableOpacity onPress={() => Linking.openURL(item?.value)} >
            <Image style={{ width: 100, height: 100 }}
              source={{ uri: `${server_var.server_api}/images/pdf_file.png` }}
              resizeMode="contain"
            />
            <TouchableOpacity
              style={{ position: "absolute", top: -5, left: -5 }}
              onPress={() => {
                setData(prev => ({ ...prev, [item.fieldName]: null }))
              }}
            >
              <View style={{ marginTop: -5, marginLeft: -5 }}>
                <CustomIcon name="close-circle" size={20} color={"red"} />
              </View>
            </TouchableOpacity>
          </TouchableOpacity>
        </View>
      </View>
    )
  } else {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <TouchableOpacity style={{
          borderColor: "gray", borderWidth: 1, borderRadius: 6, width: 100, height: 100,
          margin: 10, justifyContent: "center", alignItems: "center", padding: 5
        }}
          onPress={() => {
            if (Platform.OS == 'web') {
              fileRef1.current.click();
            } else {
              uploadFaqPdf();
            }
          }} >
          <CustomIcon name="note-plus" size={30} color="gray" />
          <Text style={{ color: "gray", marginTop: 5 }}>เพิ่ม PDF</Text>
        </TouchableOpacity>
        {
          Platform.OS == 'web' && (
            <div style={{ display: 'flex' }}>
              <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={e => uploadFaqPdf(e.target.files)} multiple accept=".pdf" />
            </div>
          )
        }
      </View>
    )
  }
}

function CustomText({ item, setData, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <TextInput
        {...item.textInputProp}
        value={item.value}
        onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
        style={[item.textInputStyle, { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' }, error?.at == item.fieldName && { borderColor: 'red' }]}
      />
      {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
    </View>
  )
}
