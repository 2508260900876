import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "./store";
import { ProductList } from './basketpart';
import { Dialog } from './pharmanotepart';
import { ShopList } from './viewproductpart'
import { getJwtToken } from '../component/custom';

export function ManageLabelPart({ navigation, route }) {
  const { globalState } = useContext(store);
  const [dialog, setDialog] = useState({ visible: false, catId: null });
  const [selectedShopId, setSelectedShopId] = useState();

  const printLabel = async (shopId) => {
    setDialog(prev => ({ ...prev, visible: false }));
    const jwtToken = await getJwtToken();
    const res = await fetch(`${server_var.server_api}/genPdfBlankDrugLabel`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
      body: JSON.stringify({ shopId })
    });
    const resJson = await res.json();
    Linking.openURL(`${server_var.server_api}/label/${resJson.result}`)
  }

  const goToShopList = async () => {
    const res = await globalState.client.service('shopusers').find({
      query: {
        userId: globalState.user.id,
        '$shop.shopType$': 'general1',
      }
    });
    if (res.total == 0) {
      alert('คุณต้องถูกกำหนดอยู่ในร้านอย่างน้อย 1 ร้าน จึงจะพิมพ์ฉลากยาได้')
    } else if (res.total == 1) {
      printLabel(res.data[0].shopId)
    } else {
      setDialog(prev => ({ ...prev, visible: !prev.visible, type: 'shoplist', shoplist: res.data }));
    }
  }

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          style={{ marginRight: 15 }}
          onPress={() => goToShopList()}
        >
          <View style={{ paddingHorizontal: 10, paddingVertical: 5, borderRadius: 5, backgroundColor: themeColor.color1 }}>
            <Text style={{ color: 'white', fontSize: 12 }}>พิมพ์ฉลากยาเปล่า</Text>
          </View>
        </TouchableOpacity>
      ),
    }, route?.custom?.index)
  }, [selectedShopId])

  const onBackDialog = (id) => {
    setSelectedShopId(id);
    console.log('hello');
  }

  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const dialogProp = { dialog, onBackDialog, setDialog, dialogWidth };
  const dialogContent = [
    {
      type: 'shoplist',
      title: 'เลือกร้าน',
      com: <ShopList {...dialogProp} nextAction={(id) => printLabel(id)} />,
      actionList: [
        {
          id: 1, title: 'ยืนยันเลือกร้านที่จะพิมพ์', touchStyle: { flex: 0.8 }, onPress: () => {
            printLabel(selectedShopId)
          }
        },
      ]
    },
  ];

  const onPressViewProduct = (productId) => {
    navigation.navigate('ViewProduct', { productId, hideButton: true, printLabelFlag: true })
  }

  return (
    <View style={{ flex: 1 }}>
      <ProductList hideListButton={true} onPressViewProduct={onPressViewProduct} />
      <Dialog dialog={dialog} setDialog={setDialog} onBackDialog={onBackDialog} dialogWidth={dialogWidth} dialogContent={dialogContent} />
    </View>
  )
}
