import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import { CustomIcon } from '../component/custom';
import EventEmitter from '../sharewebapp/EventEmitter';
import moment from 'moment';

export function ManageContentPage({ navigation }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [catId, setCatId] = useState('00');
  const [catList, setCatList] = useState();

  useEffect(() => {
    EventEmitter.addListener('ManageContent:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('ManageContent:refresh', handleRefresh);
    }
  }, [searchText])

  const handleRefresh = () => {
    loadDataList(0, '00');
    setCatId('00');
  }

  useEffect(() => {
    const loadCatList = async () => {
      const res = await globalState.client.service('cats').find({ query: { catType: 'article' } });
      setCatList(res.data);
    }
    setCatId('00');
    loadCatList();
  }, [])

  useEffect(() => {
    loadDataList(0, catId);
  }, [debouncedSearchTerm])

  const onEdit = (feedId) => {
    navigation.navigate('EditContent', { feedId })
  }

  const loadDataList = async (pSkip, pCatId) => {
    console.log('point1')
    if (!loading) {
      setLoading(true);
      console.log('point2')
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query = { $select: ['id', 'title', 'sourceId', 'feedPhoto1', 'createdAt'], $skip: pSkip ?? skip, $sort: { updatedAt: -1 }, $limit: 20, feedType: 'article' };
      if (searchArray.length > 0) {
        const tmpSearch = searchArray.join('%');
        query = {
          ...query,
          title: { $like: `%${tmpSearch}%` },
        };
      }
      if (pCatId != '00') {
        query = {
          ...query,
          sourceId: pCatId,
        };
      }
      console.log('point3')
      let res;
      try {
        res = await globalState.client.service('feeds').find({ query });
      } catch { }

      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      console.log('point4')
      setLoading(false);
    }
  }

  return (
    //<SafeAreaView style={{ flex: 1 }}>
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', marginHorizontal: 20 }}>
        <View style={{ flex: 0.3, paddingBottom: 10, paddingTop: 5 }}>
          <TouchableOpacity style={{
            height: 40, width: "100%", backgroundColor: themeColor.color1,
            borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginTop: 10
          }} onPress={() => navigation.navigate('EditContent', { feedId: 0 })}>
            <Text style={{ color: 'white' }}><CustomIcon name="plus-circle-outline" size={16} color='white' /> สร้าง</Text>
          </TouchableOpacity>
        </View>
        <View style={{ flex: 0.7, paddingBottom: 10, paddingTop: 5, marginTop: 5, marginLeft: 10 }}>
          <TextInput
            value={searchText}
            onChangeText={text => setSearchText(text)}
            placeholder={'ค้นหาจากชื่อบทความ'}
            style={{ alignSelf: 'center', width: "100%", borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40, paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }}
          />
        </View>
      </View>
      <View style={{ marginTop: 5, marginBottom: 5 }}>
        <ScrollView horizontal style={{ paddingHorizontal: 20 }}>
          {catList?.map(item => (
            <View key={item.id.toString()} style={{ marginTop: 5, marginBottom: 5 }}>
              <TouchableOpacity
                style={{ padding: 10, borderRadius: 10, backgroundColor: catId == item.catAltId ? themeColor.color1 : 'lightgray', marginRight: 10 }}
                onPress={() => {
                  loadDataList(0, item.catAltId);
                  setCatId(item.catAltId);
                }}
              >
                <Text style={{ color: catId == item.catAltId ? 'white' : 'black' }}>{item.catName.replace(' ', '')}</Text>
              </TouchableOpacity>
            </View>
          ))}
        </ScrollView>
      </View>

      <FlatList
        keyExtractor={(item) => item.id.toString()}
        style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: '60vh' } : null]}
        data={dataList}
        onEndReached={(xxx) => loadDataList(null, catId)}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadDataList(0, catId)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item, index }) => (
          <ContentItem catList={catList} item={item} onEdit={onEdit} />
        )}
      />
    </View>
    //</SafeAreaView>
  )
}

function ContentItem({ catList, item, onEdit }) {
  return (
    <TouchableOpacity onPress={() => onEdit(item.id)} >
      <View style={{
        flexDirection: 'row', alignItems: 'center', height: 100,
        borderRadius: 10, backgroundColor: 'white', paddingHorizontal: 10, paddingVertical: 5,
        marginVertical: 5, marginHorizontal: 20
      }}>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <View style={{ flex: 0.3 }}>
            <Image style={{ width: 80, height: 80 }}
              source={item?.feedPhoto1 ? { uri: item?.feedPhoto1 } : require('../assets/images/HealthTips.png')}
              resizeMode="cover"
            />
          </View>
          <View style={{ flex: 0.7 }}>
            <View style={{ flexDirection: 'column', flex: 1, justifyContent: 'space-around' }}>
              <Text style={{ fontSize: 10, color: 'gray' }}>{catList?.find(item2 => (item2.catAltId == item?.sourceId))?.catName}</Text>
              <Text style={{ fontWeight: 'bold', flexWrap: 'wrap' }}>{item?.title}</Text>
              <Text style={{ fontSize: 10, color: 'gray' }}>{moment(item?.createdAt).format('DD/MM/YYYY')}</Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity >
  )
}
