import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';

import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { useDebounce } from '../sharewebapp/common1';
import EventEmitter from '../sharewebapp/EventEmitter';
import { CustomIcon } from '../component/custom';

export function FaqPart({ navigation, route }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [catId, setCatId] = useState('00');
  const [catList, setCatList] = useState();
  const forAdmin = route?.params?.forAdmin;
  const [adminType, setAdminType] = useState();

  useEffect(() => {
    const loadUserRole = async () => {
      const res = await globalState.client.service('users').get(globalState?.user?.id, { query: { $select: ['roles'] } });
      setAdminType(res?.roles);
    }
    if (globalState.user) {
      loadUserRole();
    }
  }, [])

  useEffect(() => {
    const loadCatList = async () => {
      const res = await globalState.client.service('cats').find({ query: { catType: 'faq' } });
      setCatList(res.data);
    }
    loadCatList();
  }, [])

  useEffect(() => {
    loadDataList(0, catId);
  }, [debouncedSearchTerm])

  useEffect(() => {
    EventEmitter.addListener('Faq:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('Faq:refresh', handleRefresh);
    }
  }, [searchText])

  const handleRefresh = () => {
    setCatId('00');
    loadDataList(0, '00');
  }

  const onView = (id) => {
    if (forAdmin && ['admin', 'rapd'].includes(adminType)) {
      navigation.navigate('EditFaq', { id });
    } else {
      navigation.navigate('ViewFaq', { id });
    }
  }

  const loadDataList = async (pSkip, pCatId) => {
    if (!loading) {
      setLoading(true);
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query = { $skip: pSkip ?? skip, $sort: { title: 1 }, $limit: 20 };
      if (pCatId != '00') {
        query = {
          ...query,
          category: pCatId
        };
      }
      if (searchArray.length > 0) {
        const tmp1 = searchArray.join('%');
        query = {
          ...query,
          $or: [
            { title: { $like: `%${tmp1}%` } },
            { keyword: { $like: `%${tmp1}%` } },
          ]
        };
      }

      const res = await globalState.client.service('faqs').find({ query });
      if ((pSkip ?? skip) === 0) {
        setDataList(res.data);
      } else {
        setDataList(prev => [...prev, ...res.data]);
      }
      setSkip((res.skip + res.limit));
      setLoading(false);
      //console.log('\nFaqs : ', res.data);
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', marginHorizontal: 20 }}>
        {forAdmin && ['admin', 'rapd'].includes(adminType) &&
          <View style={{ flex: 0.3, paddingBottom: 5, paddingTop: 5 }}>
            <TouchableOpacity style={{
              height: 40, width: "100%", backgroundColor: themeColor.color1,
              borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginTop: 10
            }} onPress={() => navigation.navigate('EditFaq', { id: 0 })}>
              <Text style={{ color: 'white' }}><CustomIcon name="plus-circle-outline" size={16} color='white' /> สร้าง</Text>
            </TouchableOpacity>
          </View>
        }
        <View style={{ flex: (forAdmin && ['admin', 'rapd'].includes(adminType)) ? 0.7 : 1, paddingBottom: 5, paddingTop: 5, marginTop: 5, marginLeft: (forAdmin && ['admin', 'rapd'].includes(adminType)) ? 15 : 0 }}>
          <TextInput
            value={searchText}
            onChangeText={text => setSearchText(text)}
            placeholder={'ค้นหาข้อมูล'}
            style={{ alignSelf: 'center', width: "100%", borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40, paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }}
          />
        </View>
      </View>
      <View style={{ marginTop: 5, marginBottom: 5 }}>
        <ScrollView horizontal style={{ paddingHorizontal: 20 }}>
          {catList?.map(item => (
            <View key={item.id.toString()} style={{ marginTop: 5, marginBottom: 5 }}>
              <TouchableOpacity
                style={{ padding: 10, borderRadius: 10, backgroundColor: catId == item.catAltId ? themeColor.color1 : 'lightgray', marginRight: 10 }}
                onPress={() => {
                  setCatId(item.catAltId);
                  loadDataList(0, item.catAltId);
                }}
              >
                <Text style={{ color: catId == item.catAltId ? 'white' : 'black' }}>{item.catName}</Text>
              </TouchableOpacity>
            </View>
          ))}
        </ScrollView>
      </View>
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onEndReached={(item) => loadDataList(null, catId)}
        onEndReachedThreshold={0.5}
        onRefresh={(item) => loadDataList(0, catId)}
        refreshing={(loading && skip == 0)}
        style={[{ marginTop: 0 }, Platform.OS == 'web' ? { height: '70vh' } : null]}
        renderItem={({ item }) => (
          <FaqtItem item={item} onView={onView} />
        )}
      />
    </SafeAreaView>
  )
}

function FaqtItem({ item, onView }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5, marginHorizontal: 20, }}
      onPress={() => onView(item.id)}
    >
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1 }}>
        <View style={{ paddingBottom: 10, flex: 1 }}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 5 }}>
            <Text style={{ color: themeColor.color1, fontSize: 14, fontWeight: 'bold', flex: 1, flexWrap: 'wrap' }}>{item?.title}</Text>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingTop: 5 }}>
            <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item?.text ? (item?.text?.substr(0, 100) + '...') : ''}</Text>
            <CustomIcon name="chevron-right-circle" size={20} color="gray" style={{ paddingHorizontal: 10 }} />
          </View>
        </View>
      </View>
    </TouchableOpacity >
  )
}
