import React, { useEffect, useCallback, useState, useContext, useRef, lazy, Suspense } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator, Alert, TouchableWithoutFeedback, Keyboard, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from '../sharewebapp/store';
import EventEmitter from '../sharewebapp/EventEmitter';
import { CustomIcon, uploadPhoto, CustomDatePicker5 } from '../component/custom';
import moment from 'moment';

export function EditCouponPage({ navigation, route }) {
  const couponId = route?.params?.couponId;
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ at: '', message: '' });
  const [fieldList, setFieldList] = useState([]);

  useEffect(() => {
    loadCoupon();
  }, [couponId])

  useEffect(() => {
    const FIELD_LIST = [
      {
        id: 10, type: 'text', title: 'ID', titleStyle: { marginTop: 10 }, fieldName: 'id', value: data?.id, visible: true, textInputProp: { editable: false }, textInputStyle: { height: 40, marginTop: 5, backgroundColor: 'lightgray' }
      },
      {
        id: 20, type: 'text', title: 'ชื่อคูปอง (ภาษาไทย)', titleStyle: { marginTop: 10 }, fieldName: 'title', value: data?.title, visible: true, textInputStyle: { height: 40, marginTop: 5 }
      },
      {
        id: 30, type: 'checkbox', title: 'กลุ่มลูกค้า (Target)', visible: true,
        list: [{ title: 'ALL Customer', value: 'allcust' }, { title: 'ALL Member', value: 'allmem' }, { title: 'พนักงาน CPALL', value: 'cpstaff' }],
        titleStyle: { marginTop: 30 }, fieldName: 'target', value: data?.target, textInputStyle: { height: 40, marginTop: 5 }
      },
      {
        id: 40, type: 'date', title: 'วันที่เริ่มต้น', titleStyle: { marginTop: 30 }, fieldName: 'startDate', value: data?.startDate, visible: true
      },
      {
        id: 50, type: 'date', title: 'วันที่สิ้นสุด', titleStyle: { marginTop: 10 }, fieldName: 'endDate', value: data?.endDate, visible: true
      },
      {
        id: 60, type: 'radio', title: 'ประเภทส่วนลด', visible: true,
        list: [{ title: 'คูปอง', value: 'coupon1' }, { title: 'โปรโมชั่นโค้ด', value: 'promo1' }],
        titleStyle: { marginTop: 30 }, fieldName: 'couponType', value: data?.couponType, textInputStyle: { height: 40, marginTop: 5 }
      },
      {
        id: 70, type: 'text', title: 'Promotion Code', visible: data?.couponType == 'promo1', titleStyle: { marginTop: 10, marginLeft: 20 }, fieldName: 'promoCode', value: data?.promoCode, textInputStyle: { height: 40, marginTop: 5, marginLeft: 20 }
      },
      {
        id: 80, type: 'radio', title: 'หมวดหมู่การแสดงผลคูปอง', visible: true,
        list: [{ title: 'คูปองสำหรับค่าสินค้า', value: 'product' }, { title: 'คูปองสำหรับค่าบริการ', value: 'service' }, { title: 'คูปองส่วนลดท้ายบิล', value: 'end_of_bill' }],
        titleStyle: { marginTop: 30 }, fieldName: 'couponSubType', value: data?.couponSubType, textInputStyle: { height: 40, marginTop: 5 }
      },
      {
        id: 90, type: 'text', title: 'รหัสบาร์โค้ด 13 หลัก', visible: true, titleStyle: { marginTop: 30 }, fieldName: 'barcodeId1', value: data?.barcodeId1, textInputStyle: { height: 40, marginTop: 5 }
      },
      {
        id: 100, type: 'radio', title: 'ประเภทคูปอง', visible: true,
        list: [{ title: 'Mass Coupon', value: null }, { title: 'Unique Coupon', value: 'unique' }],
        titleStyle: { marginTop: 30 }, fieldName: 'couponMethod', value: data?.couponMethod, textInputStyle: { height: 40, marginTop: 5 }
      },
      {
        id: 110, type: 'text', title: 'Promotion Code', visible: data?.couponMethod == 'unique', titleStyle: { marginTop: 10, marginLeft: 20 }, fieldName: 'promotionCode1', value: data?.promotionCode1, textInputStyle: { height: 40, marginTop: 5, marginLeft: 20 }
      },
      {
        id: 120, type: 'text', title: 'Project Code', visible: data?.couponMethod == 'unique', titleStyle: { marginTop: 10, marginLeft: 20 }, fieldName: 'projectCode1', value: data?.promotionCode1, textInputStyle: { height: 40, marginTop: 5, marginLeft: 20 }
      },
      {
        id: 125, type: 'text', title: 'จำนวนคูปองที่แจก/คน', visible: data?.couponMethod == 'unique', titleStyle: { marginTop: 10, marginLeft: 20 }, fieldName: 'couponAmount', value: data?.couponAmount, textInputStyle: { height: 40, marginTop: 5, marginLeft: 20 }
      },
      {
        id: 130, type: 'text', title: 'จำกัดสิทธิ์/คน/วัน', visible: true, titleStyle: { marginTop: 30 }, fieldName: 'limitPerPersonPerDay', value: data?.limitPerPersonPerDay, textInputStyle: { height: 40, marginTop: 5 }
      },
      {
        id: 140, type: 'text', title: 'จำกัดสิทธิ์/คน/โครงการ', visible: true, titleStyle: { marginTop: 10 }, fieldName: 'limitPerPerson', value: data?.limitPerPerson, textInputStyle: { height: 40, marginTop: 5 }
      },
      {
        id: 150, type: 'text', title: 'จำกัดสิทธิ์ทั้งหมด/วัน', visible: true, titleStyle: { marginTop: 10 }, fieldName: 'limitPerDay', value: data?.limitPerDay, textInputStyle: { height: 40, marginTop: 5 }
      },
      {
        id: 160, type: 'text', title: 'จำกัดสิทธิ์ทั้งหมด/โครงการ', visible: true, titleStyle: { marginTop: 10 }, fieldName: 'limitProject', value: data?.limitProject, textInputStyle: { height: 40, marginTop: 5 }
      },
      {
        id: 162, type: 'option', title: 'เงื่อนไข', visible: true,
        list: [
          { title: 'เฉพาะการสั่งซื้อครั้งแรกเท่านั้น', checkedValue: 'onlyForNewPurchase', fieldName: 'couponSpecial1', value: data?.couponSpecial1 },
          { title: 'ไม่สามารถใช้ร่วมกับส่วนลดท้ายบิลอื่นได้', checkedValue: 'discountEndBill', fieldName: 'couponSpecial2', value: data?.couponSpecial2 },
        ],
        titleStyle: { marginTop: 30 }, textInputStyle: { height: 40, marginTop: 5 }
      },

      {
        id: 165, type: 'radio', title: 'สถานะ',
        list: [{ title: 'ใช้งาน', value: 'active' }, { title: 'เลิกใช้งาน', value: 'disable' }],
        visible: true, titleStyle: { marginTop: 30 }, fieldName: 'couponStatus', value: data?.couponStatus, textInputStyle: { height: 40, marginTop: 5 }
      },
      {
        id: 170, type: 'image', title: 'รูปคูปองแบบ Ticket (อัตราส่วน 2.4:1)', visible: true, titleStyle: { marginTop: 30 }, fieldName: 'photo1', value: data?.photo1
      },
      {
        id: 180, type: 'image', title: 'รูปคูปองแบบ Thumbnail (อัตราส่วน 1:1)', visible: true, titleStyle: { marginTop: 30 }, fieldName: 'photo2', value: data?.photo2
      },

    ]
    setFieldList(FIELD_LIST);
  }, [data])

  const loadCoupon = async () => {
    let res;
    if (couponId == 0) {
      res = {
        id: 0, barcodeId1: '', title: '', couponType: '', couponSubType: '', startDate: null, endDate: null, photo1: null, photo2: null,
        couponStatus: null, promotionCode1: null, projectCode1: null, promoCode: null, limitPerPersonPerDay: 0, limitPerPerson: 0,
        limitPerDay: 0, limitProject: 0, target: [], couponMethod: null, couponAmount: 1, couponStatus: null
      };
    } else {
      res = await globalState.client.service('coupons').get(couponId);
    }
    setData(res);
  }

  const onDelete = async (id) => {
    const delAction = async () => {
      await globalState.client.service('coupons').remove(id);
      dispatch({ type: 'showToast', payload: { text: 'ลบข้อมูลเรียบร้อย' } });
      EventEmitter.notify('ManageCoupon:refresh');
      navigation.goBack(2);
    }
    if (Platform.OS == 'web' && window.confirm('คุณต้องการลบคูปองนี้ ?')) {
      delAction()
    } else {
      return Alert.alert('ลบคูปอง', 'คุณต้องการลบคูปองนี้ ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => delAction() },
        ]
      );
    }
  }

  const validateForm = () => {
    const blankField = ['title', 'couponType', 'couponSubType', 'startDate', 'endDate', 'couponStatus', 'barcodeId1',].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    return true;
  }

  const onSave = async (id) => {
    setError({ at: '', message: '' });
    if (!validateForm()) {
      return;
    }

    if (couponId == 0) {
      await globalState.client.service('coupons').create(data)
    } else {
      const { id, ...rest } = data;
      await globalState.client.service('coupons').patch(id, rest)
    }

    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลเรียบร้อย' } });
    EventEmitter.notify('ManageCoupon:refresh');
    navigation.goBack(2);
  }

  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5, }}>
      <ScrollView contentContainerStyle={{ paddingBottom: 260, paddingHorizontal: 20, height: '80vh' }}>
        {/* <Text>{JSON.stringify(data, '', 2)}</Text> */}
        {
          fieldList?.filter(item => item.visible)?.map(item =>
            <CustomItem key={`${item.id}_${item.fieldName}`} item={item} setData={setData} error={error} />
          )}

        {loading &&
          <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size='large' />
          </View>
        }
      </ScrollView>
      <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 20, flex: 1 }}>
        {(couponId != 0) &&
          <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onDelete(couponId)} >
            <View style={{
              height: 40, borderRadius: 10,
              justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color6
            }}>
              <Text style={{ color: 'white' }}>ลบ</Text>
            </View>
          </TouchableOpacity>
        }
        <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onSave(couponId)} >
          <View style={{
            height: 40, borderRadius: 10,
            justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color1
          }}>
            <Text style={{ color: 'white' }}>บันทึก</Text>
          </View>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );

}

function CustomItem({ item, setData, error }) {
  const dialogWidth = Dimensions.get('window').width / 4;

  if (item.type == 'text') {
    return <CustomText item={item} setData={setData} error={error} />
  } else if (item.type == 'image') {
    return <CustomImage item={item} setData={setData} error={error} viewMode={false} />
  } else if (item.type == 'date') {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <CustomDatePicker5
          value={item?.value ? moment(item.value, 'YYYY-MM-DD') : null}
          onChange={(selectedDate) => {
            setData(prev => ({ ...prev, [item.fieldName]: moment(selectedDate).format('YYYY-MM-DD') }))
          }}
          dialogWidth={dialogWidth}
        />
        {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
      </View>
    )
  } else if (item.type == 'radio') {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <View style={{ flexDirection: 'row', marginTop: 10 }}>
          {item.list.map(item5 => (
            <TouchableOpacity key={item5.value} style={{ marginHorizontal: 10 }} onPress={() => setData(prev => ({ ...prev, [item.fieldName]: item5.value }))}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CustomIcon name={item.value == item5.value ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={20} color={'gray'} />
                <Text style={{ marginLeft: 3 }}>{item5.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
        {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
      </View>
    )
  } else if (item.type == 'checkbox') {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <View style={{ flexDirection: 'row', marginTop: 10 }}>
          {item.list.map(item5 => (
            <TouchableOpacity key={item5.value} style={{ marginHorizontal: 10 }} onPress={() => {
              setData(prev => {
                if (prev?.[item.fieldName]?.includes(item5.value)) {
                  console.log('prev', prev);
                  console.log('prev fieldName', prev?.[item?.fieldName]);
                  const tmp = prev?.[item.fieldName].filter(item6 => item6 != item5.value)
                  return { ...prev, [item.fieldName]: tmp }
                } else {
                  return { ...prev, [item.fieldName]: [...((prev?.[item.fieldName]) ?? []), item5.value] }
                }
              })
            }
            }>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CustomIcon name={item?.value?.includes(item5.value) ? "checkbox-marked-outline" : "checkbox-blank-outline"} size={20} color={'gray'} />
                <Text style={{ marginLeft: 3 }}>{item5.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
        {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
      </View>
    )
  } else if (item.type == 'option') {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <View style={{ flexDirection: 'column', marginTop: 10 }}>
          {item.list.map(item5 => (
            <TouchableOpacity key={item5.checkedValue} style={{ marginHorizontal: 10 }} onPress={() => {
              setData(prev => {
                if (prev?.[item5.fieldName]?.includes(item5.value)) {
                  console.log('prev', prev);
                  console.log('prev fieldName', prev?.[item5?.fieldName]);
                  return { ...prev, [item5.fieldName]: null }
                } else {
                  return { ...prev, [item5.fieldName]: item5.checkedValue }
                }
              })
            }
            }>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CustomIcon name={item5?.value?.includes(item5.checkedValue) ? "checkbox-marked-outline" : "checkbox-blank-outline"} size={20} color={'gray'} />
                <Text style={{ marginLeft: 3 }}>{item5.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    )
  }
}

function CustomText({ item, setData, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <TextInput
        {...item.textInputProp}
        value={item.value}
        onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
        style={[{ borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' }, item.textInputStyle, error?.at == item.fieldName && { borderColor: 'red' }]}
      />
      {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
    </View>
  )
}

function CustomImage({ item, setData, error, viewMode }) {
  const fileRef1 = useRef();
  const uploadPhoto1 = async (e) => {
    uploadPhoto2(e.target.files)
  }

  const uploadPhoto2 = async (targetFiles) => {
    let images;
    try {
      images = await uploadPhoto('feed', false, 'photolib', targetFiles);
    } catch (e) {
      alert('มีปัญหาในการอัพโหลดรูปภาพ');
    }
    console.log('images!!', images);
    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }
    for (const item2 of images) {
      setData(prev => ({ ...prev, [item.fieldName]: `${server_var.server_api}/images/feed/${item2.filename}` }))
    }
  }

  if (item?.value) {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <View style={{ marginVertical: 10 }}>
          <Image style={{ width: 120, height: 120 }}
            source={{ uri: item?.value }}
            resizeMode="cover"
          />
          {!viewMode &&
            <TouchableOpacity
              style={{ position: "absolute", top: -5, left: -5 }}
              onPress={() => {
                // setData(prev => ({ ...prev, photo1: null }))
                setData(prev => ({ ...prev, [item.fieldName]: null }))
              }}
            >
              <View style={{ marginTop: -5, marginLeft: -5 }}>
                <CustomIcon name="close-circle" size={20} color={"red"} />
              </View>
            </TouchableOpacity>
          }
        </View>
      </View>
    )
  } else {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <TouchableOpacity
          style={{
            borderColor: "gray", borderWidth: 1, borderRadius: 6, width: 100, height: 100,
            margin: 10, justifyContent: "center", alignItems: "center", padding: 5
          }}
          onPress={() => {
            if (!viewMode) {
              if (Platform.OS == 'web') {
                fileRef1.current.click();
              } else {
                uploadPhoto2('')
              }
            }
          }}
        >
          <CustomIcon name="plus-circle" size={20} color="gray" />
          <Text style={{ color: "gray", marginTop: 5 }}>ใส่รูปภาพ</Text>
        </TouchableOpacity>
        {
          Platform.OS == 'web' && (
            <div style={{ display: 'flex' }}>
              <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadPhoto1} multiple accept=".jpg, .jpeg, .png" />
            </div>
          )
        }
      </View>
    )
  }
}

