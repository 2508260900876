import { themeColor } from "../config/servervar";
export const antLayoutSider = {
    position: "relative",
    backgroundColor: themeColor.color1
};
export const antLayoutSiderMobile = {
    position: "fixed",
    height: "100vh",
    backgroundColor: themeColor.color1,
    zIndex: 999,
};