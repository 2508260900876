import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import moment from 'moment';
import { Dialog } from '../sharewebapp/pharmanotepart';
import { CustomIcon,CustomDatePicker5 } from '../component/custom';
import { getJwtToken } from '../component/custom';

export function ReportsPart({ navigation }) {
  const { globalState } = useContext(store);
  const [caseReport, setCaseReport] = useState({ title: null, caseAmt: 0, prescribe: 0, notPrescribe: 0, success: 0, forward: 0, cancelByCustomer: 0, cancelBySystem: 0 });
  const [orderReport, setOrderReport] = useState({ title: null, orderAmt: null, orderPrice: null, inBasket: null, customerPaid: null, delivering: null, delivered: null, completed: null, cancel: null });
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({ visible: false, catId: null });
  const [data, setData] = useState({ shopId: null, shopName: null, dateFrom: null, dateTo: null });
  
  const CASE_REPORT = [
    { id: 1, type: 'header', title1: 'จำนวนเคสการให้คำปรึกษา', value1: numberWithCommas(caseReport?.caseAmt) },
    { id: 2, type: 'text', title1: 'เคสที่ให้คำปรึกษาจนจบ', value1: numberWithCommas(caseReport?.success), title2: 'เคสที่มีการส่งต่อ', value2: numberWithCommas(caseReport?.forward) },
    { id: 3, type: 'break' },
    { id: 4, type: 'text', title1: 'เคสที่ถูกยกเลิกโดยลูกค้า', value1: numberWithCommas(caseReport?.cancelByCustomer), title2: 'เคสที่ถูกยกเลิกโดยระบบ', value2: numberWithCommas(caseReport?.cancelBySystem) },
    { id: 5, type: 'break' },
    { id: 6, type: 'textBottom', title1: 'เคสที่มีการจ่ายยา', value1: numberWithCommas(caseReport?.prescribe), title2: 'เคสที่ไม่มีการจ่ายยา', value2: numberWithCommas(caseReport?.notPrescribe) },
  ]

  const ORDER_REPORT = [
    { id: 1, type: 'header', title1: 'จำนวนคำสั่งซื้อที่สำเร็จ', value1: orderReport?.orderAmt, title2: 'ยอดรวมคำสั่งซื้อที่สำเร็จ', value2: orderReport?.orderPrice },
    { id: 2, type: 'text', title1: 'รอชำระเงิน', value1: orderReport?.inBasket, title2: 'ที่ต้องจัดส่ง', value2: orderReport?.customerPaid },
    { id: 3, type: 'break' },
    { id: 4, type: 'text', title1: 'ระหว่างนำส่ง', value1: orderReport?.delivering, title2: 'จัดส่งสำเร็จ', value2: orderReport?.delivered },
    { id: 5, type: 'break' },
    { id: 6, type: 'textBottom', title1: 'รับสินค้าสำเร็จ', value1: orderReport?.completed, title2: 'ยกเลิก', value2: orderReport?.cancel },
  ]

  useEffect(() => {
    if (data?.shopId) {
      loadCaseReport();
      loadOrderReport();
    }
  }, [data?.shopId, data?.dateFrom, data?.dateTo])

  const loadCaseReport = async () => {
    if (!loading) {
      setLoading(true);

      let query = { shopId: data?.shopId, $limit: 500 };
      let query2 = { fromShopId: data?.shopId, $limit: 500 };

      let criteriaDateFrom;
      if (data?.dateFrom) {
        criteriaDateFrom = moment(data?.dateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
      } else {
        criteriaDateFrom = moment().format('YYYY-MM-DD');
      }
      let criteriaDateTo;
      if (data?.dateTo) {
        criteriaDateTo = moment(data?.dateTo, 'DD/MM/YYYY').add(1, 'days').format('YYYY-MM-DD');
      } else {
        criteriaDateTo = moment().add(1, 'days').format('YYYY-MM-DD');
      }

      query = {
        ...query,
        $and: [
          { createdAt: { $gte: criteriaDateFrom } },
          { createdAt: { $lte: criteriaDateTo } }
        ],
      };
      // const res = await globalState.client.service('pharmanotes').find({ query });

      query2 = {
        ...query2,
        $and: [
          { createdAt: { $gte: criteriaDateFrom } },
          { createdAt: { $lte: criteriaDateTo } }
        ],
      };
      const resQueline = await globalState.client.service('oldquelines').find({ query: query2 });
      const forward = resQueline?.total;

      let res;
      let jwtToken;
      try {
        jwtToken = await getJwtToken();
      } catch (e) { }
      try {
        res = await fetch(`${server_var.server_api}/getCaseReport`, {
          method: 'POST',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
          body: JSON.stringify({ userId: globalState?.user?.id, shopId: data?.shopId, dateFrom: criteriaDateFrom, dateTo: criteriaDateTo })
        });
      } catch (e) {
        console.log('getCaseReport post error : ', e);
      }
      if (!res) return;
      const resJson = await res.json();
      //console.log(themeColor.FgYellow, 'getCaseReport resJson : ' + JSON.stringify(resJson), themeColor.FgReset);
  
      if (resJson?.result) {
        setCaseReport(resJson?.resultData);
      }
      setCaseReport(prev => ({ ...prev, forward }));

      // const prescribe = res?.data?.filter(item => (item?.psctype == 'prescribe')).length;
      // const notPrescribe = res?.data?.filter(item => (item?.psctype == 'not_prescribe')).length;
      // const success = res?.data?.filter(item => (item?.status?.includes('done'))).length;
      // const cancelByCustomer = res?.data?.filter(item => (item?.status?.includes('cancel_by_user'))).length;
      // const cancelBySystem = res?.data?.filter(item => (item?.status?.includes('cancel_by_system'))).length;
      // setCaseReport(prev => ({ ...prev, title: 'รายงานเคสการให้คำปรึกษา', caseAmt: `${res?.total} เคส`, prescribe, notPrescribe, success, forward, cancelByCustomer, cancelBySystem }));
      
      setLoading(false);
      console.log('caseReport query : ', JSON.stringify(query));
      console.log('caseReport result : ', JSON.stringify(caseReport));
    }
  }

  const loadOrderReport = async () => {
    if (!loading) {
      setLoading(true);

      let query = { shopId: data?.shopId, $limit: 500 };
      let criteriaDateFrom;
      if (data?.dateFrom) {
        criteriaDateFrom = moment(data?.dateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
      } else {
        criteriaDateFrom = moment().format('YYYY-MM-DD');
      }
      let criteriaDateTo;
      if (data?.dateTo) {
        criteriaDateTo = moment(data?.dateTo, 'DD/MM/YYYY').add(1, 'days').format('YYYY-MM-DD');
      } else {
        criteriaDateTo = moment().add(1, 'days').format('YYYY-MM-DD');
      }

      query = {
        ...query,
        $and: [
          { createdAt: { $gte: criteriaDateFrom } },
          { createdAt: { $lte: criteriaDateTo } }
        ],
      };
      const res = await globalState.client.service('ordermains').find({ query });

      const totalOrderPrice = (res?.data?.filter(item => (!item?.orderStatus?.includes('cancel'))).reduce((acc, item2) => (acc + parseFloat(item2?.orderPrice)), 0) || 0);
      const totalDiscountPrice = (res?.data?.filter(item => (!item?.orderStatus?.includes('cancel'))).reduce((acc, item2) => (acc + parseFloat(item2?.discountPrice)), 0) || 0);
      const success = res?.data?.filter(item => (!item?.orderStatus?.includes('cancel'))).length;
      const inBasket = res?.data?.filter(item => (item?.orderStatus?.includes('in_basket'))).length;
      const customerPaid = res?.data?.filter(item => (item?.orderStatus?.includes('customer_paid'))).length;
      const delivering = res?.data?.filter(item => (item?.orderStatus?.includes('exta_start_deliver') || item?.orderStatus?.includes('partner_start_deliver'))).length;
      const delivered = res?.data?.filter(item => (item?.orderStatus?.includes('exta_complete_deliver') || item?.orderStatus?.includes('partner_complete_deliver'))).length;
      const completed = res?.data?.filter(item => (item?.orderStatus?.includes('customer_got_deliver'))).length;
      const cancel = res?.data?.filter(item => (item?.orderStatus?.includes('cancel') || item?.orderStatus?.includes('order_problem'))).length;
      setOrderReport(prev => ({ ...prev, title: 'รายงานคำสั่งซื้อ', orderAmt: `${success} คำสั่งซื้อ`, orderPrice: `฿${numberWithCommas(totalOrderPrice - totalDiscountPrice)}`, inBasket, customerPaid, delivering, delivered, completed, cancel }));

      setLoading(false);
      console.log('orderReport query : ', query);
      console.log('orderReport result : ', JSON.stringify(orderReport));
    }
  }

  const onBackDialog = async (id) => {
    const resShop = await globalState.client.service('shopusers').find({
      query: { shopId: id, userId: globalState.user.id }
    });
    setData(prev => ({ ...prev, shopId: id, shopName: resShop?.data[0]?.shop?.shopThName }));
    setDialog(prev => ({ ...prev, visible: false }));
  }

  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const dialogProp = { dialog, onBackDialog, setDialog, dialogWidth };
  const dialogContent = [
    {
      type: 'shoplist',
      title: 'เลือกสาขา',
      com: <ShopList {...dialogProp} />,
    },
  ];

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ marginVertical: 10, marginHorizontal: 20, flexDirection: 'row', alignItems: 'flex-start' }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>สาขา</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: true, type: 'shoplist' }))}>
            <View style={{
              flexDirection: 'row', borderColor: 'lightgray', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
              height: 30, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white'
            }}>
              <Text style={{ fontSize: 14, color: 'gray' }}>{data?.shopName ?? ''}</Text>
              <CustomIcon name="store" size={20} color="gray" />
            </View>
          </TouchableOpacity>
        </View>
      </View>

      <View style={{ marginVertical: 10, marginHorizontal: 20, flexDirection: 'row', alignItems: 'flex-start' }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>เริ่มจาก</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <CustomDatePicker5
            value={data?.dateFrom}
            onChange={(selectedDate) => {
              setData(prev => ({ ...prev, dateFrom: moment(selectedDate).format('DD/MM/YYYY') }))
            }}
            dialogWidth={dialogWidth}
          />
        </View>
      </View>
      <View style={{ marginVertical: 10, marginHorizontal: 20, flexDirection: 'row', alignItems: 'flex-start' }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>จนถึง</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <CustomDatePicker5
            value={data?.dateTo}
            onChange={(selectedDate) => {
              setData(prev => ({ ...prev, dateTo: moment(selectedDate).format('DD/MM/YYYY') }))
            }}
            dialogWidth={dialogWidth}
          />
        </View>
      </View>

      <View style={{ marginVertical: 10, marginHorizontal: 20, flexDirection: 'row', alignItems: 'center', borderBottomWidth: 1, borderColor: themeColor.color12 }} />

      <ScrollView>
        {data?.shopId &&
          <View>
            <View style={{ marginVertical: 10, marginHorizontal: 20, flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ color: themeColor.color1, fontSize: 16, fontWeight: 'bold' }}>{caseReport?.title}</Text>
            </View>
            {CASE_REPORT.map(item => <ReportResult key={item.id.toString()} item={item} />)}
          </View>
        }
        {data?.shopId &&
          <View style={{ marginVertical: 20 }}>
            <View style={{ marginVertical: 10, marginHorizontal: 20, flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ color: themeColor.color1, fontSize: 16, fontWeight: 'bold' }}>{orderReport?.title}</Text>
            </View>
            {ORDER_REPORT.map(item => <ReportResult key={item.id.toString()} item={item} />)}
          </View>
        }
      </ScrollView>
      <Dialog dialog={dialog} setDialog={setDialog} onBackDialog={onBackDialog} dialogWidth={dialogWidth} dialogContent={dialogContent} />
    </SafeAreaView>
  )
}

function ReportResult({ item }) {
  if (item?.type == 'header') {
    return (
      <View style={{ marginHorizontal: 20, borderTopLeftRadius: 10, borderTopRightRadius: 10, backgroundColor: themeColor.color1, flexDirection: 'row' }}>
        <View style={{ flex: item?.title2 ? 0.5 : 1, paddingHorizontal: 15, paddingVertical: 15 }}>
          <View style={{ flexDirection: 'column' }}>
            <Text style={{ fontSize: 14, color: 'white', marginBottom: 5 }}>{item?.title1}</Text>
            <Text style={{ fontSize: 16, color: 'white', fontWeight: 'bold' }}>{item?.value1}</Text>
          </View>
        </View>
        {item?.title2 &&
          <View style={{ flex: 0.5, paddingHorizontal: 15, paddingVertical: 15 }}>
            <View style={{ flexDirection: 'column' }}>
              <Text style={{ fontSize: 14, color: 'white', marginBottom: 5 }}>{item?.title2}</Text>
              <Text style={{ fontSize: 16, color: 'white', fontWeight: 'bold' }}>{item?.value2}</Text>
            </View>
          </View>
        }
      </View>
    )
  } else if (item?.type == 'text' || item?.type == 'textBottom') {
    return (
      <View style={{ marginHorizontal: 20, borderBottomLeftRadius: item?.type == 'textBottom' ? 10 : 0, borderBottomRightRadius: item?.type == 'textBottom' ? 10 : 0, backgroundColor: 'white', flexDirection: 'row' }}>
        <View style={{ flex: 0.5, paddingHorizontal: 15, paddingVertical: 10 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text style={{ fontSize: 14, color: 'grey', flexWrap: 'wrap', marginRight: 10 }}>{item?.title1}</Text>
            <Text style={{ fontSize: 14, color: themeColor.color1 }}>{item?.value1}</Text>
          </View>
        </View>
        <View style={{ borderRightWidth: 1, borderColor: themeColor.color11 }} />
        <View style={{ flex: 0.5, paddingHorizontal: 15, paddingVertical: 10 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text style={{ fontSize: 14, color: 'grey', flexWrap: 'wrap', marginRight: 10 }}>{item?.title2}</Text>
            <Text style={{ fontSize: 14, color: themeColor.color1 }}>{item?.value2}</Text>
          </View>
        </View>
      </View>
    )
  } else if (item?.type == 'break') {
    return (
      <View
        key={item.id.toString()}
        style={{ flex: 1, borderBottomWidth: 1, borderColor: themeColor.color8 }}
      />
    )
  }
}

function ShopList({ dialog, setDialog, onBackDialog, dialogWidth }) {
  const { globalState, dispatch } = useContext(store);
  const [shopId, setShopId] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    const res = await globalState.client.service('shopusers').find({
      query: { userId: globalState.user.id }
    });
    setData(res.data)
  }
  return (
    <View style={{ paddingHorizontal: 10 }}>
      {data?.map(item => (
        <TouchableOpacity key={item.id.toString()} style={{ marginVertical: 5 }} onPress={() => {
          setShopId(item?.shopId);
          onBackDialog(item?.shopId);
        }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <CustomIcon
              name={shopId == item?.shopId ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={22} color="gray"
            />
            <Text style={{ marginLeft: 5 }}>{item?.shop?.shopThName}</Text>
          </View>

        </TouchableOpacity>
      ))}
    </View>
  )
}
