import React, { useState, useCallback, useEffect, useContext } from 'react';
import { themeColor, fontStyles } from '../config/servervar';
import { View } from 'react-native-web';
import { store } from '../sharewebapp/store'
import moment from 'moment';
import { Col, Row } from '@pankod/refine-antd';
import { useNavigate } from 'react-router-dom';
import { getPanelIndex, PanelHeader, getFlexIndex } from './webcommon';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { SettingPart } from '../sharewebapp/setting';
import { CustomerServicePart } from '../sharewebapp/customerservice';
import { ViewWebSimplePart } from '../sharewebapp/viewwebsimple';
import { ViewPharmaProfilePart } from '../sharewebapp/viewpharmaprofile';
import { PasswordEnterPart } from '../sharewebapp/passwordenter';
import { Refine, Authenticated, AuthProvider } from "@pankod/refine-core";

export function SettingCenterScreen() {
  const { globalState } = useContext(store);
  const [listPanel, setListPanel] = useState([
    { name: 'Setting', params: null }
  ]);

  const navigate = useNavigate();

  const navigation = {
    navigate: (navName, navParams) => {
      if (navName == 'CustomerService') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'ViewWebSimple') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'ViewPharmaProfile') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'PasswordEnter') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      // alert(navName + ':' + JSON.stringify(navParams));
    },
    setOptions: (optionParams, index) => {
      setListPanel(prev => {
        const tmpListPanel = [...prev];
        if (index >= 0) {
          tmpListPanel[index] = { ...tmpListPanel[index], options: optionParams }
        } else {
          tmpListPanel[tmpListPanel.length - 1] = { ...tmpListPanel[tmpListPanel.length - 1], options: optionParams }
        }
        return tmpListPanel
      })
    },
    goBack: (index) => {
      setListPanel(prev => {
        if (index >= 0) {
          prev.length = index;
        }
        return [...prev];
      })
    },
    goHome: () => {
      navigate('/')
      return;
    },
    dispatch: () => {
      console.log('dispatch')
    }
  };

  return (
    <Authenticated>
      <Row style={{ flex: 1, flexWrap: 'nowrap' }}>
        <Col span={8} style={{ display: getFlexIndex(0, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[0]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(1, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[1]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(2, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[2]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(3, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[3]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(4, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[4]} navigation={navigation} />
        </Col>
      </Row>
    </Authenticated>
  )
}

function Panel({ panel, navigation }) {
  const panelProp = { navigation, route: { params: panel?.params, custom: { index: panel?.index } } };
  if (panel?.name == 'Setting') {
    return (
      <SafeAreaProvider>
        <SettingPart {...panelProp} />
      </SafeAreaProvider>
    )

  }
  if (panel?.name == 'CustomerService') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <CustomerServicePart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewWebSimple') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewWebSimplePart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewPharmaProfile') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewPharmaProfilePart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'PasswordEnter') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <PasswordEnterPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  return <View />
}