import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator, Alert, TouchableWithoutFeedback, Keyboard, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';

export function CustomerServicePart({ navigation, route }) {

  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5, }}>
      <View style={{ marginTop: 20, paddingHorizontal: 20 }}>
        <Text style={{ color: themeColor.color1, fontWeight: 'bold' }}>ติดต่อฝ่ายบริการลูกค้า</Text>
        <View style={{ backgroundColor: 'white', marginTop: 10, borderRadius: 5 }}>
          {[
            {
              // id: 1, icon: 'chat', title: '@ALLPharmaSee', imagePath: require('../assets/images/line.png'), option: { borderTopWidth: 0 }, onPress: async () => Linking.openURL("https://lin.ee/RxkMTn5")
              id: 1, icon: 'chat', title: '@ALLPharmaSee', imagePath: `${server_var.server_api}/images/line.png`, option: { borderTopWidth: 0 }, onPress: async () => Linking.openURL("https://lin.ee/RxkMTn5")
            },
            {
              id: 2, icon: 'facebook', title: 'ALL PharmaSee', option: { borderTopWidth: 1 }, onPress: async () => Linking.openURL("https://www.facebook.com/profile.php?id=100065474619383&sk=about")
            },
            {
              id: 3, icon: 'email', title: 'allpharmasee@cpall.co.th', option: { borderTopWidth: 1 }, onPress: async () =>
                Linking.openURL("mailto:allpharmasee@cpall.co.th")
            },
            {
              id: 4, icon: 'home', title: 'บริษัท ซีพี ออลล์ จำกัด (มหาชน) \n313 อาคารซี.พี.ทาวเวอร์ ขั้น 24 ถ.สีลม \nแขวงสีลม เขตบางรัก กรุงเทพฯ 10500', option: { borderTopWidth: 1 }, onPress: async () => null
            },
            {
              id: 5, icon: 'web', title: 'www.exta.co.th/app-allpharmasee', option: { borderTopWidth: 1, paddingBottom: 10 }, onPress: async () => Linking.openURL("https://www.exta.co.th/app-allpharmasee")
            },
          ].map(item => (
            <TouchableOpacity
              key={item.id.toString()}
              onPress={() => item.onPress()}
              style={[{ marginVertical: 5, marginHorizontal: 10, justifyContent: 'center', borderColor: themeColor.color8, paddingTop: 5 }, item.option]}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {(item?.imagePath) ?
                  <Image source={{ uri: item.imagePath }} style={{ width: 25, height: 25 }} /> :
                  <CustomIcon name={item.icon} size={25} color={themeColor.color1} />
                }

                <Text style={{ fontSize: 14, color: '#303030', paddingHorizontal: 10 }}>{item.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    </SafeAreaView>
  )
}
