
import React, { useState, useCallback, useEffect, useContext, useRef } from 'react'
import { TouchableOpacity, Text, View, SafeAreaView, FlatList, Dimensions, Image, StatusBar, AppState } from 'react-native'
import { themeColor, fontStyles } from '../config/servervar';
import { store } from "../sharewebapp/store";
import moment from 'moment';
import SegmentedControlTab from '../sharewebapp/SegmentedControlTab';
import { WaitingPart } from '../sharewebapp/waitingpart';
import { MessageListPart } from '../sharewebapp/messagelistpart';
import { useCustomFocusEffect } from '../component/custom';

export function ComCenterPart({ navigation, route }) {
  const refresh = route?.params?.refresh;
  const ccTabIndex = route?.params?.ccTabIndex;
  const ccTabSeed = route?.params?.ccTabSeed;
  const { globalState } = useContext(store);
  const [tabIndex, setTabIndex] = useState(0);
  const [refreshSeed, setRefreshSeed] = useState(0);
  const appState = useRef(AppState.currentState);

  useCustomFocusEffect(
    React.useCallback(() => {
      setRefreshSeed(Math.floor(Date.now() / 1000));
    }, [])
  );

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (
        appState.current.match(/inactive|background/) && nextAppState === "active"
      ) {
        setRefreshSeed(Math.floor(Date.now() / 1000));
      }
      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, [globalState?.user])

  useEffect(() => {
    if (typeof ccTabIndex != 'undefined') {
      setTabIndex(ccTabIndex);
    }
  }, [ccTabIndex, globalState?.user, ccTabSeed])

  const onPressWaitingPart = (pressParam) => {
    const paramViewUser = {
      userId: pressParam.userId,
      viewInfo: {
        viewName: 'waiting_to_consult',
        viewText: `รอปรึกษาร้าน ${pressParam?.shop?.shopThName}`,
        viewItem: pressParam,
      }
    }
    navigation.navigate('ViewUser', paramViewUser)
  }

  const onPressMessageList = (pressParam) => {
    const paramChat = {
      chatWithId: pressParam.fromId,
      chatWithType: pressParam.fromType,
      chatFromId: tabIndex == 0 ? globalState.user.id : pressParam.shopId2,
      chatFromType: tabIndex == 0 ? 'user' : 'shop',
      queId: pressParam?.queId,
    };
    navigation.navigate('Chat', paramChat);
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ marginTop: 10, flex: 1 }}>
        {globalState?.user?.staffFlag ? (
          <SegmentedControlTab
            values={["รอปรึกษา", "กำลังปรึกษา"]}
            selectedIndex={tabIndex}
            onTabPress={(index) => setTabIndex(index)}
            badges={
              globalState?.user?.staffFlag ?
                [globalState?.totalUnread?.queline, globalState?.totalUnread?.u2s] :
                [globalState?.totalUnread?.u2u + globalState?.totalUnread?.s2u]
            }
            tabTextStyle={{ textAlign: 'center', color: themeColor.color1 }}
            tabsContainerStyle={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}
            activeTabStyle={{ backgroundColor: themeColor.color1 }}
            tabStyle={{ borderColor: themeColor.color1 }}
          />) : <View />}
        {(globalState?.user?.staffFlag && [1].includes(tabIndex)) ? (
          <MessageListPart tabIndex={tabIndex} refresh={refresh}
            onPress={onPressMessageList} refreshNo={refreshSeed}
          />
        ) : <View />}
        {(globalState?.user?.staffFlag && [0].includes(tabIndex)) ? (
          <WaitingPart onPress={onPressWaitingPart} refreshSeed={refreshSeed} />
        ) : <View />}
        {!globalState?.user?.staffFlag ? (
          < MessageListPart tabIndex={tabIndex} refresh={refresh}
            onPress={onPressMessageList} refreshNo={refreshSeed}
          />
        ) : <View />}
      </View>
    </SafeAreaView >
  );
}

