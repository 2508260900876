import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { useDebounce } from '../sharewebapp/common1';
import { getJwtToken, CustomIcon } from '../component/custom';

export function ForwardPart({ navigation, route }) {
  const { globalState } = useContext(store);
  const [dialog, setDialog] = useState({ visible: false, data: null });
  const [data, setData] = useState({ forwardToShopId: 0, queText: '', forwardToShopTitle: '', queType: null });
  const { queId, fromShopId, fromPharmaId } = route.params;
  const [error, setError] = useState({ at: '', message: '' });
  const waitFlag = useRef(false);

  const onBack = async (item) => {
    setData(prev => ({ ...prev, forwardToShopId: item.id, forwardToShopTitle: item.shopThName }));
  }

  const validateForm = () => {
    const blankField = ['forwardToShopId', 'queType'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    return true;
  }

  const forward = async () => {
    if (waitFlag.current) {
      return;
    }
    waitFlag.current = true;
    if (!validateForm()) {
      waitFlag.current = false;
      return;
    }
    const res1 = await globalState.client.service('oldquelines').find({ query: { oldId: queId } });
    if (res1.total > 0) {
      const resQueline = res1.data?.[0];
      console.log('forward' + JSON.stringify(resQueline))
      const jwtToken = await getJwtToken();
      const res = await fetch(`${server_var.server_api}/forwardMsg`, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
        body: JSON.stringify({ queId })
      });
      const resJson = await res.json();
      await globalState.client.service('quelines').create({
        userId: resQueline?.userId,
        shopId2: data.forwardToShopId,
        deviceId: resQueline?.deviceId,
        fromShopId: fromShopId,
        fromPharmaId: fromPharmaId,
        fromQueId: queId,
        queText: data.queText,
        queType: data.queType,
        deliverJsonAddr: resQueline?.deliverJsonAddr,
      })
      setTimeout(() => waitFlag.current = false, 400);
      navigation.goBack(1);

    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        {[
          { id: 1, component: (<View style={{ flex: 1 }}><Text>ร้านที่จะส่งต่อ</Text></View>) },
          {
            id: 2, fieldName: 'forwardToShopId', component: (
              <TouchableOpacity
                style={{
                  flex: 1, height: 40, borderWidth: 1, borderColor: 'lightgray',
                  justifyContent: 'center', paddingHorizontal: 5, backgroundColor: 'white'
                }}
                onPress={() => setDialog(prev => ({ ...prev, visible: true }))}
              >
                <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                  <Text>
                    {data.forwardToShopTitle}
                  </Text>
                  <CustomIcon name="chevron-down" size={23} />
                </View>
              </TouchableOpacity>
            )
          },
          {
            id: 3, component: (
              <View style={{ flex: 1, marginTop: 20 }}>
                <Text>ประเภทการส่งต่อ</Text>
              </View>
            )
          },
          {
            id: 4, fieldName: 'queType', component: (
              <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                {[
                  { id: 1, title: 'สั่งซื้อสินค้า', value: 'forward_order' },
                  { id: 2, title: 'ปรึกษาเพิ่ม', value: 'forward_consult' }
                ].map(item2 => (
                  <TouchableOpacity key={item2.id.toString()} onPress={() => setData(prev => ({ ...prev, queType: item2.value }))}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginHorizontal: 20 }}>
                      <CustomIcon name={item2.value == data.queType ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={22} />
                      <Text style={{ marginLeft: 5 }}>{item2.title}</Text>
                    </View>
                  </TouchableOpacity>
                ))}
              </View>
            ),
          },
          {
            id: 5, component: (
              <View style={{ flex: 1, marginTop: 20 }}>
                <Text>ข้อความถึงร้าน</Text>
              </View>
            )
          },
          {
            id: 6, fieldName: 'queText', component: (
              <TextInput
                multiline={true} value={data.queText} onChangeText={text => setData(prev => ({ ...prev, queText: text }))}
                style={{
                  borderWidth: 1, borderColor: 'lightgray', flex: 1, paddingHorizontal: 5,
                  height: 80, paddingHorizontal: 5, backgroundColor: 'white', textAlignVertical: 'top'
                }}
              />
            )
          },
          {
            id: 7, component: (
              <TouchableOpacity style={{ flex: 1 }} onPress={async () => forward()}>
                <View style={{
                  backgroundColor: themeColor.color1, justifyContent: 'center',
                  alignItems: 'center', height: 40, borderRadius: 10, marginTop: 10
                }}>
                  <Text style={{ color: 'white' }}>ส่งต่อ</Text>
                </View>
              </TouchableOpacity>
            )
          }
        ].map(item => (
          <View key={item.id.toString()}>
            <View style={[
              { flexDirection: 'row', marginTop: 10, paddingHorizontal: 20 },
              error?.at == item?.fieldName ? { borderColor: 'red', borderWidth: 1 } : null
            ]}>
              {item?.component}
            </View>
            {error?.at == item?.fieldName && error?.message && <Text style={{ color: 'red', textAlign: 'center' }}>{error.message}</Text>}
          </View>
        ))}
        <Dialog dialog={dialog} setDialog={setDialog} onBack={onBack} route={route} />
      </ScrollView>
    </SafeAreaView>
  )
}

function Dialog({ dialog, setDialog, onBack, route }) {
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <View style={{
          width: dialogWidth, height: dialogWidth * 1.5, paddingTop: 10, paddingBottom: 30, justifyContent: "flex-start",
          alignItems: 'center', borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
        }}>
          <ScrollView>
            <SelectShop dialog={dialog} setDialog={setDialog} dialogWidth={dialogWidth} onBack={onBack} route={route} />
          </ScrollView>
          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: false }))}>
              <CustomIcon name="close-circle-outline" size={25} color="gray" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

function SelectShop({ dialog, setDialog, dialogWidth, onBack, route }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState();
  const [form, setForm] = useState({ searchValue: '', choice: 'online' });
  const debouncedSearchTerm = useDebounce(form.searchValue, 1000);
  const { queId, fromShopId, fromPharmaId } = route.params;

  useEffect(() => {
    if (form.choice == 'online') {
      loadData1();
    } else {
      loadData2();
    }

  }, [debouncedSearchTerm, form?.choice])

  const loadData1 = async () => {
    const res1 = await globalState.client.service('oldquelines').find({ query: { oldId: queId } });
    if (res1.total > 0) {
      let params = {
        lat: res1.data?.[0]?.deliverJsonAddr?.lat, long: res1.data?.[0]?.deliverJsonAddr?.long, type: 'general1', fromShopId
      }
      const res = await fetch(`${server_var.server_api}/listShop`, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
      });
      const resJson = await res.json();
      setData(resJson);
    } else {
      setData([])
    }
  }

  const loadData2 = async (pSkip) => {
    const res1 = await globalState.client.service('oldquelines').find({ query: { oldId: queId } });
    if (res1.total > 0) {
      const res = await globalState.client.service('shops').find({
        query: {
          shopThName: { $like: `%${form.searchValue}%` },
          shopStatus: 'A',
          "addLocInfo.lat": res1.data?.[0]?.deliverJsonAddr?.lat,
          "addLocInfo.long": res1.data?.[0]?.deliverJsonAddr?.long,
        }
      });
      ///
      let res2 = res?.data?.filter(item => (item?.shopuser?.length > 0));
      // console.log('res2' + JSON.stringify(res2, '', 2));
      ///
      setData(res2);
    }
  }

  return (
    <View style={{ paddingHorizontal: 20, width: dialogWidth - 5 }}>
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
          {[
            { id: 1, title: 'ร้านที่ Online', iconName: form.choice == 'online' ? "checkbox-marked-circle-outline" : "checkbox-blank-circle-outline", onPress: () => setForm(prev => ({ ...prev, choice: 'online' })) },
            { id: 2, title: 'ร้านทั้งหมด', iconName: form.choice == 'all' ? "checkbox-marked-circle-outline" : "checkbox-blank-circle-outline", onPress: () => setForm(prev => ({ ...prev, choice: 'all' })) }
          ].map(item => (
            <TouchableOpacity key={item.id.toString()} style={{ flex: 0.5 }} onPress={() => item.onPress()}>
              <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                <CustomIcon name={item.iconName} size={16} />
                <Text style={{ marginLeft: 5 }}>{item.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </View>
      {form.choice == 'all' && (
        <TextInput
          value={form.searchValue}
          onChangeText={text => setForm(prev => ({ ...prev, searchValue: text }))}
          style={{ marginTop: 10, width: dialogWidth * 0.8, height: 35, borderWidth: 1, borderColor: 'lightgray', borderRadius: 5, paddingHorizontal: 5 }}
        />
      )}
      <View style={{ marginTop: 10 }}>
        {data?.map(item => (
          <TouchableOpacity key={item.id.toString()} onPress={() => {
            onBack(item);
            setDialog(prev => ({ ...prev, visible: false }))
          }}>
            <View style={{
              flexDirection: 'row', paddingTop: 10, paddingBottom: 10,
              borderBottomWidth: 1, borderColor: 'lightgray', justifyContent: 'space-between'
            }}>
              <View style={{ flex: 0.6 }}>
                <Text>{item.shopThName}{(item?.shopArea) ? ` (${item.shopArea})` : ``}</Text>
              </View>
              <View style={{ flex: 0.2 }}>
                <Text>{item?.queline?.length} คิว</Text>
              </View>
              <View style={{ flex: 0.2 }}>
                <Text>{item?.distanceInKm ? parseFloat(item?.distanceInKm).toFixed(2) : '0'} กม.</Text>
              </View>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}
