import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import SegmentedControlTab from '../sharewebapp/SegmentedControlTab';
import { useCustomFocusEffect } from '../component/custom';
import EventEmitter from "./EventEmitter";
import moment from 'moment';

const QUERY_LIST = [
  { id: 0, title: 'รอดำเนินการ', query: { appStatus: null, appDateTime: { $gt: moment().utc().format('YYYY-MM-DD HH:mm') } } },
  { id: 1, title: 'สำเร็จ', query: { appStatus: 'done' } },
  { id: 2, title: 'ผ่านไปแล้ว', query: { appStatus: null, appDateTime: { $lt: moment().utc().format('YYYY-MM-DD HH:mm') } } },
  { id: 3, title: 'ยกเลิก', query: { appStatus: 'cancel' } },
]


export function ManageAppointmentPart({ navigation }) {
  const [tabIndex, setTabIndex] = useState(0);
  const { globalState, dispatch } = useContext(store);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [seedNum, setSeedNum] = useState(0)

  useEffect(() => {
    EventEmitter.addListener('ManageAppointment:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('ManageAppointment:refresh', handleRefresh);
    };
  }, [tabIndex, globalState?.user])

  const handleRefresh = () => {
    loadData(0);
  }
  const loadData = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      let res1, res2;
      try {
        res1 = await globalState.client.service('shopusers').find({
          query: { userId: globalState.user.id, $limit: 500 }
        });
        res2 = await globalState.client.service('appointments').find({
          query: {
            ...QUERY_LIST[tabIndex].query,
            shopId: { $in: res1?.data?.map(item => item.shopId) },
            $skip: pSkip ?? skip,
            $sort: { id: -1 }
          }
        })
        console.log('query123:', {
          query: {
            ...QUERY_LIST[tabIndex].query,
            shopId: { $in: res1?.data?.map(item => item.shopId) },
            $skip: pSkip ?? skip,
            $sort: { id: -1 }
          }
        });
      } catch { }
      if (res2) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res2.data);
        } else {
          setDataList(prev => [...prev, ...res2.data]);
        }
        setSkip((res2.skip + res2.limit));
      }
      setLoading(false);
    }
  }

  useCustomFocusEffect(
    useCallback(() => {
      setSeedNum(Math.floor(Date.now() / 1000))
    }, [tabIndex])
  )

  useEffect(() => {
    loadData(0);
  }, [seedNum, tabIndex])

  return (
    <View style={{ flex: 1 }}>
      <SegmentedControlTab
        values={QUERY_LIST.map(item => item.title)}
        selectedIndex={tabIndex}
        onTabPress={(index) => setTabIndex(index)}
        tabTextStyle={{ textAlign: 'center', color: themeColor.color1 }}
        tabsContainerStyle={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}
        activeTabStyle={{ backgroundColor: themeColor.color1 }}
        tabStyle={{ borderColor: themeColor.color1 }}
      />
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        style={[{ marginTop: 20 }, Platform.OS == 'web' ? { height: '80vh' } : null]}
        onEndReached={(xxx) => loadData()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadData(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item }) => (<Item item={item} navigation={navigation} />)}
      />
    </View>
  )
}

function Item({ item, navigation }) {
  return (
    <TouchableOpacity style={{ marginBottom: 10 }} onPress={() =>
      navigation.navigate('ViewPharmaNoteOrderAppointment', {
        appointmentId: item?.id,
        pharmaNoteId: item?.pharmanote?.id,
        ordermainId: item?.pharmanote?.oldqueline?.ordermain?.id,
        showAppAction: !['done', 'cancel'].includes(item.appStatus),
        showEditPharmaNote: true
      })
    }>
      <View>
        <View style={{
          flexDirection: 'row', borderBottomWidth: 1, borderColor: 'lightgray',
          paddingBottom: 10, paddingHorizontal: 10
        }}>
          <Image source={{ uri: item?.user?.avatar }} style={{ width: 50, height: 50, borderRadius: 25 }} />
          <View style={{ marginLeft: 15 }}>
            <Text style={{ fontWeight: 'bold' }}>{item?.user?.displayName}</Text>
            <Text style={{ fontSize: 12 }}>วันเวลาที่นัดหมาย: {moment(item?.appDateTime).format('DD/MM/YYYY HH:mm')}</Text>
            <Text style={{ fontSize: 12 }}>สาขาที่นัดหมาย: {item?.shop?.shopThName}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}
