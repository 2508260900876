import React, { useEffect, useState, useContext, useRef } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Keyboard, Dimensions, ScrollView, ActivityIndicator, Image, TextInput, FlatList, Linking } from 'react-native';
import { useChatContext } from 'react-native-gifted-chat/lib/GiftedChatContext'; //To Be Test
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon, CustomVideo } from '../component/custom';
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';

export function KMBox({ setShowMenu, showMenu, sendMessage, setCustomText, navigation }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [catId, setCatId] = useState('00');
  const [catList, setCatList] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [faqData, setFaqData] = useState();
  const [faqId, setFaqId] = useState(0);

  useEffect(() => {
    const loadCatList = async () => {
      const res = await globalState.client.service('cats').find({ query: { catType: 'faq' } });
      setCatList(res.data);
    }
    loadCatList();
  }, [])

  useEffect(() => {
    const loadData = async () => {
      const res = await globalState.client.service('faqs').get(faqId)
      setFaqData(res);
    }
    if (faqId > 0) loadData();
  }, [faqId])

  // useEffect(() => {
  //   if (debouncedSearchTerm.length == 0) {
  //     loadDataList(0, catId);
  //   } else {
  //     loadDataList(0);
  //   }
  // }, [debouncedSearchTerm])

  useEffect(() => {
    loadDataList(0, catId);
  }, [debouncedSearchTerm])

  const loadDataList = async (pSkip, pCatId) => {
    if (!loading) {
      setLoading(true);
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query = { $skip: pSkip ?? skip, $sort: { title: 1 }, $limit: 20 };
      //console.log('\ncatId : ', catId);
      if (pCatId != '00') {
        query = {
          ...query,
          category: pCatId
        };
      }
      if (searchArray.length > 0) {
        const tmp1 = searchArray.join('%');
        query = {
          ...query,
          $or: [
            { title: { $like: `%${tmp1}%` } },
            { keyword: { $like: `%${tmp1}%` } },
          ]
        };
      }

      const res = await globalState.client.service('faqs').find({ query });
      if ((pSkip ?? skip) === 0) {
        setDataList(res.data);
      } else {
        setDataList(prev => [...prev, ...res.data]);
      }
      setSkip((res.skip + res.limit));
      setLoading(false);
      //console.log('\nFaqs : ', res.data);
    }
  }

  const onView = (faqId) => {
    setFaqId(faqId);
    setShowDetail(true);
  }

  return (
    <View style={{ height: (Dimensions.get('window').height * 0.7), marginTop: -5, backgroundColor: 'white' }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderTopWidth: 1, borderColor: 'grey' }}>
        <TouchableOpacity onPress={() => setShowDetail(false)}>
          {showDetail &&
            <View style={{ flexDirection: 'row', marginTop: 10, marginLeft: 10 }}>
              <CustomIcon name="chevron-left" size={25} color={themeColor.color1} />
              <Text style={{ color: themeColor.color1 }}> ย้อนกลับ</Text>
            </View>
          }
        </TouchableOpacity>
        <TouchableOpacity style={{ marginTop: 0, marginRight: 0 }} onPress={() => setShowMenu(0)}>
          <CustomIcon name="close-circle-outline" size={25} color="gray" />
        </TouchableOpacity>
      </View>
      {!showDetail &&
        <View>
          <TextInput
            textInputAutoFocus={false}
            value={searchText}
            onChangeText={text => setSearchText(text)}
            placeholder={'ค้นหาข้อมูล'}
            style={{ alignSelf: 'center', marginTop: 0, marginHorizontal: 20, width: "90%", borderColor: 'lightgray', borderWidth: 1, height: 40, paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }}
          />
          <View style={{ marginTop: 5, marginBottom: 5 }}>
            <ScrollView horizontal style={{ paddingHorizontal: 20 }}>
              {catList?.map(item => (
                <View key={item.id.toString()} style={{ marginTop: 5, marginBottom: 0 }}>
                  <TouchableOpacity
                    style={{ padding: 10, borderRadius: 10, backgroundColor: catId == item.catAltId ? themeColor.color1 : 'lightgray', marginRight: 10 }}
                    onPress={() => {
                      setCatId(item.catAltId);
                      loadDataList(0, item.catAltId);
                    }}
                  >
                    <Text style={{ color: catId == item.catAltId ? 'white' : 'black' }}>{item.catName}</Text>
                  </TouchableOpacity>
                </View>
              ))}
            </ScrollView>
          </View>
          <FlatList
            keyExtractor={(item) => item.id.toString()}
            data={dataList}
            onEndReached={(item) => loadDataList(null, catId)}
            onEndReachedThreshold={0.5}
            onRefresh={(item) => loadDataList(0, catId)}
            refreshing={(loading && skip == 0)}
            style={{ marginTop: 0 }}
            renderItem={({ item }) => (
              <FaqItem item={item} onView={onView} />
            )}
          />
        </View>
      }
      {showDetail &&
        <ScrollView style={{ paddingHorizontal: 20, margin2Vertical: 10, flex: 1 }}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 5 }}>
            <Text style={{ color: themeColor.color1, fontSize: 16, fontWeight: 'bold', flex: 1, flexWrap: 'wrap' }}>{faqData?.title}</Text>
          </View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 5 }}>
            <Text style={{ fontSize: 14, flex: 1, flexWrap: 'wrap' }}>{faqData?.text}</Text>
          </View>
          {faqData?.img && <ImgItem item={faqData?.img} navigation={navigation} />}
          {faqData?.video && <VideoItem item={faqData?.video} />}
          {faqData?.pdf &&
            <TouchableOpacity onPress={() => Linking.openURL(faqData?.pdf)} >
              <Image source={{ uri: `${server_var.server_api}/images/pdf_file.png` }} style={{ marginTop: 15, width: '100%', aspectRatio: 3 / 2, resizeMode: 'contain' }} />
            </TouchableOpacity>
          }

          <TouchableOpacity style={{ marginVertical: 30, alignSelf: 'center', width: '100%' }} onPress={async () => {
            await sendMessage({ text: `<${faqData?.title}>\n${faqData?.text}` });
            if (faqData?.img) {
              faqData.img.forEach(async (item) => {
                await sendMessage({ image: item.filename });
              });
            }
            if (faqData?.video) {
              await sendMessage({ video: faqData?.video });
            }
            if (faqData?.pdf) {
              await sendMessage({ type: 'File', data: { fileName: 'attached.pdf', uri: faqData?.pdf } });
            }
            setShowMenu(0);
          }}>
            <View style={{ height: 40, width: '100%', backgroundColor: themeColor.color1, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ color: 'white' }}>ส่งทางข้อความ</Text>
            </View>
          </TouchableOpacity>
        </ScrollView>
      }
    </View>
  )
}

function FaqItem({ item, onView }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5, marginHorizontal: 20, }}
      onPress={() => onView(item.id)}
    >
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1 }}>
        <View style={{ paddingBottom: 10, flex: 1 }}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 5 }}>
            <Text style={{ color: themeColor.color1, fontSize: 14, fontWeight: 'bold', flex: 1, flexWrap: 'wrap' }}>{item.title}</Text>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingTop: 5 }}>
            <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item.text.substr(0, 100) + '...'}</Text>
            <CustomIcon name="chevron-right-circle" size={20} color="gray" style={{ paddingHorizontal: 10 }} />
          </View>
        </View>
      </View>
    </TouchableOpacity >
  )
}

function ImgItem({ item, navigation }) {
  return (
    item.map((item2, index) => (
      <TouchableOpacity key={index.toString()} onPress={() => {
        navigation.navigate('ViewImage1', { uri: item2.filename })
      }} >
        <Image key={index.toString()} source={{ uri: item2.filename }} style={{ marginTop: 15, width: '100%', aspectRatio: 3 / 2, resizeMode: 'contain' }} />
      </TouchableOpacity>
    ))
  )
}

function VideoItem({ item }) {
  const videoRef = useRef()
  const videoWidth = 250;
  const [videoHeight, setVideoHeight] = useState(videoWidth);
  const updateVideoRatioOnDisplay = (videoDetails) => {
    const { width, height } = videoDetails.naturalSize;
    setVideoHeight(videoWidth / width * height);
  }

  return (
    <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 15 }} >
      <CustomVideo
        ref={videoRef}
        style={{ width: videoWidth, height: videoHeight }}
        source={{ uri: item }}
        useNativeControls
        resizeMode="contain"
        isLooping={false}
        onReadyForDisplay={updateVideoRatioOnDisplay}
      />
    </View>
  )
}
