
import React, { useState, useCallback, useEffect, useContext, useRef, useLayoutEffect } from 'react'
import { TouchableOpacity, Text, View, SafeAreaView, FlatList, Dimensions, Image, StatusBar, AppState } from 'react-native'
import { themeColor, fontStyles } from '../config/servervar';
import { store } from "../sharewebapp/store";
import moment from 'moment';
import { Col, Row } from '@pankod/refine-antd';
import { CustomIcon } from '../component/custom';
import { server_var } from './../config/servervar';
import axios from "axios";
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { mdiSetNone } from '@mdi/js';

export function PanelHeader({ panel, navigation, route }) {
  const [options, setOptions] = useState({
    headerLeft: null,
    title: '',
    headerTitleStyle: {},
    headerRight: null
  })

  useEffect(() => {
    if (panel?.options) {
      setOptions(panel.options);
    }
  }, [panel?.options])

  return (
    <View style={{ height: 50, backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <TouchableOpacity onPress={() => navigation.goBack(panel.index)}>
          <CustomIcon name="chevron-left" size={26} color={'gray'} />
        </TouchableOpacity>
        <View>{(options?.headerLeft) ? options.headerLeft() : <View />}</View>
      </View>
      <Text style={options.headerTitleStyle}>{options.title}</Text>
      <View>{(options?.headerRight) ? options.headerRight() : <View />}</View>
    </View>
  )
}

export function getPanelIndex(num, length) {
  if (num == 0) {
    if (length < 1) {
      return -1
    }
    if (length >= 1 && length <= 3) {
      return 0
    } else {
      return length - 3
    }
  }
  if (num == 1) {
    if (length <= 1) {
      return -1
    }
    if (length >= 2 && length <= 3) {
      return 1
    } else {
      return length - 2
    }
  }
  if (num == 2) {
    if (length <= 2) {
      return -1
    }
    if (length >= 3) {
      return length - 1
    }
  }
}

// num=0,length=1 flex
// num=1,length=1 flex
// num=2,length=1 flex
// num=3,length=1 none
// num=4,length=1 none

// num=0,length=4 none
// num=3,length=4 flex

//num=3,length=2
//num=4,lenght=2

export function getFlexIndex(num, length) {
  if ((length - num) > 3) {
    return 'none'
  }
  if ((num - length) >= 0) {
    return 'none'
  }
  return 'flex'
}

// export function getFlexIndex(num, length) {
//   if ((length - num) > 3) {
//     return 'none'
//   } else {
//     return 'flex'
//   }
// }
